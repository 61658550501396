import React from 'react'
import Lottie from 'react-lottie';
import LanguageContext from '../helper/LanguageContext';
import { EN } from '../Languages/EN';
import { DE } from '../Languages/DE';
import animationData from '../../src/Icons/PhoneCall.json';
import { KJUUP_COLORS } from '../helper/constants';


function AcceptCallButton() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
        }
    };
    const { isGerm } = React.useContext(LanguageContext);
    return (
        <div style={{ color:KJUUP_COLORS.internal, fontWeight:"bold", display: "flex", justifyContent: "center", alignItems: "center", border:'2px solid #288f6c', paddingLeft:10, paddingRight:10, borderRadius:10}}>
          {isGerm ? EN.join_call : DE.join_call}
            <div style={{ marginLeft: 5,  }}>
                <Lottie
                    options={defaultOptions}
                    height={40}
                    width={40}
                    isStopped={false}
                    isPaused={false}
                />
            </div>
        </div>
    )
}


export default AcceptCallButton