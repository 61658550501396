import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Form } from "react-bootstrap";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import LanguageContext from "../../helper/LanguageContext";
import { callAPI } from "../../helper/apiUtils";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import { BASE_URL, KJUUP_COLORS } from "../../helper/constants";
import { $user } from "../../helper/UserFactory";
import {
  GROUPS,
  GROUPS_BOTTOM,
  OPEN_BOTTOM_CHATS,
  OPEN_CHATS,
} from "../../store";
import { connect, useSelector } from "react-redux";
import { convertBranchRole } from "../../helper/convertor";
import FollowOneToOne from "./FollowOneToOne";
import { ReactComponent as AddOneToOne } from '../../../src/Icons/AddToProject.svg';
import { Discuss, Rings } from 'react-loader-spinner'

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};





function AddOneToOneUser(props) {
  const { isGerm } = React.useContext(LanguageContext);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [userList, setUsersList] = useState([]);
  const [isFollow, setIsFollow] = useState(false);
  const [action, setAction] = useState("");
  const [page, setPage] = useState(1)
  const [isGettingImages, setIsGettingImages] = useState(false)
  const [isDataOver, setIsDataOver] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [searchedUsers, setSearchedUsers] = useState([])
  const [viewLoader, setViewLoader] = useState(false)
  let user = useSelector((state) => state.user);

  const getUsers = async (page, keyword) => {
    const { data } = await callAPI(`/user/allUsersSearch?limit=50&page=${page}&query=${keyword}`, {}, "GET");
    // const arrList = await data.users.filter(user => user.myCompanies.length > 0); //remove the company length condition
    await CreateList(data.data.users);
    console.log("userList", data);
  };

  const searchUsers = async (page, keyword) => {
    setIsSearch(true)
    const { data } = await callAPI(`/user/allUsersSearch?limit=50&page=${page}&query=${keyword}`, {}, "GET");
    setSearchedUsers(data.data.users);
    setIsSearch(false)
  };

  const CreateList = async (US) => {
    if (userList.length == 0) {
      setUsers(US);
      setUsersList(US);
    }
    else {
      let tmp = [...userList]
      tmp = [...tmp, ...US]
      setUsers(tmp)
      setUsersList(tmp)
    }
  };

  useEffect(() => {
    getUsers(1, '');
  }, []);

  const filterUsers = async (text) => {
    setSearch(text);
    text = text.toLowerCase().trim()
    searchUsers(1, text)
  };


  const _onFollowClick = async (followingId) => {
    callAPI(`/user/${followingId}/follow?id=${followingId}`, {}, "GET");
  }

  const addProjectUser = async (u) => {
    setViewLoader(true)
    const user = $user.get();
    const { data } = await callAPI(`/group`, {}, "POST", {
      groupType: "user",
      isOneToOne: true,
      users: [user?.id, u.id],
    });
    if (!u.isFollowing) {
      _onFollowClick(u.id)
    }
    const group = await props.groups.find((cht) => cht.id === data.chat.id);
    if (group) {
      const chk = await props.openChats.find((g) => g.id === data.chat.id);
      if (chk) {
        await props.updateOpenChats(
          _.uniqBy([...props.openChats, chk], "id")
        );
      } else {
        await props.updateOpenChats([...props.openChats, group]);
        const updateGroup = await props.groups.map((g) => {
          if (g.id === group.id) {
            return { ...g, isOpenedTop: true };
          } else {
            return { ...g }
          }
        });
        props.updateGroups(updateGroup);
      }
    }
    setViewLoader(false)
    props.afterAddUser(u);
    props.hide();
  };


  const handleScroll = (e) => {
    const bottom = parseInt(e.target.scrollHeight - e.target.scrollTop) <= parseInt(e.target.clientHeight);
    if (bottom) {
      console.log('end')
      if (!isGettingImages && !isDataOver) {
        let p = page + 1
        setPage(p)
        getUsers(p, '')
      }
    }
  }

  return (
    <React.Fragment>
      <div className="one-to" style={{ height: "93vh", display: "flex", flexDirection: "column" }}>
        {viewLoader && (
          <div className="website_loader">
            <div className="loader" />
          </div>
        )}
        <div className="main_heading">
          <a onClick={(e) => props.hide()} className="main_heading pointer">
            <img
              width={10}
              src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
            />
            {isGerm ? DE.cancel : EN.cancel}
          </a>
        </div>
        <div className="invite add_one_to_user">
          <div className="admin_right">
            <div className="search_div">
              <div className="search">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Control
                    autocomplete="off"
                    value={search}
                    type="text"
                    placeholder={isGerm ? DE.search : EN.search}
                    onChange={(e) => filterUsers(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        {
          isSearch ?
            <div style={{ height: 200, alignItems: "center", display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }} />
              <div>
                <Rings
                  height="100"
                  width="100"
                  color={KJUUP_COLORS.base_color}
                  radius="6"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="rings-loading"
                />
              </div>
              <div style={{ flex: 1 }} />
            </div>
            :
            <></>
        }
        {!search ?
          <div onScroll={handleScroll} className="scroll" style={{ flex: 1, paddingLeft: 10, paddingRight: 10, overflowY: "scroll", overflowX: "hidden" }}>
            {users &&
              users.map((u, i) => (
                u.id !== user.id ?
                  <div key={i} className="admin-contact">
                    <div className="row m-0">
                      <div
                        className="col-md-12 p-0 pointer"
                        onClick={(e) => addProjectUser(u)}
                      >
                        <div className="admin-control">
                          <img
                            width={40}
                            height={40}
                            className="news_profile"
                            style={{ borderRadius: 100, objectFit: "cover" }}
                            src={
                              u.img
                                ? BASE_URL + u.img
                                : process.env.PUBLIC_URL +
                                "/assets/img/head.svg"
                            }
                            alt="Img"
                          />
                          <h4>
                            {u.name}
                            <span>
                              {convertBranchRole(u.details, isGerm) ||
                                u.details}
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <></>
              ))}
          </div>
          :
          !isSearch ?
            <div className="scroll" style={{ flex: 1, paddingLeft: 10, paddingRight: 10, overflowY: "scroll", overflowX: "hidden" }}>
              {searchedUsers &&
                searchedUsers.map((u, i) => (
                  u.id !== user.id ?
                    <div key={i} className="admin-contact">
                      <div className="row m-0">
                        <div
                          className="col-md-12 p-0 pointer"
                          onClick={(e) => addProjectUser(u)}
                        >
                          <div className="admin-control">
                            <img
                              width={40}
                              height={40}
                              className="news_profile"
                              style={{ borderRadius: 100, objectFit: "cover" }}
                              src={
                                u.img
                                  ? BASE_URL + u.img
                                  : process.env.PUBLIC_URL +
                                  "/assets/img/head.svg"
                              }
                              alt="Img"
                            />
                            <h4>
                              {u.name}
                              <span>
                                {convertBranchRole(u.details, isGerm) ||
                                  u.details}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <></>
                ))}
            </div>
            :
            <></>
        }
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { groups, groupsBottom, openChats, openBottomChats } = state;
  return {
    groups: groups,
    groupsBottom: groupsBottom,
    openChats: openChats,
    openBottomChats: openBottomChats,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateGroups: (groups) => dispatch({ type: GROUPS, groups: groups }),
    updateOpenChats: (chats) =>
      dispatch({ type: OPEN_CHATS, openChats: chats }),
    updateGroupsBottom: (groups) =>
      dispatch({ type: GROUPS_BOTTOM, groupsBottom: groups }),
    updateOpenBottomChats: (chats) =>
      dispatch({ type: OPEN_BOTTOM_CHATS, openBottomChats: chats }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddOneToOneUser));
