import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Form, ListGroup, Modal } from "react-bootstrap";
import Layout from "../../components/Layout";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import LanguageContext from "../../helper/LanguageContext";
import { callAPI } from "../../helper/apiUtils";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import { BASE_URL } from "../../helper/constants";
import { Positions } from "../../Languages/vars/Positions";
import { $crud } from "../../helper/CrudFactory";
import { ReactComponent as AddSvg } from "../../Icons/Add.svg";
import { convertBranchRole } from "../../helper/convertor";
import InviteExternalComponent from "../../components/InviteExternalComponent";
import InviteExternalComponentPOL from "../../components/InviteExternalComponentPOL";
import ImageWithPlaceholder from "../../components/ImageWithPlaceholder";
import { object } from "prop-types";


const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

function CompanyUser(props) {
  const { isGerm } = React.useContext(LanguageContext);
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [userList, setUsersList] = useState([]);
  const [showPositionDialog, setPositionDialog] = useState(false);
  const [employeePosition, setEmployeePosition] = useState();
  const [userId, setUserId] = useState();
  const [isInviteByLink, setInviteByLink] = useState(false);
  const [viewLoader, setViewLoader] = useState(false)
  const [companyId, setCompanyId] = useState("");
  const [allToSearch, setAllToSearch] = useState([])
  const [allToSearchBack, setAllToSearchBack] = useState([])
  const [isKjuup, setIsKjuup] = useState([])
  const positions = Positions;
  const getCompanies = async () => {
    const id = props.match.params.id;
    const { data } = await callAPI(`/company/${id}/getUsersToAdd`, {}, "GET");
    await CreateList(data.users);
    // console.log(data);
  };

  const CreateList = async (users) => {
    const arr = _.uniqBy(
      users.map((user) => {
        let name = { name: user.lastName.trim()[0].toUpperCase() };
        return name;
      }),
      "name"
    );
    const updatedArr = _.sortBy(arr, ["name"]);
    const usersList = updatedArr.map((a) => {
      let names = users.filter((u) => u.lastName.trim()[0].toUpperCase() === a.name);
      a.names = names;
      return a;
    });
    setUsers(usersList);
    setUsersList(usersList);
    setAllToSearch(users)
    setAllToSearchBack(users)
  };

  useEffect(() => {
    setCompanyId(props.match.params.id);
    getCompanies();
  }, []);
  useEffect(() => {
    if (props.location.state) {
      setIsKjuup(props.location.state.isKjuup)
    }
  }, [props])
  const [search, setSearch] = useState("");

  const filterUsers = async (searchValue) => {
    setSearch(searchValue);
    if (searchValue) {
      let xx = [...allToSearchBack]
      let xxx = xx.filter((itm) => {
        if (itm.firstName.toLowerCase().startsWith(searchValue.toLowerCase()) || itm.lastName.toLowerCase().startsWith(searchValue.toLowerCase()) || itm.fullName.toLowerCase().startsWith(searchValue.toLowerCase())) {
          return true
        }
        // if (itm.firstName.toLowerCase().startsWith(searchValue.toLowerCase()) || itm.lastName.toLowerCase().startsWith(searchValue.toLowerCase())) {
        //   return true
        // }
      })
      setAllToSearch([...xxx])
    }
    else {
      setAllToSearch([...allToSearchBack])
    }
  };

  const addUser = async (id) => {
    handleSubmit(id)
  };
  const hidePositionDialog = () => {
    if (isInviteByLink) setInviteByLink(false);
    setPositionDialog(false);
  };

  const handleSubmit = async (id) => {
    console.log(props.match.params)
    setViewLoader(true)
    const { data } = await callAPI(
      `/company/request`,
      null,
      "POST",
      (props.match.params.departmentId && props.match.params.departmentId !== "null" && props.match.params.departmentId !== "undefined") ? {
        companyId: props.match.params.id,
        userId: id,
        lang: `${isGerm ? 'de' : 'en'}`,
        deptLocation: props.match.params.departmentId
      } : {
        companyId: props.match.params.id,
        userId: id,
        lang: `${isGerm ? 'de' : 'en'}`
      }
    );
    $crud.notify({
      type: data.status ? "success" : "error",
      message: data.message,
    });
    let tmp = [...users]
    for (let i = 0; i < users.length; i++) {
      for (let j = 0; j < users[i].names.length; j++) {
        if (users[i].names[j].id == id) {
          tmp[i].names.splice(j, 1)
          if (tmp[i].names.length == 0) {
            tmp.splice(i, 1)
          }
        }
      }
    }
    let ttx = allToSearch.filter((e) => {
      return e.id != id
    })
    let ttxx = allToSearchBack.filter((e) => {
      return e.id != id
    })
    setAllToSearch(ttx)
    setAllToSearchBack(ttxx)
    setUsers(tmp)
    setViewLoader(false)
  };

  const handleInviteUser = () => {
    setInviteByLink(true);
    setPositionDialog(true);
  };

  return (
    <React.Fragment>
      <Layout>
        <div class="company_section w-300 company_list">
          {viewLoader && (
            <div className="website_loader">
              <div className="loader" />
            </div>
          )}
          <div className="company_header">
            <div class="heading_icon m-0">
              <Link
                to={`/company-details/${companyId}`}
                className={isKjuup ? "main_heading main_heading-internal" : "main_heading"}
              >
                <img
                  width={10}
                  src={process.env.PUBLIC_URL + isKjuup ? "/assets/img/backx.svg" : "/assets/img/back.svg"}
                />
                {isGerm ? DE.add_employee : EN.add_employee}
              </Link>
            </div>
          </div>
          <div className="company_list_ver">
            <div className="my-scroll min_height m-0">
              <CustomScrollbars
                autoHide
                autoHideTimeout={500}
                autoHideDuration={200}
                className="bottom-bar"
              >
                <div className="invite p-2">
                  <div className="admin_right">
                    <div className="search_div">
                      <div className="search">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Control
                            autocomplete="off"
                            value={search}
                            type="search"
                            placeholder="Search"
                            onChange={(e) => filterUsers(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <InviteExternalComponentPOL
                      onClick={(e) => {
                        let path = (props.match.params.departmentId && props.match.params.departmentId !== null && props.match.params.departmentId !== "null" && props.match.params.departmentId !== "undefined") ? `/invite-user/${props.match.params.id}/${props.match.params.departmentId}` : `/invite-user/${props.match.params.id}/${null}`
                        history.push({
                          pathname: path,
                          state: { isKjuup }
                        });
                      }
                      }
                      color={'#fff'}
                      id={'Compnies'}
                    />
                    {!search &&
                      <div className="admin-content">
                        {users &&
                          users.map((user, index) => (
                            <div key={index}>
                              <h5 className="mt-2">{user.name}</h5>
                              {user.names &&
                                _.sortBy(
                                  user.names,
                                  [(us) => us?.lastName?.toLowerCase()],
                                  ["lastName"]
                                ).map((u, i) => (
                                  <div key={i} className="admin-contact">
                                    <div className="row m-0">
                                      <div className="col-md-10 p-0">
                                        <div className="admin-control">
                                          {u.avatar ?
                                            <ImageWithPlaceholder
                                              width={40}
                                              height={40}
                                              className="news_profile"
                                              style={{ borderRadius: 100, objectFit: "cover" }}
                                              src={BASE_URL + u.avatar}
                                              alt="G"
                                              fallbackSrc={(process.env.PUBLIC_URL + "/assets/img/head.svg")}
                                            />
                                            :
                                            <img
                                              width={40}
                                              height={40}
                                              className="news_profile"
                                              style={{ borderRadius: 100, objectFit: "cover" }}
                                              src={(process.env.PUBLIC_URL + "/assets/img/head.svg")}
                                              alt="Generic placeholder"
                                            />
                                          }
                                          <h4>
                                            {u.fullName}
                                            <span>{convertBranchRole(u.details, isGerm)}</span>
                                          </h4>
                                        </div>
                                      </div>
                                      <div className="col-md-2" pr-0>
                                        <AddSvg
                                          onClick={(e) => {
                                            addUser(u.id)
                                          }}
                                          className={isKjuup ? 'mt-0 mr-0 custom-svg-internal' : "mt-0 mr-0 pointer custom-svg"}
                                          height={21}
                                        />
                                        {/* <div className="custom_checkbox">
                                        <label className="container_checkbox">
                                          <input
                                            type="checkbox"
                                            value={u.id}
                                            onClick={(e) => addUser(u.id)}
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      </div> */}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          ))}
                      </div>
                    }
                    {search &&
                      <div className="admin-content">
                        {allToSearch &&
                          allToSearch.map((u, i) => (
                            <div key={i} className="admin-contact">
                              <div className="row m-0">
                                <div className="col-md-10 p-0">
                                  <div className="admin-control">
                                    <img
                                      width={40}
                                      height={40}
                                      className="news_profile"
                                      style={{ borderRadius: 6, objectFit:"cover" }}
                                      src={
                                        u.avatar
                                          ? BASE_URL + u.avatar
                                          : process.env.PUBLIC_URL +
                                          "/assets/img/head.svg"
                                      }
                                      alt="Generic placeholder"
                                    />
                                    <h4>
                                      {u.fullName}
                                      <span>{convertBranchRole(u.details, isGerm)}</span>
                                    </h4>
                                  </div>
                                </div>
                                <div className="col-md-2" pr-0>
                                  <AddSvg
                                    onClick={(e) => {
                                      addUser(u.id)
                                    }}
                                    className="mt-0 mr-0 pointer custom-svg"
                                    height={21}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    }
                  </div>
                </div>
              </CustomScrollbars>
            </div>
          </div>
        </div>
        <>
          <Modal
            show={showPositionDialog}
            onHide={hidePositionDialog}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {isGerm
                  ? DE.position_within_company
                  : EN.position_within_company}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ListGroup variant="flush">
                {positions.map((position) => (
                  <div
                    onClick={(e) => setEmployeePosition(position.name_en)}
                    key={position.id}
                    className="form-check p"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id={position.id}
                      value={position.name_en}
                    />
                    <label
                      className="form-check-label pointer"
                      htmlFor={position.id}
                    >
                      {isGerm ? position.name_de : position.name_en}
                    </label>
                  </div>
                ))}
              </ListGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={hidePositionDialog}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </Layout>
    </React.Fragment>
  );
}

export default CompanyUser;
