import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Media } from "react-bootstrap";
import Layout from "../../components/Layout";
import { callAPI } from "../../helper/apiUtils";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import { BASE_URL } from "../../helper/constants";
import { Link } from "react-router-dom";
import { $crud } from "../../helper/CrudFactory";
import _ from "lodash";
import { convertBranchRole } from "../../helper/convertor";
import { Scrollbars } from "react-custom-scrollbars";
const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};
const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);
function Followers() {
  const { isGerm } = useContext(LanguageContext);
  const [followers, setFollowers] = useState([]);
  const [allFollowers, setAllFollowers] = useState([])
  const [search, setSearch] = useState("")
  const getFollowers = async () => {
    const { data } = await callAPI(`/user/follower`, {}, "GET");
    const followersList = _.sortBy(data.followers, [
      (follower) => follower?.lastName?.toLowerCase(),
    ]);
    setAllFollowers([...followersList])
    setFollowers([...followersList]);
  };
  useEffect(() => {
    getFollowers();
  }, []);

  const removeFollower = async (follower) => {
    await $crud.confirm({
      title: "",
      textContent: isGerm ? `Wollen Sie ${follower.fullName} wirklich aus der Liste der Abonnenten entfernen? Wenn Sie ihn/sie entfernen wird er/sie nicht darüber informiert.` : `Do you really want to remove ${follower.fullName} from following you? If you remove him/her he/she will not get informed.`,
      options: {
        ok: isGerm ? DE.confirm : EN.confirm,
        cancel: isGerm ? DE.cancel : EN.cancel,
      },
    });
    const { data } = await callAPI(`/user/${follower.id}/remove`, {}, "GET");
    $crud.notify({
      type: data.status ? "success" : "error",
      message: data.message,
    });
    await getFollowers();
  };
  const searchFollowers = async (value) => {
    setSearch(value);
    value = value.trim();
    const searchResult = await allFollowers.filter((itm) => {
      if (!itm.isCompany && (itm.firstName.toLowerCase().startsWith(value.toLowerCase()) || itm.lastName.toLowerCase().startsWith(value.toLowerCase()) || itm.fullName.toLowerCase().startsWith(value.toLowerCase()))) {
        return true
      }
      // if (value === "") {
      //   return itm;
      // } else if (itm.firstName.toLowerCase().startsWith(value.toLowerCase()) || itm.lastName.toLowerCase().startsWith(value.toLowerCase())) {
      //   return itm;
      // }
    });
    if (value === "") {
      setFollowers(allFollowers);
    } else {
      setFollowers(searchResult);
    }
  };

  return (
    <React.Fragment>
      <Layout>
        {followers && (
          <div class="w-300 following follower">
            <div className="main_heading">
              <Link to="/profile">
                <img
                  width={10}
                  src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                />
              </Link>
              {isGerm ? DE.followers : EN.followers}
            </div>
            <div className="search">
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Control
                  autocomplete="off"
                  onChange={(e) => searchFollowers(e.target.value)}
                  value={search}
                  type="search"
                  placeholder={isGerm ? DE.search : EN.search}
                />
              </Form.Group>
              <img
                src={process.env.PUBLIC_URL + "assets/img/search.svg"}
                alt="Generic placeholder"
              />
            </div>
            <div>
              <div className="my-scroll min_height_followers_following contact_height">
                <CustomScrollbars
                  autoHide
                  autoHideTimeout={500}
                  autoHideDuration={200}
                  className="bottom-bar"
                >
                  {followers &&
                    followers.map((follower) => (
                      <div key={follower.id} className="post_notification">
                        <div className="my_post">
                          <Media>
                            <img
                              width={40}
                              height={40}
                              className="mr-3"
                              src={
                                follower.avatar
                                  ? BASE_URL + follower.avatar
                                  : process.env.PUBLIC_URL + "/assets/img/user.jpg"
                              }
                              alt="Generic placeholder"
                              style={{ objectFit: "cover", borderRadius: "100px", }}
                            />
                            <Media.Body>
                              <div className="authore_name" style={{ }}>
                                <Link to={`/user-profile/${follower.id}`}>
                                  <h5>{follower.fullName}</h5>
                                </Link>
                                <p style={{marginRight:15}}>{convertBranchRole(follower.buildingIndustryRole, isGerm)}</p>
                              </div>
                            </Media.Body>
                            <Button
                              className="btn-sm theme-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                removeFollower(follower);
                              }}
                            >
                              {" "}
                              {isGerm ? DE.remove : EN.remove}
                            </Button>
                          </Media>
                        </div>
                      </div>
                    ))}
                </CustomScrollbars>
              </div>
            </div>
          </div>
        )}
        {!followers && (
          <div className=" w-300 p-0">
            <div className="company_header  company_border">
              <div class="heading_icon m-0">
                <Link
                  to="/profile"
                  className="main_heading"
                  style={{ lineHeight: "27px" }}
                >
                  <img
                    width={10}
                    src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                  />
                  {isGerm ? DE.followers : EN.followers}
                </Link>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </React.Fragment>
  );
}

export default Followers;
