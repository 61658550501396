import ReactTooltip from "react-tooltip";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { NavLink, withRouter } from "react-router-dom";
import { $user } from "./helper/UserFactory";
import LanguageContext from "./helper/LanguageContext";
import { DE } from "./Languages/DE";
import { EN } from "./Languages/EN";
import { callAPI } from "./helper/apiUtils";
import { connect, useDispatch, useSelector } from "react-redux";
import { BASE_URL, KJUUP_COLORS, TOGGLE_DRAWER } from "./helper/constants";
import { Badge } from "react-bootstrap";
import { ReactComponent as LatestLogo } from '../src/Icons/Latest-message.svg';
import { ReactComponent as ContactIcon } from '../src/Icons/Contacts-Icon.svg';
import { ReactComponent as ContactIconFilled } from '../src/Icons/Contacts-Icon-filled.svg';
import { ReactComponent as AddcompanyIcon } from '../src/Icons/Add company-deactivated.svg';
import { ReactComponent as AddProjectIcon } from '../src/Icons/Add project-deactivated.svg';
import { ReactComponent as HomeIcon } from '../src/Icons/Home-Feed-Active.svg';
import { ReactComponent as HomeIconInactive } from '../src/Icons/Home-Feed-Inactive.svg';
import { ReactComponent as JobportalDeactived } from '../src/Icons/Job portal-Deactivated.svg';
import { ReactComponent as JobPortalActivated } from '../src/Icons/Job portal-activated.svg';
import { ReactComponent as MyCompActivated } from '../src/Icons/My companies-activated.svg';
import { ReactComponent as MyCompDeactivated } from '../src/Icons/My companies-deactivated.svg';
import { ReactComponent as AddProjectActivatedIcon } from '../src/Icons/Add project-Activated.svg';
import animationData from '../src/Icons/VideoCall.json';
import Lottie from 'react-lottie';
// import Lottie from "lottie-react";




import { ALL_BADGE_COUNT, GROUP_CHATS, NEWS_BADGE_COUNT, OPEN_CHATS, OPEN_PROJECTS, RESET_MARKIN_PROJECT, SET_ACTIVITY_MESSAGES, SET_IN_ACTIVITY, SET_USER_LOGIN_TOKEN, SHOW_LIST_ONGOING } from "./store";

const SideNave = (props) => {
  const user = useSelector((state) => state.user);
  const profile = useSelector((state) => state.profile);
  const { unSeenAllCount } = useSelector((state) => state);
  const { drawerOpen } = useSelector((state) => state);
  const companyImage = useSelector((state) => state.companyImage);
  const { pathname } = useLocation();
  const [openSidebar, setOpenSideBar] = useState("articles");
  const history = useHistory();
  const dispatch = useDispatch()
  const inActivity = useSelector(e => e.inActivity)
  const [isVisible, setIsVisible] = useState(false);
  const runningCalls = useSelector(e => e.runningCalls)

  // console.log("inActivity", user, profile)
  useEffect(() => {
    if (pathname && pathname != '/') {
      setOpenSideBar(pathname.replace("/", "").replace("#", ""));
    }
    else if (pathname == '/')
      redirectToArticles()
  }, []);

  const redirectToArticles = () => {
    history.push('/articles');
  };

  useEffect(() => {
    // Toggle visibility every 3 seconds
    const interval = setInterval(() => {
      setIsVisible((prev) => !prev);
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval
  }, []);

  const logout = async () => {
    // alert("logour")
    var userLang = navigator.language || navigator.userLanguage;
    localStorage.removeItem("autoTrans")
    localStorage.removeItem("videoCallPop");
    if (userLang === 'de') {
      localStorage.setItem('switch', '2')
      setIsGerm(true)
    } else {
      localStorage.setItem('switch', '1')
      setIsGerm(false)
    }
    const { data } = await callAPI("/user/logout", {}, "GET");
    // console.log(data);
    await $user.logout();
    dispatch({ type: SET_USER_LOGIN_TOKEN, status: null })
    if (window.electron && typeof window.electron.sendBadgeUpdate === 'function')
      window.electron.sendBadgeUpdate('');
    history.push("/");
  };
  const { isGerm, setIsGerm } = React.useContext(LanguageContext);

  const handleSidebarClick = (type) => {
    if (type == 'LatestMessages') {
      props.setInActivity(!inActivity)
    }
    else {
      props.setInActivity(false)
    }
    let a = { ...unSeenAllCount, unseenNewsCount: 0 }
    props.updateAllBadgeCount(a)
    if (openSidebar === type || !drawerOpen) {
      props.handleDrawer(!drawerOpen);
    } else if (openSidebar !== type) {
      props.handleDrawer(true);
    }
    setOpenSideBar(type);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      // preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      {drawerOpen && (
        <>
          <ReactTooltip id="hometooltip" place="right" effect="solid">
            {" "}
            {isGerm ? DE.news_feed : EN.news_feed}
          </ReactTooltip>
          <ReactTooltip id="feedstooltip" place="right" effect="solid">
            {" "}
            {isGerm ? DE.Activity : EN.Activity}{" "}
          </ReactTooltip>
          <ReactTooltip id="profiletooltip" place="right" effect="solid">
            {isGerm ? DE.profile : EN.profile}{" "}
          </ReactTooltip>
          <ReactTooltip id="mycompany" place="right" effect="solid">
            {" "}
            {isGerm ? DE.my_companies : EN.my_companies}{" "}
          </ReactTooltip>

          <ReactTooltip id="contacttooltip" place="right" effect="solid">
            {" "}
            {isGerm ? DE.contacts : EN.contacts}{" "}
          </ReactTooltip>
          <ReactTooltip id="addcompanytooltip" place="right" effect="solid">
            {" "}
            {isGerm ? DE.add_company : EN.add_company}{" "}
          </ReactTooltip>
          <ReactTooltip id="addprojecttooltip" place="right" effect="solid">
            {" "}
            {isGerm ? DE.add_project : EN.add_project}{" "}
          </ReactTooltip>
          <ReactTooltip id="capacitiestooltip" place="right" effect="solid">
            {" "}
            {isGerm ? DE.free_capacity : EN.free_capacity}{" "}
          </ReactTooltip>
          <ReactTooltip id="projecttooltip" place="right" effect="solid">
            {" "}
            {isGerm ? DE.project_list : EN.project_list}{" "}
          </ReactTooltip>
          <ReactTooltip id="workareatooltip" place="right" effect="solid">
            {" "}
            {isGerm ? DE.work_area : EN.work_area}{" "}
          </ReactTooltip>
          <ReactTooltip id="chartooltip" place="right" effect="solid">
            {" "}
            {isGerm ? DE.one_to_one_chat : EN.one_to_one_chat}{" "}
          </ReactTooltip>
          <ReactTooltip id="companytooltip" place="right" effect="solid">
            {" "}
            {isGerm ? DE.company_list : EN.company_list}
          </ReactTooltip>
          <ReactTooltip id="logout" place="right" effect="solid">
            {" "}
            {isGerm ? DE.logout : EN.logout}{" "}
          </ReactTooltip>
          <ReactTooltip id="changeLanguage" place="right" effect="solid">
            {" "}
            {isGerm ? DE.change_language : EN.change_language}{" "}
          </ReactTooltip>
        </>
      )}

      <nav className="main_nav">
        <ul className="side_nav">
          <li className="home_icon"
            onClick={() => { }}
          >
            {!companyImage ?
              <span>
                <svg
                  width="35px"
                  id="Layer_2"
                  data-name="Layer 2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 96.84 96.84"
                >
                  <path
                    className="cls-color"
                    d="M287.74,259.66H235.31a9.71,9.71,0,0,0-9.71,9.71V321.5h29.8v-32h32.34Z"
                    transform="translate(-225.6 -259.66)"
                  />
                  <path
                    className="cls-color"
                    d="M292.64,259.66v67h-67v20.09a9.71,9.71,0,0,0,9.71,9.71h77.42a9.71,9.71,0,0,0,9.71-9.71V269.37a9.71,9.71,0,0,0-9.71-9.71Z"
                    transform="translate(-225.6 -259.66)"
                  />
                  <path
                    className="cls-color-2"
                    d="M285.79,306.7l-20.6-11.9a1.83,1.83,0,0,0-2.74,1.59v23.78a1.82,1.82,0,0,0,2.74,1.58l20.6-11.89A1.82,1.82,0,0,0,285.79,306.7Z"
                    transform="translate(-225.6 -259.66)"
                  />
                </svg>
                {/* {props.unseenNewsCount > 0 ? (
                    <Badge pill>{props.unseenNewsCount}</Badge>
                  ) : (
                    ""
                  )} */}
              </span>
              :
              <img
                width={'35px'}
                height={'35px'}
                style={{ objectFit: "cover", borderRadius: 10, border: '0.5 solid #DFDFDF' }}
                src={BASE_URL + companyImage}
                alt="Generic placeholder"
              />
            }
          </li>
          {runningCalls?.length > 0 && (
            <li onClick={() => dispatch({ type: SHOW_LIST_ONGOING, payload: true })} style={{  height:70,}}>
              <Lottie options={defaultOptions}
                height={60}
                width={60}
                isStopped={false}
                isPaused={false}
                // isStopped={this.state.isStopped}
                // isPaused={this.state.isPaused} 
                />
            </li>
          )}

          <li onClick={() => handleSidebarClick("LatestMessages")} style={{ marginTop: runningCalls?.length > 0?0:-2 }}>
            <NavLink
              to="/LatestMessages"
              activeClassName={
                openSidebar === "LatestMessages" && drawerOpen ? "" : ""
              }
              data-tip
              data-for="feedstooltip"
            >
              <LatestLogo height={'35px'} width={'35px'} stroke={(openSidebar === 'LatestMessages' && drawerOpen) ? KJUUP_COLORS.base_color : 'grey'} fill={(openSidebar === 'LatestMessages' && drawerOpen) ? KJUUP_COLORS.base_color : 'grey'} />
            </NavLink>
          </li>
          <li onClick={() => handleSidebarClick("articles")}>
            <NavLink
              to="/articles"
              activeClassName={
                openSidebar === "articles" && drawerOpen ? "active" : ""
              }
              data-tip
              data-for="hometooltip"
            >
              {(openSidebar === 'articles' && drawerOpen) ?
                <HomeIcon height={'35px'} width={'35px'} stroke={(openSidebar === 'articles' && drawerOpen) ? KJUUP_COLORS.base_color : 'grey'} fill={(openSidebar === 'articles' && drawerOpen) ? KJUUP_COLORS.base_color : 'grey'} />
                :
                <HomeIconInactive height={'35px'} width={'35px'} stroke={(openSidebar === 'articles' && drawerOpen) ? KJUUP_COLORS.base_color : 'grey'} fill={(openSidebar === 'articles' && drawerOpen) ? KJUUP_COLORS.base_color : 'grey'} />
              }
              {/* <span className="svg_icon home_svg">
                <svg
                  width="33px"
                  id="Layer_9"
                  data-name="Layer 9"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 98.8 103.75"
                >
                  <path
                    className="cls-1"
                    d="M274.72,53.12a3,3,0,0,0-3.88,0L225.91,91.89a2.89,2.89,0,0,0-1,2.24v56a3,3,0,0,0,3,3h53.25V132.94h10.07v20.22H317.7a3,3,0,0,0,3-3v-56a3,3,0,0,0-1-2.24Z"
                    transform="translate(-223.37 -50.9)"
                  />
                </svg>
              </span> */}
            </NavLink>
          </li>
          <li onClick={() => handleSidebarClick("profile")}>
            <NavLink
              to="/profile"
              activeClassName={
                openSidebar === "profile" && drawerOpen ? "active" : ""
              }
              data-tip
              data-for="profiletooltip"
            >
              <span className="svg_icon">
                {user?.avatar && (
                  <img
                    src={BASE_URL + user?.avatar}
                    style={{ borderRadius: "100px", objectFit: "cover" }}
                  />
                )}
                {!user?.avatar && (
                  <svg
                    width="35px"
                    id="Layer_4"
                    data-name="Layer 4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 39.55 39.54"
                  >
                    <rect
                      className="cls-1"
                      x="0.45"
                      y="0.45"
                      width="38.65"
                      height="38.64"
                      rx="4.19"
                      ry="4.19"
                    />
                    <path
                      className="cls-2"
                      d="M299.22,235c.28-.29.88-.87.87-.87-2.46-1.13-5.08-1.73-6.36-2.29s-4.75-2.26-4.75-2.26-.17-2.74-1.19-2.9l.17-1.85a13.45,13.45,0,0,0,1.19-4.18s1.19,1,1.62-1.21,1-6.19-.85-5.23a22.92,22.92,0,0,0,0-5.14c-.34-1.93-2.08-7.88-9.9-7.88s-9.57,5.95-9.91,7.88a22.69,22.69,0,0,0,.05,5.14c-1.87-1-1.28,3.06-.85,5.23s1.61,1.21,1.61,1.21a13.45,13.45,0,0,0,1.19,4.18l.17,1.85c-1,.16-1.18,2.9-1.18,2.9s-3.48,1.7-4.76,2.26c-1.1.48-1.41,1.31-3.92,2.46a5.32,5.32,0,0,0,2.76,1.8,6.72,6.72,0,0,0,1.75.24h13.58l14.6-.09a8.72,8.72,0,0,0,3-.49l1-.76"
                      transform="translate(-261.42 -197.24)"
                    />
                  </svg>
                )}
              </span>
            </NavLink>
          </li>
          <li onClick={() => handleSidebarClick("my-companies")}>
            <NavLink
              to="/my-companies"
              activeClassName={
                openSidebar === "my-companies" && drawerOpen ? "active" : ""
              }
              data-tip
              data-for="mycompany"
            >
              <span className="svg_icon company_menu">
                {openSidebar === "my-companies" && drawerOpen ?
                  <MyCompActivated height={'35px'} width={'35px'} />
                  :
                  <MyCompDeactivated height={'35px'} width={'35px'} />
                }
              </span>
            </NavLink>
          </li>

          <li onClick={() => handleSidebarClick("contact")}>
            <NavLink
              activeClassName={
                openSidebar === "contact" && drawerOpen ? "active" : ""
              }
              to="/contact"
              data-tip
              data-for="contacttooltip"
            >
              {(openSidebar === 'contact' && drawerOpen) ?
                <ContactIconFilled height={'35px'} width={'35px'} stroke={(openSidebar === 'contact' && drawerOpen) ? KJUUP_COLORS.base_color : 'grey'} fill={(openSidebar === 'contact' && drawerOpen) ? KJUUP_COLORS.base_color : 'grey'} />
                :
                <ContactIcon height={'35px'} width={'35px'} stroke={(openSidebar === 'contact' && drawerOpen) ? KJUUP_COLORS.base_color : 'grey'} fill={(openSidebar === 'contact' && drawerOpen) ? KJUUP_COLORS.base_color : 'grey'} />
              }
              {/* <span className="svg_icon">
                <svg
                  width="29px"
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 17.68 20.9"
                >
                  <path
                    className="cls-1"
                    d="M223,273.19a1.34,1.34,0,0,0-1.39-1.3h-.72a5.87,5.87,0,0,1-9.78,0h-.71a1.35,1.35,0,0,0-1.4,1.3l-1.39,9.5A1.35,1.35,0,0,0,209,284h14a1.35,1.35,0,0,0,1.39-1.31Z"
                    transform="translate(-207.16 -263.55)"
                  />
                  <path
                    className="cls-1"
                    d="M216,273.37a4.69,4.69,0,1,0-4.68-4.69A4.69,4.69,0,0,0,216,273.37Z"
                    transform="translate(-207.16 -263.55)"
                  />
                </svg>
              </span> */}
            </NavLink>
          </li>
          <li onClick={() => handleSidebarClick("add-company")}>
            <NavLink
              to="/add-company"
              activeClassName={
                openSidebar === "add-company" && drawerOpen ? "active" : ""
              }
              data-tip
              data-for="addcompanytooltip"
            >
              {/* <span className="svg_icon">
                <svg
                  width="22px"
                  id="Layer_11"
                  data-name="Layer 11"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16.55 27"
                >
                  <path
                    className="cls-1"
                    d="M266.21,251.78h-4.93a1.79,1.79,0,0,1-1.77-1.48l-1.39-7.72a2.71,2.71,0,0,1,2.66-3.18h5.93a2.7,2.7,0,0,1,2.65,3.18L268,250.3A1.8,1.8,0,0,1,266.21,251.78Z"
                    transform="translate(-256.94 -234.86)"
                  />
                  <path
                    className="cls-1"
                    d="M257.39,248.81V252a1.88,1.88,0,0,0,1.8,1.88h9.1a1.87,1.87,0,0,0,1.8-1.88v-3.15"
                    transform="translate(-256.94 -234.86)"
                  />
                  <path
                    className="cls-1"
                    d="M260.25,261.86v-1.72a.9.9,0,0,1,.9-.9H267a.9.9,0,0,1,.9.9v1.72"
                    transform="translate(-256.94 -234.86)"
                  />
                  <line
                    className="cls-1"
                    x1="7.12"
                    y1="27"
                    x2="7.12"
                    y2="18.67"
                  />
                  <line
                    className="cls-1"
                    x1="11.62"
                    y1="2.47"
                    x2="16.55"
                    y2="2.47"
                  />
                  <line className="cls-1" x1="14.09" x2="14.09" y2="4.93" />
                </svg>
              </span> */}
              <AddcompanyIcon height={'35px'} width={'35px'} stroke={(openSidebar === 'add-company' && drawerOpen) ? KJUUP_COLORS.base_color : 'grey'} fill={(openSidebar === 'add-company' && drawerOpen) ? KJUUP_COLORS.base_color : 'grey'} />

            </NavLink>
          </li>
          <li onClick={() => handleSidebarClick("add-project")}>
            <NavLink
              to="/add-project"
              activeClassName={
                openSidebar === "add-project" && drawerOpen ? "active" : ""
              }
              data-tip
              data-for="addprojecttooltip"
            >
              {openSidebar === "add-project" && drawerOpen ?
                <AddProjectActivatedIcon height={'35px'} width={'35px'} />
                :
                <AddProjectIcon height={'35px'} width={'35px'} stroke={(openSidebar === 'add-project' && drawerOpen) ? KJUUP_COLORS.base_color : 'grey'} fill={(openSidebar === 'add-project' && drawerOpen) ? KJUUP_COLORS.base_color : 'grey'} />
              }
            </NavLink>
          </li>
          <li onClick={() => handleSidebarClick("free-capacity")}>
            <NavLink
              to="/free-capacity"
              activeClassName={
                openSidebar === "free-capacity" && drawerOpen ? "active" : ""
              }
              data-tip
              data-for="capacitiestooltip"
            >
              {openSidebar === "free-capacity" && drawerOpen ?
                <JobPortalActivated height={'35px'} width={'35px'} />
                :
                <JobportalDeactived height={'35px'} width={'35px'} />
              }

              {/* <span className="svg_icon">
                <svg
                  width="35px"
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 22.1 21.03"
                >
                  <path
                    className="cls-1"
                    d="M102.25,136.25H112a9.81,9.81,0,0,1-19.62,0,9.87,9.87,0,0,1,9.85-9.84,10.79,10.79,0,0,1,3.25.59Zm9.67-1.5h1.68a9.67,9.67,0,0,0-.25-2.42,9.49,9.49,0,0,0-6-6.33l-.49,1.58-2.59,7.17Z"
                    transform="translate(-91.95 -125.43)"
                  />
                </svg>
              </span> */}
            </NavLink>
          </li>
          {/* <li onClick={() => handleSidebarClick("project-list")}>
            <NavLink
              to="/project-list"
              activeClassName={
                openSidebar === "project-list" && drawerOpen ? "active" : ""
              }
              data-tip
              data-for="projecttooltip"
            >
              <span className="svg_icon">
                <svg
                  width="33px"
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 19.86 20.9"
                >
                  <path
                    className="cls-1"
                    d="M260.9,239a1.11,1.11,0,0,1-1.13,1.05h-.67l.91,2-3.23-2h-6.42a1.07,1.07,0,0,1-1.09-1.05v-6a1,1,0,0,1,1.09-1h9.41a1.07,1.07,0,0,1,1.13,1Z"
                    transform="translate(-245.3 -227.88)"
                  />
                  <path
                    className="cls-1"
                    d="M245.75,229.52v13a1.19,1.19,0,0,0,1.18,1.19h16.59a1.19,1.19,0,0,0,1.18-1.19V231.19a1.17,1.17,0,0,0-1.18-1.17h-8.3a1.25,1.25,0,0,1-1.18-1c-.35-.85-1.1-.73-1.76-.73h-5.35A1.18,1.18,0,0,0,245.75,229.52Z"
                    transform="translate(-245.3 -227.88)"
                  />
                  <line
                    className="cls-1"
                    x1="1.48"
                    y1="18.18"
                    x2="18.28"
                    y2="18.18"
                  />
                  <line
                    className="cls-1"
                    x1="1.48"
                    y1="20.45"
                    x2="18.28"
                    y2="20.45"
                  />
                </svg>
              </span>
            </NavLink>
          </li> */}
          {/* <li onClick={() => handleSidebarClick("company")}>
            <NavLink
              to="/company"
              activeClassName={
                openSidebar === "company" && drawerOpen ? "active" : ""
              }
              data-tip
              data-for="companytooltip"
            >
              <span className="svg_icon">
                <svg
                  width="17px"
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 9.95 20.9"
                >
                  <path
                    className="cls-1"
                    d="M103.82,144.53h-3.51a1.28,1.28,0,0,1-1.26-1.06l-1-5.5A1.93,1.93,0,0,1,100,135.7h4.23a1.92,1.92,0,0,1,1.89,2.27l-1,5.5A1.29,1.29,0,0,1,103.82,144.53Z"
                    transform="translate(-97.09 -135.25)"
                  />
                  <path
                    className="cls-1"
                    d="M97.54,142.41v2.25A1.34,1.34,0,0,0,98.82,146h6.49a1.34,1.34,0,0,0,1.28-1.34v-2.25"
                    transform="translate(-97.09 -135.25)"
                  />
                  <path
                    className="cls-1"
                    d="M99.58,151.71v-1.22a.64.64,0,0,1,.64-.64h4.15a.64.64,0,0,1,.64.64v1.22"
                    transform="translate(-97.09 -135.25)"
                  />
                  <line
                    className="cls-1"
                    x1="5.2"
                    y1="16.46"
                    x2="5.2"
                    y2="10.52"
                  />
                  <line
                    className="cls-1"
                    x1="1.34"
                    y1="18.19"
                    x2="8.58"
                    y2="18.19"
                  />
                  <line
                    className="cls-1"
                    x1="1.34"
                    y1="20.45"
                    x2="8.58"
                    y2="20.45"
                  />
                </svg>
              </span>
            </NavLink>
          </li> */}
          {/* <li onClick={(e) => {
                        e.preventDefault();
                        handleClass('news');
                    }} className={(display === 'news') ? "active_nav" : "diactive_nav"} data-tip
                        data-for="feedstooltip">
                        <span>
                            <img alt='img' className="img_gray" src={process.env.PUBLIC_URL + "assets/img/home_g.png"}/>
                            <img alt='img' className="img_active"
                                 src={process.env.PUBLIC_URL + "assets/img/home_o.png"}/>
                        </span>
                    </li>
                    <li onClick={(e) => {
                        e.preventDefault();
                        handleClass('contact');
                    }} className={(display === 'contact') ? "active_nav" : "diactive_nav"} data-tip
                        data-for="contacttooltip">
                        <span>
                            <img alt='img' className="img_gray"
                                 src={process.env.PUBLIC_URL + "assets/img/contact_g.png"}/>
                            <img alt='img' className="img_active"
                                 src={process.env.PUBLIC_URL + "assets/img/contact_o.png"}/>
                        </span>
                    </li>
                    <li onClick={(e) => {
                        e.preventDefault();
                        handleClass('addcompany');
                    }} className={(display === 'addcompany') ? "active_nav" : "diactive_nav"} data-tip
                        data-for="addcompanytooltip">
                        <span>
                            <img alt='img' className="img_gray"
                                 src={process.env.PUBLIC_URL + "assets/img/add_company_g.png"}/>
                            <img alt='img' className="img_active"
                                 src={process.env.PUBLIC_URL + "assets/img/add_company_o.png"}/>
                        </span>
                    </li>
                    <li onClick={(e) => {
                        e.preventDefault();
                        handleClass('addproject');
                    }} className={(display === 'addproject') ? "active_nav" : "diactive_nav"} data-tip
                        data-for="addprojecttooltip">
                        <span>
                            <img alt='img' className="img_gray"
                                 src={process.env.PUBLIC_URL + "assets/img/addproject_g.png"}/>
                            <img alt='img' className="img_active"
                                 src={process.env.PUBLIC_URL + "assets/img/addproject_o.png"}/>
                        </span>
                    </li>
                    <li onClick={(e) => {
                        e.preventDefault();
                        handleClass('capacities');
                    }} className={(display === 'capacities') ? "active_nav" : "diactive_nav"} data-tip
                        data-for="capacitiestooltip">
                        <span>
                            <img alt='img' className="img_gray"
                                 src={process.env.PUBLIC_URL + "assets/img/capacities__g.png"}/>
                            <img alt='img' className="img_active"
                                 src={process.env.PUBLIC_URL + "assets/img/capacities__o.png"}/>
                        </span>
                    </li>
                    <li onClick={(e) => {
                        e.preventDefault();
                        handleClass('project');
                    }} className={(display === 'project') ? "active_nav" : "diactive_nav"} data-tip
                        data-for="projecttooltip">
                        <span>
                            <img alt='img' className="img_gray"
                                 src={process.env.PUBLIC_URL + "assets/img/project_g.png"}/>
                            <img alt='img' className="img_active"
                                 src={process.env.PUBLIC_URL + "assets/img/project_o.png"}/>
                        </span>
                    </li>
                    <li onClick={(e) => {
                        e.preventDefault();
                        handleClass('company');
                    }} className={(display === 'company') ? "active_nav" : "diactive_nav"} data-tip
                        data-for="companytooltip">
                        <span>
                            <img alt='img' className="img_gray"
                                 src={process.env.PUBLIC_URL + "assets/img/company_g.png"}/>
                            <img alt='img' className="img_active"
                                 src={process.env.PUBLIC_URL + "assets/img/company_o.png"}/>
                        </span>
                    </li>*/}
          <li
            onClick={(e) => {
              e.preventDefault();
              logout();
            }}
            data-tip
            data-for="logout"
          >
            <span className="svg_icon logout_icon">
              <svg
                width="35px"
                id="Log-Out"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100.74 92.03"
              >
                <path
                  className="cls-1"
                  d="M230,230.82a44.52,44.52,0,1,0,0-38.67"
                  transform="translate(-215.38 -165.47)"
                />
                <line
                  className="cls-1"
                  x1="56.92"
                  y1="46.02"
                  x2="2.25"
                  y2="46.02"
                />
                <polyline
                  className="cls-1"
                  points="12.18 56.16 2.12 46.1 12.18 36.04"
                />
              </svg>
            </span>
          </li>
          {/*<li onClick={handleChangeLanguage} data-tip data-for="changeLanguage">*/}
          {/*    <span>*/}
          {/*        {language}*/}
          {/*    </span>*/}
          {/*</li>*/}
        </ul>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  const { unseenNewsCount, drawerOpen, unSeenAllCount, inActivity } = state;
  return {
    unseenNewsCount: unSeenAllCount?.unseenNewsCount,
    drawerOpen: drawerOpen,
    inActivity: inActivity
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateNewsCount: (count) =>
      dispatch({ type: NEWS_BADGE_COUNT, unseenNewsCount: count }),
    handleDrawer: (open) => dispatch({ type: TOGGLE_DRAWER, open }),
    updateAllBadgeCount: (count) =>
      dispatch({ type: ALL_BADGE_COUNT, unSeenAllCount: count }),
    setInActivity: (status) => dispatch({ type: SET_IN_ACTIVITY, status: status }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SideNave));
