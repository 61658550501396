import {Media} from "react-bootstrap";
import {BASE_URL} from "../../helper/constants";
import {Skeleton} from "@material-ui/lab";
import {DE} from "../../Languages/DE";
import {EN} from "../../Languages/EN";
import React from "react";
import LanguageContext from "../../helper/LanguageContext";
import moment from "moment";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import {Link, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import { convertBranchRole, convertHtmlString } from "../../helper/convertor";


export function ForienArticle({article, index, expandArticle, reloadArticle, handleFollow, company, removeArticle, viewArticleDetail}) {
    const {isGerm} = React.useContext(LanguageContext);
    const user = useSelector(state => state.user)
    const history = useHistory();
    const [articleBranch,setArticleBranch]=React.useState([])
    const getCompany = (createdById) => {
        if (createdById === user?.id) {
            history.push({
                pathname: `/company-details/${createdById}`,
            })
        } else {
            history.push({
                pathname: `/foreign-company-details/${createdById}`,
            })
        }
    }
React.useEffect(()=>{
    let tempRole=article?.buildingIndustryRole?.split(",")
    let temoVal=tempRole?.map((itm,index)=>(index? ", ":"")+convertBranchRole(itm,isGerm))
    setArticleBranch(temoVal)
},[article])

    return (
        <div className="forien-article">
            <Media>
                {
                    article.avatar &&
                    <img
                        width={40}
                        height={40}
                        className="mr-3 news_profile"
                        src={article.avatar ? BASE_URL + article.avatar : process.env.PUBLIC_URL + "assets/img/company.png"}
                        alt="Generic placeholder"
                    />
                }
                {
                    !article.avatar &&
                    <img
                        width={40}
                        height={40}
                        className="mr-3 news_profile"
                        src={process.env.PUBLIC_URL + "/assets/img/Companies-No-Image.svg"}
                        alt="Generic placeholder"
                    />
                }
                <Media.Body>
                    <div className="authore_name">
                        <div className="authore_name">
                            {
                                article.id ?
                                    <h5  style={{whiteSpace:"pre-wrap !important"}}  onClick={e => getCompany(article.createdById)}>{article.userFullName || article.createdBy}</h5> :
                                    <Skeleton variant="text"/>
                            }

                        </div>
                        {
                            article.postedByName ?
                                <h6 className="text-itallic">{article.postedByName ? article.postedByName :
                                    <Skeleton variant="text"/>} -
                                    <span>
                                         {article.id ? articleBranch :
                                        <Skeleton variant="text"/>}
                                        </span></h6> :
                                <Skeleton variant="text"/>
                        }
                        <div className="w-100">
                            {
                                article.product ?
                                    <h6 className="product_user">Product: <span>{article.product}</span></h6> : ''
                            }
                        </div>
                    </div>
                    <div className="side_icon">
                        {
                            (company.role === 'ROLE_COMPANY_SUPER_ADMIN') ?
                                <>
                                    <Link to={`/edit-company-article/${company.id}/${article.id}`}>
                                        <img width={18} className="img-fluid"
                                             src={process.env.PUBLIC_URL + "/assets/img/edit-pen-s.svg"}
                                             alt="Generic placeholder"/>
                                    </Link>
                                    <a href="#" onClick={e => removeArticle(article)}>
                                        <img width={15} className="img-fluid"
                                             src={process.env.PUBLIC_URL + "/assets/img/trashcan.svg"}
                                             alt="Generic placeholder"/>
                                    </a>
                                </> : ""
                        }
                    </div>
                </Media.Body>
            </Media>
            {
                article.id ?
                    <p className="m-0 mt-2 mb-2"><Link style={{color: '#212529', fontSize: 13}} to={`/feed-detail/${article.id}`}
                                                  className="font-weight-bold">{article.title}</Link></p> :
                    <Skeleton variant="text"/>
            }
 {        article.expand &&   <p  className="small" style={{color: '#212529'}}>
                {
                    ( article.description) ?
                    <span className="m-0" dangerouslySetInnerHTML={{__html: convertHtmlString(article.description)}} />
                    :
                    !article.description ?
                    <span className="m-0"  dangerouslySetInnerHTML={{__html: convertHtmlString(article.description)}}></span>:
                    <span className="m-0" dangerouslySetInnerHTML={{__html: convertHtmlString(article.description)}} /> 
                }
                {/* {
                    ((!article.expand && (article.description)) && article.description.length > 100) &&
                    <span className="pointer theme-color pl-3" onClick={(e) => {
                        e.preventDefault();
                        expandArticle(index)
                    }}>See More</span>
                } */}
            </p>}
            <div className="article-img">
                {
                    article.uploadUrl &&
                   
                        <Carousel
                            additionalTransfrom={0}
                            arrows={article.uploadUrl.length > 1 ? true : false}
                            centerMode={false}
                            className="p-0"
                            // containerClass="container"
                            dotListClass=""
                            draggable={article.uploadUrl.length > 1 ? true : false}
                            focusOnSelect={false}
                            infinite={false}
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 1024
                                    },
                                    items: 1,
                                },
                                mobile: {
                                    breakpoint: {
                                        max: 464,
                                        min: 0
                                    },
                                    items: 1
                                },
                                tablet: {
                                    breakpoint: {
                                        max: 1024,
                                        min: 464
                                    },
                                    items: 1
                                }
                            }}
                            showDots={article.uploadUrl.length > 1 ? true : false}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                        >
                            {
                                article.uploadUrl.map((image, index) => (
                                    <div className="profile_slider embed-responsive embed-responsive-16by9">
                                    <img
                                        className="pointer embed-responsive-item"
                                        onClick={() => viewArticleDetail(article)}
                                        src={BASE_URL + image.fileUrl}
                                        style={{
                                            objectFit: 'cover'
                                        }}
                                    />
                                    </div>
                                ))
                            }
                        </Carousel>
                   
                }
            </div>
            <div className="date-follow">
                <p>{moment(article.createdAt).format('D MMMM HH:MM')}</p>
                <Link to={`/people-likes/${article.id}`} className="follow-img">
                    {
                        article.likes &&
                        article.likes.map((like, i) => (
                            i >= 3 ?
                            <></>
                            :
                            like.avatar ?
                                <img key={i}
                                    src={BASE_URL + like.avatar}
                                    alt="Generic placeholder" /> :
                                <div key={i} className="svg_icon news_profile" style={{ display: 'inline-block' }}>
                                    <svg width="14px" height="14px" id="Layer_4" data-name="Layer 4"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.54 39.54">
                                        <path className="cls-1"
                                            d="M271.14,229.57l-3.12,1.5a19.26,19.26,0,0,0,13.16,5.26h.17a19.28,19.28,0,0,0,12.32-4.53c-1.32-.59-4.69-2.23-4.69-2.23s-.17-2.74-1.19-2.9l.17-1.85a13.45,13.45,0,0,0,1.19-4.18s1.19,1,1.62-1.21,1-6.19-.85-5.23a22.92,22.92,0,0,0,0-5.14c-.34-1.93-2.08-7.88-9.9-7.88s-9.57,5.95-9.91,7.88a22.69,22.69,0,0,0,.05,5.14c-1.87-1-1.28,3.06-.85,5.23s1.61,1.21,1.61,1.21a13.45,13.45,0,0,0,1.19,4.18l.17,1.85C271.3,226.83,271.14,229.57,271.14,229.57Z"
                                            transform="translate(-261.48 -197.24)" />
                                        <circle className="cls-2" cx="19.77" cy="19.77" r="19.32" />
                                    </svg>
                                </div>
                        ))
                    }

                    <span>{article.likeCount ? article.likeCount : ''}</span>
                </Link>
            </div>
            <div className="date-follow no-border no-hover-effect">
                <a onClick={(e) => {
                }} className={article.likeStatus ? "svg_icon pointer active-like-follow" : "svg_icon pointer"}>
                    <svg className="mr-1" width="17px"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 16.26 15.9">
                        <path
                            d="M146.53,100H144.2a.59.59,0,0,0-.6.59v6.92a.59.59,0,0,0,.6.59h2.33a.59.59,0,0,0,.6-.59v-6.92A.59.59,0,0,0,146.53,100Z"
                            transform="translate(-143.15 -92.63)"/>
                        <path className="cls-1"
                              d="M156.94,98.05h-3.27V94.72c0-.58-.64-1.64-1.15-1.64H151.3c-.23,0-.51.45-.51,1.19v2a6.17,6.17,0,0,1-1.06,2.21c-.45.61-1.35,1.15-1.35,2v5.9c0,.57.48,1.31,1.73,1.31h5.58c.77,0,1.38-.13,2.15-1.86a8.5,8.5,0,0,0,1.12-3.91V99.56C159,98.82,158.32,98.05,156.94,98.05Z"
                              transform="translate(-143.15 -92.63)"/>
                    </svg>
                    {isGerm ? DE.like : EN.like}</a>
                <a click={(e) => {
                }} className={`svg_icon pointer ${article.followStatus ?  'social-icon-active' : ''}`}>
                  <svg  width="17px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.08 18">
                        <path class="cls-1" d="M280.29,283.57a1,1,0,0,0-1-.92h-.13a4.85,4.85,0,0,1-3.83,1.85,4.75,4.75,0,0,1-3.78-1.85h-.19a1,1,0,0,0-1,.92l-1,6.72a1,1,0,0,0,1,.93h9.9a1,1,0,0,0,1-.93Z" transform="translate(-268.95 -275.77)" />
                        <path class="cls-1" d="M275.34,282.85a3.32,3.32,0,1,0-3.31-3.32A3.32,3.32,0,0,0,275.34,282.85Z" transform="translate(-268.95 -275.77)" />
                        <line class="cls-2" x1="12.65" y1="0.67" x2="12.65" y2="2.71" />
                        <line class="cls-2" x1="12.65" y1="2.71" x2="12.65" y2="4.75" />
                        <line class="cls-2" x1="12.65" y1="2.71" x2="10.67" y2="2.71" />
                        <line class="cls-2" x1="14.63" y1="2.71" x2="12.65" y2="2.71" />
                    </svg>
                    {
                        article.followStatus ?
                            <span> {isGerm ? DE.following : EN.following}</span>
                            : <span> {isGerm ? DE.follow : EN.follow}</span>
                    }
                </a>
            </div>
        </div>
    );
}
