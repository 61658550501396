import React, { Component, createRef } from 'react';
import { Button, Form, Media } from 'react-bootstrap';
import { Link, withRouter } from "react-router-dom";
import Layout from "../../components/Layout";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from '../../Languages/DE';
import { callAPI } from "../../helper/apiUtils";
import { EN } from '../../Languages/EN';
import { $crud } from "../../helper/CrudFactory";
import { ReactComponent as SmilyIcon } from "../../Icons/SmilyDeselected.svg"
import { BASE_URL } from "../../helper/constants";
import Carousel from "react-multi-carousel";
import { Scrollbars } from "react-custom-scrollbars";
import { BsArrowLeft, BsArrowRight, BsFillXCircleFill } from "react-icons/all";
import { BsChevronDown } from "react-icons/bs";
import ProjectListDialog from "./Dialogs/ProjectListDialog";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { convertBranchRole, convertBuildings, convertHtmlString, urlify } from '../../helper/convertor';
import { IconButton } from '@material-ui/core';
import PlayerFeed from '../../components/PlayerFeed';
import ReadMores from '../../components/ReadMores';
import DepartLocaDialogue from '../Company/Dialogs/DepartLocaDialogue';
import { connect } from "react-redux";
import RCPlayer from '../../components/RCPlayer';
const extractUrls = require("extract-urls");

const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: 6,
        backgroundColor: 'rgba(240, 132, 56, 0.8)'
    };
    return <div className="thumb-style" style={{ ...style, ...thumbStyle }} {...props} />;
};

const renderThumbx = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: 6,
        backgroundColor: 'rgba(240, 132, 56, 0.8)'
    };
    return <div />;
};

const CustomScrollbars = props => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        renderTrackHorizontal={renderThumbx}
        {...props}
    />
);

class CreateCompanyArticle extends Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.imageInputRef = createRef(null);
        this.state = {
            buildings: [],
            chars_left: 60,
            article: {
                title: "",
                description: "",
                isEmojiUsed: false,
                uploadType: "",
                file: [],
                link: "",
                buildingId: "",
                product: ""
            },
            expand: false,
            preview: false,
            profile: {},
            company: {},
            viewProject: false,
            editorState: "",
            _Link: [],
            departments: [],
            deptLocationId: "",
            isCompanyAdmin: false,
            viewLoader: false,
            modalData: [],
            showPlayer: false,
            initialIndex: 0
        }
    }


    getCompanyDetails = async () => {
        this.setState({ loading: true })
        const id = this.props?.location?.state?.companyId;
        const { data } = await callAPI(`/company/${id}/profile`, {}, "GET");
        if (data.status) {
            console.log('---data', data)
            this.setState({ company: data.profile })
            if (data.profile.position == "COMPANY ADMIN") {
                this.setState({ isCompanyAdmin: true })
            }
            if (data.profile.position == "COMPANY_DEPARTMENT_ADMIN") {
                this.setState({ deptLocationId: [data.profile.deptLocationId] })
            }
            this._getDepartmentAndLocations()
            // if (this.props?.location?.state?.from == 'internal') {
            //     this._getDepartmentAndLocations()
            // }
            // else {
            //     this.setState({ loading: false })
            // }
        }
        else {
            this.setState({ loading: false })
        }
    };

    _getDepartmentAndLocations = async () => {
        const id = this.props?.location?.state?.companyId;
        const { data } = await callAPI(`/company/getDeptLocation/${id}`, {}, "GET");
        if (data.status) {
            this.setState({ departments: data.data.getCompanyDept })
            this.setState({ loading: false })
        }
        else {
            this.setState({ loading: false })
        }
    }




    toggleSmily = (value) => {
        this.setState({ article: { ...this.state.article, isEmojiUsed: !value } });
    }

    removeImage = (file, index) => {
        const article = this.state.article;
        article.file = [];
        this.setState({ article: article });
    };

    expandArticle = () => {
        this.setState({ expand: true })
    }


    removeAllFields = async () => {
        if (this.state.article.title) {
            await $crud.confirm({
                title: this.context.isGerm ? DE.confirm : EN.confirm,
                textContent: this.context.isGerm ? 'Wollen Sie den Artikel wirklich verwerfen?' : `Do you really want to discard the article?`,
                options: {
                    ok: this.context.isGerm ? DE.ok : EN.ok,
                    cancel: this.context.isGerm ? DE.cancel : EN.cancel,
                },
            });
            this.setState({
                article: {
                    title: "",
                    description: "",
                    isEmojiUsed: false,
                    uploadType: "",
                    file: [],
                    link: "",
                    buildingId: "",
                    product: "",
                },
                chars_left: 60,
            }, () => {
                this.props.history.goBack()
                // this.props.history.push({
                //     pathname: `/companies`,
                // });
            });
        }
    }


    setParam = (key, value) => {
        this.setState(state => {
            return {
                ...state,
                article: {
                    ...state.article,
                    [key]: value
                }
            }
        })
    }


    handleWordCount = async (event) => {
        const charCount = event.target.value.length;
        this.setState({
            chars_left: 60 - charCount
        })
        await this.setParam('title', event.target.value)
    }


    get = async () => {

        const { data } = await callAPI('/building/my', {}, 'GET')
        this.setState({
            buildings: data.buildings
        })

        console.log(data)
    }

    componentDidMount() {
        console.log(this.props)
        this.setState({ articleType: this.props?.location?.state?.articleType == 1 ? 1 : 0, companyIndex: this.props?.location?.state?.companyIndex })
        this.get()
        this.getProfile();
        this.getCompanyDetails()


    }

    getProfile = async () => {
        const { data } = await callAPI(`/user/profile`, {}, 'GET')
        console.log(data)
        this.setState({ profile: data.profile })
    }
    // getCompanyDetails = async () => {
    //     const id = this.props.match.params.id
    //     const { data } = await callAPI(`/company/${id}/companyById`, {}, 'GET')
    //     console.log(data)
    //     this.setState({ company: data.company })
    // }


    choseFiles = async (e) => {
        // e.preventDefault();
        // const file = await $crud.chooseFile();
        // console.log(file)
        // const {article} = this.state
        // article.file = [file]
        // this.setState({article: article})


        e.preventDefault();
        const { files } = e.target;
        if (files.length > 0) {
            const filesArray = Object.keys(files).map((key) =>
                this.getPreview(files[key])
            );
            const previewFiles = await Promise.all(filesArray);
            this.setState((state) => {
                return {
                    ...state,
                    article: {
                        ...state.article,
                        file: [...state.article.file, ...previewFiles],
                    },
                };
            });
            this.setState({ _Link: [...this.state._Link, ...previewFiles] })
        }
    }
    getPreview = (file) => {
        return new Promise((resolve) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = (e) => {
                file.url = fileReader.result;
                resolve(file);
            };
        });
    };
    onInputClick = (event) => {
        event.target.value = "";
    };
    handlePreview = () => {
        // if (this.state.article.title) {
        //     this.setState({preview: true});
        // }

        if (!this.state.article.title) {
            $crud.notify({
                type: "error",
                message: "Please enter article title first.",
            });
        }
        else {
            this.setState({ preview: true });
        }
    }
    postArticle = async () => {
        const regex = /(<a[^>]*>.*?<\/a>)|(https?:\/\/[^\s<>\"]+)/g;
        this.setState({ loading: true })
        const { article, deptLocationId, departments, articleType } = this.state;
        let formData = new FormData()
        formData.append('title', article.title)
        formData.append('description', article.description ? article.description.replace(regex, (match, p1, p2) => {
            // p1 will contain the matched <a> tag if it exists
            // p2 will contain the URL if it exists
            if (p1) {
                // If <a> tag exists, return it as it is
                return p1;
            } else {
                // If not, wrap the URL in <a> tag
                return `<a href="${p2}">${p2}</a>`;
            }
        }) : "")
        formData.append('isEmojiUsed', article.isEmojiUsed)
        formData.append('uploadType', (article.file.length > 0) ? 'media' : 'none')
        formData.append('link', '')
        formData.append('buildingId', article.buildingId)
        formData.append('product', article.product)
        formData.append('articleType', this.state.articleType.toString())
        if (this.state.articleType.toString() == "1") {
            if (this.props?.location?.state?.deptLocationId || (deptLocationId && deptLocationId.length > 0)) {
                let ids = deptLocationId ? (Array.isArray(deptLocationId) ? deptLocationId : [deptLocationId]) : [this.props?.location?.state?.deptLocationId]
                for (let i = 0; i < ids.length; i++) {
                    formData.append(`deptLocation`, ids[i]);
                }
            }
            else if (articleType == 0 && departments.length > 0) {
                for (let i = 0; i < departments.length; i++) {
                    formData.append(`deptLocation`, departments[i].id);
                }
            }
        }
        if (article.file && article.file.length > 0) {
            article.file.map((f, index) => {
                formData.append(`file`, f)
            })
        }
        $crud.post(this.props?.location?.state?.companyId ? `/company/${this.props?.location?.state?.companyId}/article` : `/company/${this.props?.location?.state?.companyId}/article`, formData).then(res => {
            $crud.notify({
                type: res.status ? "success" : 'error',
                message: res.message
            })
            if (res.status) {
                this.props.history.goBack()
                // this.props.history.push({
                //     // pathname: `/company-details/${id}`,
                //     pathname: `/articles`,
                //     state: { companyIndex: this.props?.location?.state?.companyIndex, isPosted: true }
                // });
                // this.props.history.goBack()

            }
            this.setState({ loading: true })
        }).catch(() => {
            this.setState({ loading: true })

        })
    }
    onEditorStateChange = (editorState) => {
        const regex = /(<a[^>]*>.*?<\/a>)|(https?:\/\/[^\s<>\"]+)/g;
        let currentContentAsHTML = JSON.stringify(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
        this.setState(state => {
            return {
                ...state,
                article: {
                    ...state.article,
                    description: currentContentAsHTML,
                },
                editorState,
            }
        })
        this.setParam(
            "description",
            convertHtmlString(
                currentContentAsHTML
                    .replace(regex, (match, p1, p2) => {
                        if (p1) {
                            return p1;
                        } else {
                            return `<a href="${p2}">${p2}</a>`;
                        }
                    })
            )
        );
        this.setState({ _Link: [] }, () => {
            this._checkForLink(currentContentAsHTML)
        })
    };
    _checkForLink = (currentContentAsHTML) => {
        let { article } = this.state
        let datax = extractUrls(currentContentAsHTML);
        datax = datax && Array.isArray(datax) ? datax.map(e => { return e.replace('&quot', '') }) : []
        let data = [...new Set(datax)];
        let all = []
        if (data && data.length > 0) {
            for (let obj of data) {
                let x = {
                    href: obj
                }
                let lnk = obj
                if (lnk.match('vimeo')) {
                    x.videoType = 'vimeo'
                    all.push(x)
                }
                if (lnk.match('youtube') || lnk.match('youtu')) {
                    x.videoType = 'youtube'
                    all.push(x)
                }
            }
            if (all.length > 0) {
                this.setState({ _Link: [] }, () => {
                    this.setState({ _Link: [...new Set([...all, ...article.file])] })
                })
            }
            else if (article.file && article.file.length > 0) {
                this.setState({ _Link: [] }, () => {
                    this.setState({ _Link: [...new Set(article.file)] })
                })
            }
        }
        else {
            if (article.file && article.file.length > 0) {
                this.setState({ _Link: [] }, () => {
                    this.setState({ _Link: [...new Set(article.file)] })
                })
            }
            else
                this.setState({ _Link: [] })
        }
    }
    renderImages = (isPreview = false) => {
        const files = this.state.article.file;
        if (files?.length === 0) {
            return null;
        }
        return (
            <div
                className="col-lg-12 p-2 position-relative"
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    overflow: files.length > 2 ? "auto" : "",
                    maxHeight: files.length > 2 ? "240px" : "",
                }}
            >
                {!isPreview &&
                    <BsFillXCircleFill
                        size={20}
                        className="pointer position-absolute"
                        style={{ right: 12, top: 12 }}
                        color="red"
                        onClick={(e) => this.removeImage()}
                    />
                }
                {files.map((each) => {
                    return (
                        <img
                            className={files.length >= 2 ? "upload-multi-group-image" : ""}
                            src={each.url}
                            key={each?.name}
                            alt={each?.name}
                        />
                    );
                })}
            </div>
        );
    };
    render() {
        const regex = /(<a[^>]*>.*?<\/a>)|(https?:\/\/[^\s<>\"]+)/g;
        let { _Link, article, articleType, departments, showDepartModal, loading, modalData, initialIndex, showPlayer } = this.state
        return (
            <React.Fragment>
                <Layout>
                    <div className="w-300 following" style={{ display: "flex", flexDirection: "column", height: '93vh' }}>
                        {loading && (
                            <div className="website_loader">
                                <div className="loader" />
                            </div>
                        )}
                        {!this.state.preview ?
                            <div className={articleType == 1 ? "main_heading main_heading-internal" : "main_heading"} style={{ marginBottom: 1 }}>
                                <button style={{ borderWidth: 0, backgroundColor: "#fff" }} onClick={() => {
                                    this.props.history.goBack()
                                }}>
                                    {articleType == 1 ?
                                        <img className="mr-0" width={10}
                                            src={process.env.PUBLIC_URL + "/assets/img/backx.svg"} />
                                        :
                                        <img className="mr-0" width={10}
                                            src={process.env.PUBLIC_URL + "/assets/img/back.svg"} />
                                    }

                                </button>
                                <img src={process.env.PUBLIC_URL + "/assets/img/create-article.svg"} />
                                {this.state.company.name}
                            </div>
                            :
                            <></>
                        }
                        {this.state.preview ?
                            <div style={{flex:1, overflow:"scroll"}}>
                                {
                                    this.state.preview &&
                                    <div>
                                        <div className="p-2">
                                            <Media>
                                                {
                                                    this.props?.location?.state?.from != 'internal' && this.state.company?.avatar ?
                                                        <img
                                                            width={40}
                                                            height={40}
                                                            style={{ borderRadius: 5 }}
                                                            className="mr-3 news_profile"
                                                            src={this.state.company.logo ? BASE_URL + this.state.company.logo : this.state.company.avatar ? BASE_URL + this.state.company.avatar : process.env.PUBLIC_URL + "/assets/img/Companies-No-Image.svg"}
                                                            alt="Generic placeholder"
                                                        />
                                                        :
                                                        <img
                                                            width={40}
                                                            height={40}
                                                            style={{ borderRadius: 5 }}
                                                            className="mr-3 news_profile"
                                                            src={this.props.user.avatar ? BASE_URL + this.props.user.avatar : process.env.PUBLIC_URL + "/assets/img/Companies-No-Image.svg"}
                                                            alt="Generic placeholder"
                                                        />
                                                }

                                                <Media.Body>
                                                    <div className="w-100 authore_name personal_article">
                                                        <h6 className="m-0">{this.props?.location?.state?.from != 'internal' ? this.state.company.companyName : (this.props.user.firstName + " " + this.props.user.lastName)}</h6>
                                                        {this.props?.location?.state?.from != 'internal' ?
                                                            <h6><span className="text-muted text-normal"
                                                                style={{ fontSize: 13 }}>
                                                                <span style={{ color: "#000", fontStyle: "normal" }}>{this.state.profile.fullName + ' - '}</span>
                                                                <span className="text-italic">{convertBranchRole(this.props.user.buildingIndustryRole, this.context.isGerm)}</span></span>
                                                            </h6>
                                                            :
                                                            <h6 className="m-0"><span className="text-italic">{convertBranchRole(this.props.user.buildingIndustryRole, this.context.isGerm)}</span></h6>
                                                        }
                                                    </div>
                                                    <div className="w-100 personal_article_2">
                                                        {
                                                            this.state.article.product ?
                                                                <h6>Product: <span className="text-muted"
                                                                    style={{ fontSize: 13 }}>{this.state.article.product}</span>
                                                                </h6> : ''
                                                        }
                                                    </div>
                                                </Media.Body>
                                            </Media>
                                            <strong className="mb-2 mt-2 d-block"
                                                style={{ color: '#212529', fontSize: 13 }}>{this.state.article.title}</strong>
                                            <p className="small"
                                                style={{ color: '#212529' }}>
                                                <ReadMores>
                                                    {this.state.article?.description.replace(regex, (match, p1, p2) => {
                                                        // p1 will contain the matched <a> tag if it exists
                                                        // p2 will contain the URL if it exists
                                                        if (p1) {
                                                            // If <a> tag exists, return it as it is
                                                            return p1;
                                                        } else {
                                                            // If not, wrap the URL in <a> tag
                                                            return `<a href="${p2}">${p2}</a>`;
                                                        }
                                                    })}
                                                </ReadMores>
                                            </p>
                                        </div>
                                        <PlayerFeed
                                            Links={_Link}
                                            article={article}
                                            isC={true}
                                            prev={true}
                                            onPress={(data, index) => {
                                                this.setState({ showPlayer: true, modalData: data, initialIndex: index })
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                            :
                            <div style={{ overflowY: "scroll", overflowX: "hidden", flex: 1 }}>
                                {
                                    !this.state.preview &&
                                    <div>

                                        <div className="article_form">
                                            <div className="form-bottom">
                                                <Form className="add_company_from">
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Control type="text" rows={1}
                                                            maxLength="60"
                                                            value={this.state.article.title}
                                                            onChange={this.handleWordCount}
                                                            placeholder={this.context.isGerm ? DE.title : EN.title} />
                                                        <Form.Text
                                                            className="text-muted"> {this.state.chars_left} {this.context.isGerm ? DE.characters_remaining : EN.characters_remaining}</Form.Text>
                                                    </Form.Group>

                                                    <Form.Group controlId="formBasic">
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ flex: 1 }}>
                                                                <Editor
                                                                    stripPastedStyles={true}
                                                                    editorState={this.state.editorState}
                                                                    toolbar={{
                                                                        options: ["inline"],
                                                                        inline: {
                                                                            inDropdown: false,
                                                                            options: ["bold", "italic", "underline"],
                                                                        },
                                                                        list: { inDropdown: true },
                                                                        textAlign: { inDropdown: true },
                                                                        link: { inDropdown: true },
                                                                        history: { inDropdown: true }
                                                                    }}
                                                                    editorClassName="demo-editor"
                                                                    toolbarClassName={this.props?.location?.state?.articleType == 1 ? "toolbar-class custom-richtext-internal" : "toolbar-class custom-richtext"}
                                                                    onEditorStateChange={this.onEditorStateChange}
                                                                />
                                                            </div>
                                                            {/* <div style={{ position: "absolute", right: 0, }}>
                                                            <div className="form-icons" style={{ marginRight: 20, marginTop: 3 }}>
                                                                <SmilyIcon
                                                                    className={this.state.article.isEmojiUsed ? articleType == 1 ? 'smily-icon-internal active' : 'smily-icon active' : 'smily-icon'}
                                                                    onClick={e => this.toggleSmily(this.state.article.isEmojiUsed)}
                                                                    width={20} />
                                                            </div>
                                                        </div> */}
                                                        </div>
                                                    </Form.Group>
                                                    {(departments && departments.length > 0 && this.state.isCompanyAdmin && articleType == 1) ?
                                                        <Form.Group className="select_coustom border-0 hello">
                                                            <a
                                                                className="register_role pointer text-truncate position-relative"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ showDepartModal: true })
                                                                    // setShowDepartModal(true);
                                                                }}
                                                            >
                                                                {!this.state.names && (
                                                                    <span>
                                                                        {this.context.isGerm
                                                                            ? DE.dep_loc
                                                                            : EN.dep_loc}{" "}
                                                                    </span>
                                                                )}
                                                                {this.state.names && <span>{this.state.names}</span>}
                                                                <BsChevronDown />
                                                            </a>

                                                        </Form.Group>
                                                        :
                                                        <></>
                                                    }
                                                    <div
                                                        onClick={() => this.imageInputRef.current.click()}
                                                        style={{ textAlign: "center", display: "flex", flexDirection: "column", marginTop: 20, marginBottom: 20 }}>
                                                        <input
                                                            type="file"
                                                            hidden
                                                            multiple
                                                            onChange={this.choseFiles}
                                                            onClick={this.onInputClick}
                                                            accept=".jpg, .png, .jpeg"
                                                            ref={this.imageInputRef}
                                                        />
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/assets/img/Add_rounded.svg"}
                                                            height={40} width={40}
                                                            style={{ alignSelf: "center" }}
                                                        />
                                                        <span style={{ fontStyle: "italic", color: "grey", marginTop: 5 }}>{this.context.isGerm ? DE.add_images : EN.add_images}</span>
                                                    </div>
                                                    {/* {_Link && _Link.length > 0 && */}
                                                    <PlayerFeed
                                                        Links={_Link}
                                                        article={article}
                                                        isC
                                                        removeImage={(i) => {
                                                            let { article } = this.state
                                                            let ind = article.file.findIndex(e => e.name == _Link[i].name)
                                                            if (ind !== -1) {
                                                                let x = { ...article }
                                                                let b = [...x.file]
                                                                b.splice(ind, 1)
                                                                x = { ...x, file: b }
                                                                this.setState({ article: x }, () => {
                                                                    this._checkForLink(article.description)
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    {/* } */}
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {this.state.preview ?
                            <div className="row justify-content-between m-0 p-2 button-group-3">
                                <Button style={{ marginTop: 8, marginBottom: 8 }} className={articleType == 1 ? "request-btnx" : "request-btn"}
                                    onClick={() => this.setState({ preview: false })}><BsArrowLeft
                                        size={20} /> {this.context.isGerm ? DE.back : EN.back}</Button>
                                <Button style={{ marginTop: 8, marginBottom: 8 }} className={articleType == 1 ? "request-btnx" : "request-btn"}
                                    onClick={() => {
                                        if (this.state.isCompanyAdmin && this.state.departments.length > 0 && articleType == 1) {
                                            if (!this.state.deptLocationId) {
                                                $crud.notify({
                                                    type: 'error',
                                                    message: "Please select department/location"
                                                })
                                            }
                                            else
                                                this.postArticle()
                                        }
                                        else if (!this.state.isCompanyAdmin || this.props?.location?.state?.from != 'internal') {
                                            this.postArticle()
                                        }
                                        else {
                                            this.postArticle()
                                        }
                                    }}>{this.context.isGerm ? DE.post : EN.post} <BsArrowRight
                                        size={20} /></Button>
                            </div>
                            :
                            <></>
                        }
                        {!this.state.preview ?
                            <div className="button-group-3" style={{}}>
                                <Button
                                    style={{ marginTop: 8, marginBottom: 8 }}
                                    className={this.state.article.title ? articleType == 1 ? "btn request-btnx" : "btn request-btn" : articleType == 1 ? "btn request-btnx disable-btn-internal" : "btn request-btn disable-btn"}
                                    type="reset"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        this.removeAllFields()
                                    }}>
                                    {this.context.isGerm ? DE.abort : EN.abort}
                                </Button>
                                <Button
                                    style={{ marginTop: 8, marginBottom: 8 }}
                                    className={this.state.article.title ? articleType == 1 ? "btn request-btnx" : "btn request-btn" : articleType == 1 ? "btn request-btnx disable-btn-internal" : "btn request-btn disable-btn"}
                                    onClick={this.handlePreview}
                                    type="button"> {this.context.isGerm ? DE.preview : EN.preview}</Button>
                                <Button style={{ marginTop: 8, marginBottom: 8 }} className={this.state.article.title ? articleType == 1 ? "btn request-btnx" : "btn request-btn" : articleType == 1 ? "btn request-btnx disable-btn-internal" : "btn request-btn disable-btn"}
                                    onClick={() => {
                                        if (articleType == 1) {
                                            if (this.state.isCompanyAdmin && this.state.departments.length > 0) {
                                                if (!this.state.deptLocationId) {
                                                    $crud.notify({
                                                        type: 'error',
                                                        message: this.context.isGerm ? 'Bitte mind. ein/e Abteilung/Standort/Team auswählen' : "Please select min. 1 Department/Location/Team"
                                                    })
                                                }
                                                else
                                                    this.postArticle()
                                            }
                                            else if (!this.state.isCompanyAdmin || this.props?.location?.state?.from != 'internal') {
                                                this.postArticle()
                                            }
                                            else {
                                                this.postArticle()
                                            }
                                        }
                                        else {
                                            this.postArticle()
                                        }
                                    }}>
                                    {this.context.isGerm ? DE.post : EN.post}
                                </Button>
                            </div>
                            :
                            <></>
                        }
                        {showDepartModal && (
                            <DepartLocaDialogue
                                articleType={articleType}
                                handleClose={(e) => this.setState({ showDepartModal: false })}
                                positions={departments}
                                multiple
                                onSelectMultiple={(ids, names) => {
                                    this.setState({ deptLocationId: ids, names })
                                }}
                            />
                        )}
                    </div>
                    {
                        this.state.viewProject &&
                        <ProjectListDialog isIntenal={this.props?.location?.state?.articleType == 1 ? true : false} handleClose={() => this.setState({ viewProject: false })}
                            setParam={this.setParam} buildings={this.state.buildings} />
                    }
                </Layout>

            </React.Fragment>
        );
    }
}

// export default withRouter(CreateCompanyArticle);
const mapStateToProps = (state) => {
    const { article, drawerOpen, isExternalSelected, selectedCompanyId, user } = state;
    return {
        article: article,
        drawerOpen: drawerOpen,
        isExternalSelected,
        selectedCompanyId,
        user: user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CreateCompanyArticle));
