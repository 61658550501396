import React, { useEffect, useRef, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import LanguageContext from "../../helper/LanguageContext";
import { callAPI } from "../../helper/apiUtils";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import { BASE_URL, KJUUP_COLORS } from "../../helper/constants";
import { $user } from "../../helper/UserFactory";
import {
  GROUPS,
  GROUPS_BOTTOM,
  OPEN_BOTTOM_CHATS,
  OPEN_CHATS,
} from "../../store";
import { connect } from "react-redux";
import { convertBranchRole } from "../../helper/convertor";
import { async } from "@firebase/util";
import { $crud } from "../../helper/CrudFactory";
import InviteContact from "../Project/InviteContact";
import { Discuss, Rings, Oval } from 'react-loader-spinner'


const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};




function FollowOneToOne(props) {
  const { isGerm } = React.useContext(LanguageContext);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [userList, setUsersList] = useState([]);
  const [inviteLink, setInviteLink] = useState(null)
  const [page, setPage] = useState(1)
  const getUsers = async (pg, val) => {
    // const { data } = await callAPI(`/user/suggestion?passCompany=false&limit=100&page=${pg ? pg : page}${val ? `&query=${val}` : ""}`, {}, "GET");
    const { data } = await callAPI(`/user/suggestion`, {}, "POST", {
      passCompany: false,
      page: pg ? pg : page,
      limit: 100,
      query: val ? val : ''
    });
    // const arrList = await data.users.filter(user => user.myCompanies.length > 0); //remove the company length condition
    await CreateList(data?.data?.suggestions, data?.data?.totalCnt, val);
  };
  const [loader, setLoader] = useState([])

  const _onFollowClick = async (followingId) => {
    const { data } = await callAPI(`/user/${followingId}/follow?id=${followingId}`, {}, "GET");
    $crud.notify({
      type: data.status ? "success" : "error",
      message: data.message,
    });
    getUsers()
  }

  const handleScroll = (e) => {
    const bottom = (e.target.scrollHeight - e.target.scrollTop) <= e.target.clientHeight;
    console.log((e.target.scrollHeight - e.target.scrollTop), e.target.clientHeight)
    if (bottom) { getUsers() }
  }

  const CreateList = async (all, totalCnt, val) => {
    if (!search && !val) {
      if (all.length > 0 && all.length < totalCnt) {
        setPage(page + 1)
        if (users.length == 0) {
          setUsers(all);
          setUsersList(all);
        }
        else {
          let tmp = [...users]
          tmp = [...tmp, ...all]
          let xx = tmp.filter((value, index, self) => {
            return index === self.findIndex((t) => (
              t.id === value.id
            ))
          })
          setUsers(xx);
          setUsersList(xx);
        }
      }
    }
    else {
      let xx = all.filter((value, index, self) => {
        return index === self.findIndex((t) => (
          t.id === value.id
        ))
      })
      setUsers(xx);
      setUsersList(xx);
    }
    setLoader(false)
  };

  useEffect(() => {
    getUsers();
  }, []);

  const filterUsers = async (text) => {
    setSearch(text);
    setPage(1)
    setUsers([])
    setUsersList([])
    setLoader(true)
    getUsers(1, text)
    // text = text.trim()
    // if (text.length > 0) {
    //   let uu = userList.filter(i => {
    //     const fullName = `${i.firstName}${i.lastName}`.toLowerCase();
    //     const reversedFullName = `${i.firstName}${i.lastName}`.toLowerCase();
    //     const trimmedSearchValue = text.replace(/\s+/g, '').toLowerCase();
    //     return (fullName.includes(trimmedSearchValue) || reversedFullName.includes(trimmedSearchValue))
    //   });
    //   setUsers(uu)
    // }
    // else {
    //   setUsers([...userList])
    // }
  };

  const addProjectUser = async (u) => {
    const user = $user.get();
    const { data } = await callAPI(`/group`, {}, "POST", {
      groupType: "user",
      isOneToOne: true,
      users: [user?.id, u.id],
    });
    if (data.chatExists) {
      if (props.action === "top") {
        const group = await props.groups.find((cht) => cht.id === data.chat.id);
        console.log(group);
        if (group) {
          const chk = await props.openChats.find((g) => g.id === data.chat.id);
          console.log(chk);
          if (chk) {
            await props.updateOpenChats(
              _.uniqBy([...props.openChats, chk], "id")
            );
          } else {
            await props.updateOpenChats([...props.openChats, group]);
            const updateGroup = await props.groups.map((g) => {
              if (g.id === group.id) {
                return { ...g, isOpenedTop: true };
              } else {
                return { ...g };
              }
            });
            props.updateGroups(updateGroup);
          }
        }
      } else {
        const group = await props.groupsBottom.find(
          (cht) => cht.id === data.chat.id
        );
        console.log(group);
        if (group) {
          const chk = await props.openBottomChats.find(
            (g) => g.id === data.chat.id
          );
          console.log(chk);
          if (chk) {
            await props.updateOpenBottomChats(
              _.uniqBy([...props.openBottomChats, chk], "id")
            );
          } else {
            await props.updateOpenBottomChats([
              ...props.openBottomChats,
              group,
            ]);
            const updateGroup = await props.groupsBottom.map((g) => {
              if (g.id === group.id) {
                return { ...g, isOpenedBottom: true };
              } else {
                return { ...g };
              }
            });
            props.updateGroupsBottom(updateGroup);
          }
        }
      }
    }
    props.afterAddUser(u);
    props.hide();
  };

  const scrollbarsRef = useRef(null);

  return (
    <div>
      {!inviteLink ?
        <div style={{ height: "92vh", display: "flex", flexDirection: "column", marginLeft: 2, marginRight: 2 }}>
          <div className="main_heading">
            <a onClick={(e) => props.hide()} className="main_heading pointer">
              <img
                width={10}
                src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
              />
              {isGerm ? DE.cancel : EN.cancel}
            </a>
          </div>
          <div className="invite add_one_to_user">
            <div className="admin_right">
              <div className="search_div">
                <div className="search">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      autocomplete="off"
                      value={search}
                      type="text"
                      placeholder={isGerm ? DE.search : EN.search}
                      onChange={(e) => filterUsers(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>
              <div
                className="bg-purple bg-white"
                style={{ margin: "10px 0" }}
              >
                <a
                  onClick={(e) => setInviteLink(true)}
                  className="middle-title pointer"
                >
                  <span>
                    <img
                      width={30}
                      src={
                        process.env.PUBLIC_URL + "/assets/img/Link-deselected.svg"
                      }
                    />
                  </span>
                  {isGerm ? DE.invite_with_link : EN.invite_with_link}
                </a>
              </div>
            </div>
          </div>
          <div
            className="wscroll right_wscroll"
            style={{ paddingLeft: 10, paddingRight: 10, overflowX: "hidden", overflowY: "scroll", flex: 1 }}
            onScroll={handleScroll}
            ref={scrollbarsRef}
          >
            {
              loader ?
                <div style={{ flex: 1, zIndex: 100, textAlign: "center", display: "flex" }}>
                  <div style={{ flex: 1 }} />
                  <Oval
                    height={40}
                    width={40}
                    color={KJUUP_COLORS.base_color}
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor={"#fff"}
                    strokeWidth={5}
                    strokeWidthSecondary={5}
                  />
                  <div style={{ flex: 1 }} />

                </div>
                :
                <></>
            }
            <div className="admin-content">
              {users &&
                _.sortBy(
                  users,
                  [(us) => us?.lastName?.toLowerCase()],
                  ["lastName"]
                ).map((u, i) => (
                  <div key={i} style={{ borderBottom: "1px solid #dfdfdf" }}>
                    <div key={i} className="admin-contact pb-2" style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ flex: 1 }}>
                        <div className="row m-0">
                          <div
                            className="col-md-12 p-0 pointer"
                            onClick={(e) => addProjectUser(u)}
                          >
                            <div className="admin-control">
                              <img
                                width={40}
                                height={40}
                                className="news_profile"
                                style={{ borderRadius: 100 }}
                                src={
                                  u.img
                                    ? BASE_URL + u.img
                                    : process.env.PUBLIC_URL +
                                    "/assets/img/head.svg"
                                }
                              // alt="Generic placeholder"
                              />
                              <h4>
                                {u.name}
                                <span>
                                  {convertBranchRole(u.position, isGerm) ||
                                    u.position}
                                </span>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ textAlign: "center", paddingTop: 10 }}>
                        <Button
                          style={{ backgroundColor: "#f08438", borderWidth: 0, color: "#fff", height: 35, width: null }}
                          onClick={() => _onFollowClick(u.id)}
                        >
                          <p>
                            {isGerm ? DE.follow : EN.follow}
                          </p>
                        </Button>
                      </div>
                    </div>
                    {/* <h5 className="mt-2">{user.name}</h5> */}
                    {/* {user.names &&
                            _.sortBy(
                              user.names,
                              [(us) => us?.lastName?.toLowerCase()],
                              ["lastName"]
                            ).map((u, i) => (
                              <div key={i} className="admin-contact" style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ flex: 1 }}>
                                  <div className="row m-0">
                                    <div
                                      className="col-md-12 p-0 pointer"
                                      onClick={(e) => addProjectUser(u)}
                                    >
                                      <div className="admin-control">
                                        <img
                                          width={40}
                                          height={40}
                                          className="news_profile"
                                          style={{ borderRadius: 100 }}
                                          src={
                                            u.avatar
                                              ? BASE_URL + u.avatar
                                              : process.env.PUBLIC_URL +
                                              "/assets/img/head.svg"
                                          }
                                          alt="Generic placeholder"
                                        />
                                        <h4>
                                          {u.name}
                                          <span>
                                            {convertBranchRole(u.position, isGerm) ||
                                              u.position}
                                          </span>
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ textAlign: "center", paddingTop: 10 }}>
                                  <Button
                                    style={{ backgroundColor: "#f08438", borderWidth: 0, color: "#fff", height: 35, width: null }}
                                    onClick={() => _onFollowClick(u.id)}
                                  >
                                    <p>
                                      {isGerm ? DE.follow : EN.follow}
                                    </p>
                                  </Button>
                                </div>
                              </div>
                            ))} */}
                  </div>
                ))}
            </div>
          </div>
        </div>
        :
        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
          <InviteContact handleClose={(e) => setInviteLink(false)} />
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  const { groups, groupsBottom, openChats, openBottomChats } = state;
  return {
    groups: groups,
    groupsBottom: groupsBottom,
    openChats: openChats,
    openBottomChats: openBottomChats,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateGroups: (groups) => dispatch({ type: GROUPS, groups: groups }),
    updateOpenChats: (chats) =>
      dispatch({ type: OPEN_CHATS, openChats: chats }),
    updateGroupsBottom: (groups) =>
      dispatch({ type: GROUPS_BOTTOM, groupsBottom: groups }),
    updateOpenBottomChats: (chats) =>
      dispatch({ type: OPEN_BOTTOM_CHATS, openBottomChats: chats }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FollowOneToOne));
