import React, { Component, createRef } from "react";
import { Button, Form, Media } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { Link, withRouter } from "react-router-dom";
import 'react-multi-carousel/lib/styles.css';
import Layout from "../../components/Layout";
import { $user } from "../../helper/UserFactory";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { callAPI } from "../../helper/apiUtils";
import { EN } from "../../Languages/EN";
import { $crud } from "../../helper/CrudFactory";
import { ReactComponent as SmilyIcon } from "../../Icons/SmilyDeselected.svg";
import { BASE_URL } from "../../helper/constants";
import { Skeleton } from "@material-ui/lab";
import { Scrollbars } from "react-custom-scrollbars";
import { BsArrowLeft, BsArrowRight, BsFillXCircleFill } from "react-icons/all";
import { BsChevronDown } from "react-icons/bs";
import ProjectListDialog from "./Dialogs/ProjectListDialog";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { convertHtmlString, urlify } from "../../helper/convertor";
import IconButton from "@material-ui/core/IconButton";
import PlayerFeed from "../../components/PlayerFeed";
import ReadMores from "../../components/ReadMores";
const extractUrls = require("extract-urls");

const user = $user.get();

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

class AddPersonalArticle extends Component {
  static contextType = LanguageContext;

  constructor(props) {
    super(props);
    this.imageInputRef = createRef(null);
    this.state = {
      buildings: [],
      chars_left: 60,
      article: {
        title: "",
        description: "",
        isEmojiUsed: false,
        uploadType: "",
        file: [],
        link: "",
        buildingId: "",
        product: "",
      },
      expand: false,
      preview: false,
      profile: {},
      viewProject: false,
      editorState: "",
      _Link: []
    };
  }

  toggleSmily = (value) => {
    this.setState({ article: { ...this.state.article, isEmojiUsed: !value } });
  };

  removeImage = (file, index) => {
    const article = this.state.article;
    article.file = [];
    this.setState({ article: article });
  };
  expandArticle = () => {
    this.setState({ expand: true });
  };

  removeAllFields = async () => {
    if (this.state.article.title) {
      await $crud.confirm({
        title: this.context.isGerm ? DE.confirm : EN.confirm,
        textContent: this.context.isGerm ? 'Wollen Sie den Artikel wirklich verwerfen?' : `Do you really want to discard the article?`,
        options: {
          ok: this.context.isGerm ? DE.ok : EN.ok,
          cancel: this.context.isGerm ? DE.cancel : EN.cancel,
        },
      });
      this.setState({
        article: {
          title: "",
          description: "",
          isEmojiUsed: false,
          uploadType: "",
          file: [],
          link: "",
          buildingId: "",
          product: "",
        },
        chars_left: 60,
      }, () => {
        this.props.history.goBack()
      });
    }
  };

  setParam = (key, value) => {
    this.setState((state) => {
      return {
        ...state,
        article: {
          ...state.article,
          [key]: value,
        },
      };
    });
  };

  handleWordCount = async (event) => {
    const charCount = event.target.value.length;
    this.setState({
      chars_left: 60 - charCount,
    });
    await this.setParam("title", event.target.value);
  };

  get = async () => {
    const { data } = await callAPI("/building/my", {}, "GET");
    this.setState({
      buildings: data.buildings,
    });

    console.log(data);
  };

  componentDidMount() {
    this.get();
    this.getProfile();
  }

  getProfile = async () => {
    const { data } = await callAPI(`/user/profile`, {}, "GET");
    console.log(data);
    this.setState({ profile: data.profile });
  };

  getPreview = (file) => {
    return new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
        file.url = fileReader.result;
        resolve(file);
      };
    });
  };

  choseFiles = async (e) => {
    e.preventDefault();
    const { files } = e.target;
    if (files.length > 0) {
      const filesArray = Object.keys(files).map((key) =>
        this.getPreview(files[key])
      );
      const previewFiles = await Promise.all(filesArray);
      this.setState((state) => {
        return {
          ...state,
          article: {
            ...state.article,
            file: previewFiles,
          },
        };
      });
      this.setState({ _Link: [...this.state._Link, ...previewFiles] })
    }
  };

  handlePreview = () => {
    if (!this.state.article.title) {
      $crud.notify({
        type: "error",
        message: "Please enter article title first.",
      });
    } else {
      this.setState({ preview: true });
    }
  };
  postArticle = async (e) => {
    e.preventDefault();
    const { article } = this.state;
    let formData = new FormData();
    if (article.title) {
      formData.append("title", article.title);
      formData.append("description", convertHtmlString(article.description));
      formData.append("isEmojiUsed", article.isEmojiUsed);
      formData.append("uploadType", article.file.length > 0 ? "media" : "none");
      formData.append("link", "");
      formData.append("buildingId", article.buildingId || "");
      formData.append("product", article.product);
      // formData.append("file", article.file);
      article.file.forEach((file, index) => {
        formData.append(`file`, file);
      });
      const data = await $crud.post("/user/article", formData);
      console.log(data);
      $crud.notify({
        type: data.status ? "success" : "error",
        message: data.message,
      });
      this.setState((state) => {
        return {
          ...state,
          article: {
            ...state.article,
            title: "",
            description: "",
            isEmojiUsed: false,
            uploadType: "",
            file: [],
            link: "",
            buildingId: "",
            product: "",
          },
          preview: false,
          chars_left: 60,
        };
      });
      if (data.status) {
        this.props.history.push({
          pathname: `/profile`,
        });
      }
    } else {
      await $crud.notify({
        type: "error",
        message: "Please add all mandatory fields",
      });
    }
  };
  _checkForLink = (currentContentAsHTML) => {
    let { article } = this.state
    let datax = extractUrls(currentContentAsHTML);
    let data = [...new Set(datax)];
    let all = []
    if (data && data.length > 0) {
      for (let obj of data) {
        let x = {
          href: obj
        }
        let lnk = obj
        if (lnk.match('vimeo')) {
          x.videoType = 'vimeo'
          all.push(x)
        }
        if (lnk.match('youtube') || lnk.match('youtu')) {
          x.videoType = 'youtube'
          all.push(x)
        }
      }
      if (all.length > 0) {
        this.setState({ _Link: [] }, () => {
          this.setState({ _Link: [...new Set([...all, ...article.file])] })
        })
      }
      else if (article.file && article.file.length > 0) {
        this.setState({ _Link: [] }, () => {
          this.setState({ _Link: [...new Set(article.file)] })
        })
      }
    }
    else {
      if (article.file && article.file.length > 0) {
        this.setState({ _Link: [] }, () => {
          this.setState({ _Link: [...new Set(article.file)] })
        })
      }
      else
        this.setState({ _Link: [] })
    }
  }
  onEditorStateChange = (editorState) => {
    let currentContentAsHTML = JSON.stringify(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    this.setState({ _Link: [] }, () => {
      this._checkForLink(currentContentAsHTML)
    })
    this.setParam(
      "description",
      urlify(
        convertHtmlString(
          currentContentAsHTML
            ?.replaceAll("<p", " <p")
            ?.replaceAll("</p", " </p")
        )
      )
    );
    this.setState({ editorState: editorState });
  };

  onInputClick = (event) => {
    event.target.value = "";
  };

  renderImages = (isPreview = false) => {
    const files = this.state.article.file;
    if (files?.length === 0) {
      return null;
    }
    return (
      <div
        className="col-lg-12 p-2 position-relative"
        style={{
          display: "flex",
          flexWrap: "wrap",
          overflow: files.length > 2 ? "auto" : "",
          maxHeight: files.length > 2 ? "240px" : "",
        }}
      >
        {!isPreview &&
          <BsFillXCircleFill
            size={20}
            className="pointer position-absolute"
            style={{ right: 12, top: 12 }}
            color="red"
            onClick={(e) => this.removeImage()}
          />
        }
        {files.map((each) => {
          return (
            <img
              className={files.length >= 2 ? "upload-multi-group-image" : ""}
              src={each.url}
              key={each?.name}
              alt={each?.name}
            />
          );
        })}
      </div>
    );
  };

  render() {
    let { _Link, article } = this.state
    return (
      <React.Fragment>
        <Layout>
          <div className="w-300 following add_article add_article_personal">
            <CustomScrollbars
              autoHide
              autoHideTimeout={500}
              autoHideDuration={200}
              className="bottom-bar"
            >
              {!this.state.preview && (
                <div>
                  <div className="main_heading">
                    <button style={{ borderWidth: 0, backgroundColor: "#fff" }} onClick={() => {
                      this.props.history.goBack()
                    }}>
                      <img
                        className="mr-0"
                        width={10}
                        src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                      />
                    </button>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/create-article.svg"
                      }
                    />
                    {user?.firstName} {user?.lastName}
                  </div>
                  <div className="article_form">
                    <div className="form-bottom">
                      <Form className="add_company_from">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            type="text"
                            rows={1}
                            maxLength="60"
                            value={this.state.article.title}
                            onChange={this.handleWordCount}
                            placeholder={
                              this.context.isGerm
                                ? DE.title + "*"
                                : EN.title + "*"
                            }
                          />
                          <Form.Text className="text-muted">
                            {" "}
                            {this.state.chars_left}{" "}
                            {this.context.isGerm
                              ? DE.characters_remaining
                              : EN.characters_remaining}
                          </Form.Text>
                        </Form.Group>
                        {/* <div className="row">{this.renderImages()}</div> */}
                        <Form.Group controlId="formBasic">
                          <div className="form-icons">
                            <IconButton
                              type="button"
                              onClick={() => this.imageInputRef.current.click()}
                            >
                              <input
                                type="file"
                                hidden
                                multiple
                                onChange={this.choseFiles}
                                onClick={this.onInputClick}
                                accept=".jpg, .png, .jpeg"
                                ref={this.imageInputRef}
                              />
                              <img
                                width={19}
                                src={
                                  process.env.PUBLIC_URL + "/assets/img/Add.svg"
                                }
                                alt="Generic placeholder"
                              />
                            </IconButton>
                            <SmilyIcon
                              className={
                                this.state.article.isEmojiUsed
                                  ? "smily-icon active"
                                  : "smily-icon"
                              }
                              onClick={(e) =>
                                this.toggleSmily(this.state.article.isEmojiUsed)
                              }
                              width={20}
                            />
                          </div>

                          <Editor
                            stripPastedStyles={true}
                            editorState={this.state.editorState}
                            toolbar={{
                              options: ["inline"],
                              inline: {
                                inDropdown: false,
                                options: ["bold", "italic", "underline"],
                              },
                              list: { inDropdown: true },
                              textAlign: { inDropdown: true },
                              link: { inDropdown: true },
                              history: { inDropdown: true },
                            }}
                            editorClassName="demo-editor"
                            toolbarClassName="toolbar-class custom-richtext"
                            onEditorStateChange={this.onEditorStateChange}
                          />
                        </Form.Group>
                        {_Link && _Link.length > 0 &&
                          <PlayerFeed
                            Links={_Link}
                            article={article}
                            isC
                            removeImage={(i) => {
                              let { article } = this.state
                              let ind = article.file.findIndex(e => e.fileUrl == _Link[i].fileUrl)
                              if (ind !== -1) {
                                let x = { ...article }
                                x.file.splice(ind, 1)
                                console.log(x)
                                this.setState({ article: x }, () => {
                                  this._checkForLink(article.description)
                                })
                              }
                            }}
                          />
                        }
                        <div className="title_icon">
                          <h4>
                            {this.context.isGerm
                              ? DE.optional_information
                              : EN.optional_information}
                          </h4>
                        </div>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            value={this.state.article.product}
                            onChange={(e) =>
                              this.setParam("product", e.target.value)
                            }
                            type="text"
                            placeholder={
                              this.context.isGerm
                                ? DE.featured_products
                                : EN.featured_products
                            }
                          />
                        </Form.Group>
                        <Form.Group className="select_coustom border-0">
                          <a
                            className="register_role pointer text-truncate position-relative"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ viewProject: true });
                            }}
                          >
                            {!this.state.article.buildingId && (
                              <span>
                                {this.context.isGerm
                                  ? DE.projects
                                  : EN.projects}{" "}
                              </span>
                            )}
                            {this.state.article.buildingId && (
                              <span>{this.state.article.buildingName}</span>
                            )}
                            <BsChevronDown />
                          </a>
                        </Form.Group>

                        <div className="button-group-3">
                          <Button
                            className={
                              this.state.article.title
                                ? "btn request-btn"
                                : "btn request-btn disable-btn"
                            }
                            type="reset"
                            onClick={(e) => {
                              e.preventDefault();
                              this.removeAllFields();
                            }}
                          >
                            {this.context.isGerm ? DE.abort : EN.abort}
                          </Button>
                          <Button
                            className={
                              this.state.article.title
                                ? "btn request-btn"
                                : "btn request-btn disable-btn"
                            }
                            onClick={this.handlePreview}
                            type="button"
                          >
                            {this.context.isGerm ? DE.preview : EN.preview}
                          </Button>
                          <Button
                            className="btn request-btn"
                            onClick={(e) => {
                              e.target.setAttribute("disabled", true);
                              this.postArticle(e);
                            }}
                          >
                            {this.context.isGerm ? DE.post : EN.post}
                          </Button>
                        </div>
                      </Form>
                      <p className="mandatory">
                        *
                        {this.context.isGerm
                          ? DE.mandatory_fiels
                          : EN.mandatory_fiels}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {this.state.preview && (
                <div>
                  <div className="p-2">
                    <Media>
                      {this.state.profile?.avatar && (
                        <img
                          width={40}
                          height={40}
                          style={{ borderRadius: 5 }}
                          className="mr-3 news_profile"
                          src={
                            this.state.profile.avatar
                              ? BASE_URL + this.state.profile.avatar
                              : process.env.PUBLIC_URL +
                              "assets/img/profile.png"
                          }
                          alt="Generic placeholder"
                        />
                      )}
                      {!this.state.profile?.avatar && (
                        <div className="svg_icon mr-3 news_profile">
                          <svg
                            width="50px"
                            height="50px"
                            viewBox="0 0 99.84 99.83"
                          >
                            <path
                              className="prf1"
                              d="M318.68,147.82l2.2-2.17c-6.17-2.82-12.74-4.33-15.94-5.74S293,134.24,293,134.24s-.42-6.86-3-7.26l.43-4.64s3-6.45,3-10.48c0,0,3,2.42,4.05-3s2.56-15.51-2.13-13.1c0,0,1-8,.11-12.89s-5.22-19.74-24.82-19.74-24,14.91-24.81,19.74.1,12.89.1,12.89c-4.68-2.41-3.19,7.66-2.13,13.1s4,3,4,3c0,4,3,10.48,3,10.48l.43,4.64c-2.56.4-3,7.26-3,7.26s-8.72,4.26-11.92,5.67c-2.76,1.22-3.54,3.29-9.82,6.16a13.32,13.32,0,0,0,6.9,4.52,16.83,16.83,0,0,0,4.41.6h34l36.59-.22c1.8,0,5.46-.25,7.59-1.24.33-.21,2.27-1.67,2.58-1.91"
                              transform="translate(-223.59 -52.87)"
                            />
                            <rect
                              className="prf2"
                              x="1.5"
                              y="1.5"
                              width="96.84"
                              height="96.83"
                              rx="10.49"
                              ry="10.49"
                            />
                          </svg>
                        </div>
                      )}
                      <Media.Body>
                        <div className="w-100 authore_name personal_article">
                          {this.state.profile.fullName ? (
                            <h6>
                              {this.state.profile.fullName ? (
                                this.state.profile.fullName
                              ) : (
                                <Skeleton variant="text" />
                              )}
                              {this.state.profile.id ? (
                                <span
                                  className="text-muted"
                                  style={{ fontSize: 13 }}
                                >
                                  {this.state.profile.buildingIndustryRole
                                    ? ` | ${this.state.profile.buildingIndustryRole}`
                                    : ""}
                                </span>
                              ) : (
                                <Skeleton variant="text" />
                              )}
                            </h6>
                          ) : (
                            <Skeleton variant="text" />
                          )}
                        </div>
                        <div className="w-100 personal_article_2">
                          {this.state.article.product ? (
                            <h6>
                              Product:{" "}
                              <span
                                className="text-muted"
                                style={{ fontSize: 12 }}
                              >
                                {this.state.article.product}
                              </span>
                            </h6>
                          ) : (
                            ""
                          )}
                        </div>
                      </Media.Body>
                    </Media>
                    <strong
                      className="mb-2 mt-2 d-block"
                      style={{ color: "#212529", fontSize: 13 }}
                    >
                      {this.state.article.title}
                    </strong>
                    <p className="small" style={{ color: "#212529" }}>
                      <ReadMores>
                        {this.state.article?.description}
                      </ReadMores>
                    </p>
                  </div>
                  {this.state.article.isEmojiUsed && (
                    <div className="text-right p-2">
                      <SmilyIcon
                        className={
                          this.state.article.isEmojiUsed
                            ? "smily-icon active"
                            : "smily-icon"
                        }
                        width={20}
                      />
                    </div>
                  )}
                  {_Link && _Link.length > 0 &&
                    <PlayerFeed
                      Links={_Link}
                      article={article}
                    />
                  }
                  <div className="row justify-content-between m-0 p-2 button-group-3">
                    <Button
                      className="request-btn"
                      onClick={() => this.setState({ preview: false })}
                    >
                      <BsArrowLeft size={20} /> {this.context.isGerm ? DE.back : EN.back}
                    </Button>
                    <Button
                      className="request-btn"
                      onClick={(e) => {
                        e.target.setAttribute("disabled", true);
                        this.postArticle(e);
                      }}
                    >
                      Post <BsArrowRight size={20} />
                    </Button>
                  </div>
                </div>
              )}
            </CustomScrollbars>
          </div>
          {this.state.viewProject && (
            <ProjectListDialog
              handleClose={() => this.setState({ viewProject: false })}
              setParam={this.setParam}
              buildings={this.state.buildings}
            />
          )}
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouter(AddPersonalArticle);
