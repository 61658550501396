import React, { useRef, useState } from 'react';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { SET_RUNNING_CALLS, SHOW_LIST_ONGOING, START_JITSI_CALL } from '../store';
import { callAPI } from '../helper/apiUtils';
import './jitisi.css';   

const RCJitsiCall = ({ isJitsiCallStarted, user }) => {
    const { roomId } = isJitsiCallStarted;
    const [showLoader, setShowLoader] = React.useState(true);
    const dispatch = useDispatch();
    const displayName = `${user.firstName} ${user.lastName}`;
    const runningCalls = useSelector(e => e.runningCalls)
    const apiRef = useRef(null);
    const [participants, setParticipants] = useState([]);
    const [callAdmin, setCallAdmin] = useState(null);
    const [isCallActive, setIsCallActive] = useState(false);



    const EndJitsiCall = async () => {
        dispatch({ type: START_JITSI_CALL, payload: false });
        let newRunningCalls = runningCalls.filter(e => e.groupId !== roomId);
        if (newRunningCalls.length === 0) {
            dispatch({ type: SHOW_LIST_ONGOING, payload: false })
        }
        dispatch({ type: SET_RUNNING_CALLS, payload: newRunningCalls })
        await callAPI(`/videochat/leave`, {}, "POST", { room: roomId });       
    }


    const LeaveTheCall = async () => {
        dispatch({ type: START_JITSI_CALL, payload: false });
        let newRunningCalls = runningCalls.filter(e => e.groupId !== roomId);
        if (newRunningCalls.length === 0) {
            dispatch({ type: SHOW_LIST_ONGOING, payload: false })
        }
        dispatch({ type: SET_RUNNING_CALLS, payload: newRunningCalls })
    }




    const handleApiReady = (api) => {
        setShowLoader(false);
        apiRef.current = api;
        // Event listener for participant join
        api.addListener("participantJoined", (participant) => {
            console.log("participantJoined", participant);
            setParticipants((prev) => [
                ...prev,
                { id: participant.id, name: participant.displayName },
            ]);
        });
        // Event listener for participant leave
        api.addListener("participantLeft", (participant) => {
            console.log("participantLeft", participant);
            // LeaveTheCall()
            setParticipants((prev) =>
                prev.filter((p) => p.id !== participant.id)
            );
        });
        // Event listener for call ended
        api.addListener("videoConferenceLeft", () => {
            console.log("videoConferenceLeft");
            EndJitsiCall()
        });
    };


    return (
        <div style={{ position: "absolute", left: 0, right: 0, bottom: 0, top: 0, zIndex: 1000, background: '#111B22', display: "flex", flexDirection: "column" }}>
            {showLoader && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
            <JitsiMeeting
                domain="jitsi.kjuup.de"
                roomName={roomId || ""}
                configOverwrite={{
                    startWithAudioMuted: true,
                    startScreenSharing: false,
                    enableEmailInStats: false,
                    'prejoinConfig.enabled': false
                }}
                interfaceConfigOverwrite={{
                    APP_NAME: 'Kjuup',
                    SHOW_JITSI_WATERMARK: false,
                    TOOLBAR_BUTTONS: [
                        "microphone",
                        "camera",
                        "closedcaptions",
                        "desktop",
                        "embedmeeting",
                        "fullscreen",
                        "hangup",
                        "chat",
                        "recording",
                        "livestreaming",
                        "etherpad",
                        "sharedvideo",
                        "settings",
                        "raisehand",
                        "videoquality",
                        "filmstrip",
                        "invite",
                        "stats",
                        "shortcuts",
                        "tileview",
                        "download",
                        "help",
                        "mute-everyone",
                        "security",
                    ]
                }}
                userInfo={{
                    displayName: displayName,
                    email: user.email,
                }}
                onApiReady={handleApiReady}
                getIFrameRef={(iframeRef) => {
                    iframeRef.style.height = '100vh'; // Ensure iframe is always visible
                }}
            />
        </div>
    );
};

export default RCJitsiCall;
