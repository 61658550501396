import React, { Component } from 'react';
import { Button, Form, Media } from 'react-bootstrap';
import { Link, withRouter } from "react-router-dom";
import Layout from "../../components/Layout";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from '../../Languages/DE';
import { callAPI } from "../../helper/apiUtils";
import { EN } from '../../Languages/EN';
import { $crud } from "../../helper/CrudFactory";
import { ReactComponent as SmilyIcon } from "../../Icons/SmilyDeselected.svg"
import { BASE_URL } from "../../helper/constants";
import Carousel from "react-multi-carousel";
import { Scrollbars } from "react-custom-scrollbars";
import { BsArrowLeft, BsArrowRight, BsFillXCircleFill } from "react-icons/all";
import { BsChevronDown } from "react-icons/bs";
import ProjectListDialog from "./Dialogs/ProjectListDialog";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { convertBranchRole, convertHtmlString, urlify } from '../../helper/convertor';
import PlayerFeed from '../../components/PlayerFeed';
import { connect } from "react-redux";
import RCPlayer from '../../components/RCPlayer';
const extractUrls = require("extract-urls");

const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: 6,
        backgroundColor: 'rgba(240, 132, 56, 0.8)'
    };
    return <div className="thumb-style" style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = props => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
    />
);

class EditCompanyArticle extends Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            editorState: "",
            buildings: [],
            chars_left: 60,
            article: {
                id: '',
                title: "",
                description: "",
                isEmojiUsed: false,
                uploadType: "",
                file: [],
                link: "",
                buildingId: "",
                product: "",
                prevFiles: []
            },
            expand: false,
            preview: false,
            profile: {},
            company: {},
            viewProject: false,
            prevFiles: [],
            _Link: [],
            modalData: [],
            showPlayer: false,
            initialIndex: 0
        }

    }

    getDetails = async () => {
        const regex = /(<a[^>]*>.*?<\/a>)|(https?:\/\/[^\s<>\"]+)/g;
        const id = this.props.match.params.articleId
        const { data } = await callAPI(`/article/${id}`, {}, 'GET')
        let article = data.article[0]
        await this.getBase64Files(article.uploadUrl);
        const html = (article && article.description) ? article.description.replace(regex, (match, p1, p2) => {
            if (p1) {
                return p1;
            } else {
                if (p2) {
                    return `<a href="${p2}">${p2}</a>`;
                }
                return ""
            }
        }) : ""
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState(state => {
                return {
                    ...state,
                    article: {
                        id: article.id,
                        title: article.title,
                        isEmojiUsed: article.isEmojiUsed ?? false,
                        uploadType: article.uploadType,
                        prevFiles: article.uploadUrl,
                        link: article.link,
                        buildingId: article.buildingId,
                        product: article.product && article.product !== "null" ? article.product : "",
                        file: article.uploadUrl,
                    },
                    editorState,
                    chars_left: 60 - (article.title).length
                }
            })
            if (article && article.description) {
                this.setParam(
                    "description",
                    convertHtmlString(
                        article.description.replace(regex, (match, p1, p2) => {
                            if (p1) {
                                return p1;
                            } else {
                                return `<a href="${p2}">${p2}</a>`;
                            }
                        })
                    )
                );
                setTimeout(() => {
                    this._checkForLink(article.description)
                }, 500)
            }
        }
    }


    toggleSmily = (value) => {
        this.setState({ article: { ...this.state.article, isEmojiUsed: !value } });
    }
    expandArticle = () => {
        this.setState({ expand: true })
    }

    removeImage = (file, index) => {
        console.log(index)
        const article = this.state.article;
        if (article.prevFiles.length > 0) {
            const files = article.prevFiles.splice(index + 1, 1);
            const prevFiles = this.state.prevFiles.splice(index + 1, 1);
            this.setState({ prevFiles: prevFiles })
            article.prevFiles = files;
        } else {
            const files = article.file.splice(index + 1, 1);
        }
        this.setState({ article: article })
    }


    removeAllFields = async () => {
        if (this.state.article.title) {
            await $crud.confirm({
                title: "Confirm",
                textContent: `Are you sure you want to clear all this article?`,
                options: {
                    ok: "OK",
                    cancel: "Cancel"
                }
            });
            this.setState({
                article: {
                    title: "",
                    description: "",
                    isEmojiUsed: false,
                    uploadType: "",
                    file: [],
                    link: "",
                    buildingId: "",
                    product: "",
                },
                chars_left: 60
            })
        }
    }


    setParam = (key, value) => {
        this.setState(state => {
            return {
                ...state,
                article: {
                    ...state.article,
                    [key]: value
                }
            }
        })
    }


    handleWordCount = async (event) => {
        const charCount = event.target.value.length;
        this.setState({
            chars_left: 60 - charCount
        })
        await this.setParam('title', event.target.value)
    }


    get = async () => {

        const { data } = await callAPI('/building/my', {}, 'GET')
        this.setState({
            buildings: data.buildings
        })

        console.log(data)
    }

    componentDidMount() {
        this.setState({ articleType: this.props?.location?.state?.articleType == 1 ? 1 : 0 })
        this.get()
        this.getProfile();
        this.getCompanyDetails()
        this.getDetails()

    }

    getProfile = async () => {
        const { data } = await callAPI(`/user/profile`, {}, 'GET')
        console.log(data)
        this.setState({ profile: data.profile })
    }
    getCompanyDetails = async () => {
        const id = this.props.match.params.id
        const { data } = await callAPI(`/company/${id}/companyById`, {}, 'GET')
        console.log(data)
        this.setState({ company: data.company })
    }


    choseFiles = async (e) => {
        e.preventDefault();
        let file = await $crud.chooseFile();
        const { article } = this.state
        article.prevFiles = []
        file.fileUrl = file.url
        file.local = true
        let x = [...article.file, file]
        article.file = [...x]
        this.setState({ prevFiles: [] })
        this.setState({ article: article })
        this.setState({ _Link: [...new Set([...this.state._Link, ...x])] })
    }
    handlePreview = () => {
        if (this.state.article.title) {
            this.setState({ preview: true });
        }
    }

    getBase64Files = async (images) => {
        if (images.length > 0) {
            images.map(image => {
                this.toDataURL(BASE_URL + image.fileUrl, (u) => {
                    const file = this.dataURLtoFile(u, 'file.png')
                    this.setState(state => {
                        return {
                            ...state,
                            prevFiles: [...state.prevFiles, file]
                        }
                    })
                })
            })
            // return [];
        }
    }

    dataURLtoFile(dataurl, filename) {

        var arr = dataurl?.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    toDataURL = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    };


    postArticle = async () => {
        const regex = /(<a[^>]*>.*?<\/a>)|(https?:\/\/[^\s<>\"]+)/g;
        const { article } = this.state;
        let formData = new FormData()
        formData.append('id', article.id)
        formData.append('title', article.title)
        formData.append('description', article.description ? article.description.replace(regex, (match, p1, p2) => {
            // p1 will contain the matched <a> tag if it exists
            // p2 will contain the URL if it exists
            if (p1) {
                // If <a> tag exists, return it as it is
                return p1;
            } else {
                // If not, wrap the URL in <a> tag
                return `<a href="${p2}">${p2}</a>`;
            }
        }) : "")
        formData.append('isEmojiUsed', article.isEmojiUsed)
        formData.append('uploadType', ((article.file && article.file.length > 0) || (this.state.prevFiles && this.state.prevFiles.length > 0)) ? 'media' : 'none')
        formData.append('link', '')
        formData.append('buildingId', article.buildingId)
        formData.append('product', article.product)
        let fileData = '';
        if (article.file && article.file.length > 0) {
            fileData = article.file[0];
        }
        if (this.state.prevFiles && this.state.prevFiles.length > 0) {
            fileData = this.state.prevFiles[0];
        }
        delete article.prevFiles;
        // formData.append('file', fileData);

        let _serverIm = []
        let _localIm = []
        if (article.file) {
            for (let obj of article.file) {
                if (obj.local) {
                    _localIm.push(obj)
                }
                else {
                    _serverIm.push(obj)
                }
            }
        }
        if (_serverIm.length > 0)
            formData.append('fileList', JSON.stringify(_serverIm))
        else {
            formData.append('fileList', JSON.stringify([]))
        }
        _localIm.map(item => {
            formData.append('file', item);
        })

        console.log('-----------------> Formdata', formData)

        const id = this.state.company.id;
        $crud.post('/article/updateArticle', formData).then(res => {
            $crud.notify({
                type: res.status ? "success" : 'error',
                message: res.message
            })
            if (res.status) {
                this.props.history.push({
                    pathname: `/company-details/${id}`,
                });
            }

        });
    }
    onEditorStateChange = (editorState) => {
        const regex = /(<a[^>]*>.*?<\/a>)|(https?:\/\/[^\s<>\"]+)/g;
        let currentContentAsHTML = JSON.stringify(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
        this.setState(state => {
            return {
                ...state,
                article: {
                    ...state.article,
                    description: currentContentAsHTML,
                },
                editorState,
            }
        })
        this.setParam(
            "description",
            convertHtmlString(
                currentContentAsHTML
                    .replace(regex, (match, p1, p2) => {
                        if (p1) {
                            return p1;
                        } else {
                            return `<a href="${p2}">${p2}</a>`;
                        }
                    })
            )
        );
        this.setState({ _Link: [] }, () => {
            this._checkForLink(currentContentAsHTML)
        })
    };
    _checkForLink = (currentContentAsHTML) => {
        let { article } = this.state
        let datax = extractUrls(currentContentAsHTML);
        datax = datax && Array.isArray(datax) ? datax.map(e => { return e.replace('&quot', '') }) : datax
        let data = [...new Set(datax)];
        let all = []
        if (data && data.length > 0) {
            for (let obj of data) {
                let x = {
                    href: obj
                }
                let lnk = obj
                if (lnk.match('vimeo')) {
                    x.videoType = 'vimeo'
                    all.push(x)
                }
                if (lnk.match('youtube') || lnk.match('youtu')) {
                    x.videoType = 'youtube'
                    all.push(x)
                }
            }
            if (all.length > 0) {
                this.setState({ _Link: [] }, () => {
                    this.setState({ _Link: [...new Set([...all, ...((article?.file && article?.file.length) ? article?.file : [])])] })
                })
            }
            else if (article.file && article.file.length > 0) {
                this.setState({ _Link: [] }, () => {
                    this.setState({ _Link: [...new Set(article?.file)] })
                })
            }
        }
        else {
            if (article.file && article.file.length > 0) {
                this.setState({ _Link: [] }, () => {
                    this.setState({ _Link: [...new Set(article.file)] })
                })
            }
            else
                this.setState({ _Link: [] })
        }
    }
    render() {
        const regex = /(<a[^>]*>.*?<\/a>)|(https?:\/\/[^\s<>\"]+)/g;
        let { _Link, article, articleType, modalData, initialIndex, showPlayer } = this.state
        return (
            <React.Fragment>
                <Layout>
                    <div className="w-300 following" style={{ display: "flex", flexDirection: "column", height: '93vh' }}>
                        {!this.state.preview ?
                            <div className={articleType == 1 ? "main_heading main_heading-internal" : "main_heading"} style={{ marginBottom: 1 }}>
                                <button style={{ borderWidth: 0, backgroundColor: "#fff" }} onClick={() => {
                                    this.props.history.goBack()
                                }}>
                                    {articleType == 1 ?
                                        <img className="mr-0" width={10}
                                            src={process.env.PUBLIC_URL + "/assets/img/backx.svg"} />
                                        :
                                        <img className="mr-0" width={10}
                                            src={process.env.PUBLIC_URL + "/assets/img/back.svg"} />
                                    }
                                </button>
                                <img src={process.env.PUBLIC_URL + "/assets/img/create-article.svg"} />
                            </div>
                            :
                            <></>
                        }
                        <div style={{ overflowY: "scroll", overflowX: "hidden", flex: 1 }}>
                            {
                                !this.state.preview &&
                                <div>
                                    <div className="article_form">
                                        <div className="form-bottom">
                                            <Form className="add_company_from">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Control type="text" rows={1}
                                                        maxLength="60"
                                                        value={this.state.article.title}
                                                        onChange={this.handleWordCount}
                                                        placeholder={this.context.isGerm ? DE.title : EN.title} />
                                                    <Form.Text
                                                        className="text-muted"> {this.state.chars_left} {this.context.isGerm ? DE.characters_remaining : EN.characters_remaining}</Form.Text>
                                                </Form.Group>
                                                <Form.Group controlId="formBasic">
                                                    <div className="form-icons">
                                                        <a href="#">
                                                            <img onClick={e => this.choseFiles(e)} width={20}
                                                                src={process.env.PUBLIC_URL + "/assets/img/Add.svg"} />
                                                        </a>
                                                    </div>
                                                    <Editor
                                                        stripPastedStyles={true}
                                                        editorState={this.state.editorState}
                                                        toolbar={{
                                                            options: ["inline"],
                                                            inline: {
                                                                inDropdown: false,
                                                                options: ["bold", "italic", "underline"]
                                                            },
                                                            list: { inDropdown: true },
                                                            textAlign: { inDropdown: true },
                                                            link: { inDropdown: true },
                                                            history: { inDropdown: true }
                                                        }}
                                                        editorClassName="demo-editor"
                                                        toolbarClassName={this.props?.location?.state?.articleType == 1 ? "toolbar-class custom-richtext-internal" : "toolbar-class custom-richtext"}
                                                        onEditorStateChange={this.onEditorStateChange}
                                                    />
                                                </Form.Group>
                                                {_Link && _Link.length > 0 &&
                                                    <PlayerFeed
                                                        Links={_Link}
                                                        article={article}
                                                        isC
                                                        removeImage={(i) => {
                                                            let { article } = this.state
                                                            let ind = article.file.findIndex(e => e.fileUrl == _Link[i].fileUrl)
                                                            console.log(article, ind, _Link)
                                                            if (ind !== -1) {
                                                                let x = { ...article }
                                                                let b = [...x.file]
                                                                b.splice(ind, 1)
                                                                x = { ...x, file: b }
                                                                this.setState({ article: x }, () => {
                                                                    this._checkForLink(article.description)
                                                                })
                                                            }
                                                        }}
                                                    />
                                                }
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.preview &&
                                <div>
                                    <div className="p-2">
                                        <Media>
                                            {
                                                this.props?.location?.state?.from != 'internal' && this.state.company?.avatar ?
                                                    <img
                                                        width={40}
                                                        height={40}
                                                        style={{ borderRadius: 5 }}
                                                        className="mr-3 news_profile"
                                                        src={this.state.company.logo ? BASE_URL + this.state.company.logo : this.state.company.avatar ? BASE_URL + this.state.company.avatar : process.env.PUBLIC_URL + "/assets/img/Companies-No-Image.svg"}
                                                        alt="Generic placeholder"
                                                    />
                                                    :
                                                    <img
                                                        width={40}
                                                        height={40}
                                                        style={{ borderRadius: 5 }}
                                                        className="mr-3 news_profile"
                                                        src={this.props.user.avatar ? BASE_URL + this.props.user.avatar : process.env.PUBLIC_URL + "/assets/img/Companies-No-Image.svg"}
                                                        alt="Generic placeholder"
                                                    />
                                            }
                                            <Media.Body>
                                                <div className="w-100 authore_name personal_article">
                                                    <h6 className="m-0">{this.props?.location?.state?.from != 'internal' ? this.state.company.companyName : (this.props.user.firstName + " " + this.props.user.lastName)}</h6>
                                                    {this.props?.location?.state?.from != 'internal' ?
                                                        <h6 className="m-0"><span className="text-muted text-normal"
                                                            style={{ fontSize: 13 }}>{this.state.profile.fullName}<span className="text-italic">{this.state.profile.buildingIndustryRole ? ` - ${this.state.profile.buildingIndustryRole}` : ''}</span></span>
                                                        </h6>
                                                        :
                                                        <h6 className="m-0"><span className="text-italic">{convertBranchRole(this.props.user.buildingIndustryRole, this.context.isGerm)}</span></h6>
                                                    }
                                                </div>
                                                <div className="w-100 personal_article_2">
                                                    {
                                                        this.state.article.product ?
                                                            <h6>Product: <span className="text-muted"
                                                                style={{ fontSize: 13 }}>{this.state.article.product}</span>
                                                            </h6> : ''
                                                    }
                                                </div>
                                            </Media.Body>
                                        </Media>
                                        <strong className="mb-2 mt-2 d-block"
                                            style={{ color: '#212529', fontSize: 13 }}>{this.state.article.title}</strong>
                                        <p className="small"
                                            style={{ color: '#212529' }}>
                                            {
                                                (this.state.expand && this.state.article.description) ?
                                                    <span className="m-0" dangerouslySetInnerHTML={{
                                                        __html: this.state.article?.description.replace(regex, (match, p1, p2) => {
                                                            // p1 will contain the matched <a> tag if it exists
                                                            // p2 will contain the URL if it exists
                                                            if (p1) {
                                                                // If <a> tag exists, return it as it is
                                                                return p1;
                                                            } else {
                                                                // If not, wrap the URL in <a> tag
                                                                return `<a href="${p2}">${p2}</a>`;
                                                            }
                                                        })
                                                    }}></span>
                                                    :
                                                    !this.state.article?.description ?
                                                        <span className="m-0" dangerouslySetInnerHTML={{
                                                            __html: this.state.article?.description.replace(regex, (match, p1, p2) => {
                                                                // p1 will contain the matched <a> tag if it exists
                                                                // p2 will contain the URL if it exists
                                                                if (p1) {
                                                                    // If <a> tag exists, return it as it is
                                                                    return p1;
                                                                } else {
                                                                    // If not, wrap the URL in <a> tag
                                                                    return `<a href="${p2}">${p2}</a>`;
                                                                }
                                                            })
                                                        }}></span> :
                                                        <span className="m-0" dangerouslySetInnerHTML={{
                                                            __html: this.state.article?.description.replace(regex, (match, p1, p2) => {
                                                                // p1 will contain the matched <a> tag if it exists
                                                                // p2 will contain the URL if it exists
                                                                if (p1) {
                                                                    // If <a> tag exists, return it as it is
                                                                    return p1;
                                                                } else {
                                                                    // If not, wrap the URL in <a> tag
                                                                    return `<a href="${p2}">${p2}</a>`;
                                                                }
                                                            })
                                                        }} />
                                            }
                                        </p>
                                    </div>
                                    {_Link && _Link.length > 0 &&
                                        <PlayerFeed
                                            Links={_Link}
                                            article={article}
                                            onPress={(data, index) => {
                                                this.setState({ showPlayer: true, modalData: data, initialIndex: index })
                                            }}
                                        />
                                    }
                                </div>
                            }
                        </div>
                        {
                            this.state.preview ?
                                <div className="row justify-content-between m-0 p-2 button-group-3">
                                    <Button style={{ marginTop: 8, marginBottom: 8 }} className={articleType == 1 ? "request-btnx" : "request-btn"}
                                        onClick={() => this.setState({ preview: false })}><BsArrowLeft
                                            size={20} /> {this.context.isGerm ? DE.back : EN.back}</Button>
                                    <Button style={{ marginTop: 8, marginBottom: 8 }} className={articleType == 1 ? "request-btnx" : "request-btn"}
                                        onClick={() => this.postArticle()}>{this.context.isGerm ? DE.update : EN.update} <BsArrowRight
                                            size={20} /></Button>
                                </div>
                                :
                                <></>
                        }
                        {!this.state.preview ?
                            <div style={{}} className="button-group-3">
                                <Button
                                    style={{ marginTop: 8, marginBottom: 8 }}
                                    className={this.state.article.title ? articleType == 1 ? "btn request-btnx" : "btn request-btn" : articleType == 1 ? "btn request-btnx disable-btn-internal" : "btn request-btn disable-btn"}
                                    type="reset" onClick={(e) => {
                                        e.preventDefault()
                                        this.props.history.push({
                                            pathname: `/company-details/${this.state.company.id}`,
                                        });
                                    }}>
                                    {this.context.isGerm ? DE.abort : EN.abort}
                                </Button>
                                <Button
                                    style={{ marginTop: 8, marginBottom: 8 }}
                                    className={this.state.article.title ? articleType == 1 ? "btn request-btnx" : "btn request-btn" : articleType == 1 ? "btn request-btnx disable-btn-internal" : "btn request-btn disable-btn"}
                                    onClick={this.handlePreview}
                                    type="button"> {this.context.isGerm ? DE.preview : EN.preview}</Button>
                                <Button
                                    style={{ marginTop: 8, marginBottom: 8 }}

                                    className={this.state.article.title ? articleType == 1 ? "btn request-btnx" : "btn request-btn" : articleType == 1 ? "btn request-btnx disable-btn-internal" : "btn request-btn disable-btn"}
                                    onClick={() => this.postArticle()}>
                                    {this.context.isGerm ? DE.update : EN.update}
                                </Button>
                            </div>
                            :
                            <></>
                        }
                    </div>
                    {
                        this.state.viewProject &&
                        <ProjectListDialog isIntenal={this.props?.location?.state?.articleType == 1 ? true : false} handleClose={() => this.setState({ viewProject: false })}
                            setParam={this.setParam} buildings={this.state.buildings} />
                    }
                </Layout>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    const { article, drawerOpen, isExternalSelected, selectedCompanyId, user } = state;
    return {
        article: article,
        drawerOpen: drawerOpen,
        isExternalSelected,
        selectedCompanyId,
        user: user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EditCompanyArticle));


// export default withRouter(EditCompanyArticle);
