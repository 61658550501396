import React, { useEffect, useRef, useState } from 'react';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import Layout from "../../components/Layout";
import { EN } from '../../Languages/EN';
import { DE } from '../../Languages/DE';
import LanguageContext from '../../helper/LanguageContext';
import { Link, withRouter } from 'react-router-dom';
import { callAPI } from "../../helper/apiUtils";
import { BASE_URL } from "../../helper/constants";
import { Scrollbars } from 'react-custom-scrollbars';
import ShareMapDialog from "../../components/ShareMapDialog";
import { ReactComponent as AddSvg } from "../../Icons/Add.svg"
import { Positions } from "../../Languages/vars/Positions";
import { $crud } from "../../helper/CrudFactory";
import { ReactComponent as ArrowLeft } from "../../Icons/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../Icons/ArrowRight.svg";
import Carousel from "react-multi-carousel";
import { $user } from "../../helper/UserFactory";
import { ForienArticle } from '../Feeds/ForienArticle';
import { convertBranchRole, convertPosition } from '../../helper/convertor';

const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: 6,
        backgroundColor: 'rgba(240, 132, 56, 0.8)'
    };
    return <div className="thumb-style" style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = props => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
    />
);

const user = $user.get();

function ForeignCompanyDetails(props) {
    const { isGerm } = React.useContext(LanguageContext);
    const CarouselEl = useRef()
    const [slideNo, setSlideNo] = useState(0)
    const [company, setCompany] = useState({})
    let [companyBranch, setCompanyBranch] = useState()
    const [show, setShow] = useState(false)
    const [employeePosition, setEmployeePosition] = useState();
    const [showPositionDialog, setPositionDialog] = useState(false);
    const handleClose = () => setShow(false);
    const hidePositionDialog = () => setPositionDialog(false);
    const [services, setServices] = useState([]);
    const getCompanyDetails = async () => {
        const id = props.match.params.id
        const { data } = await callAPI(`/company/${id}/profile`, {}, 'GET')
        console.log(data)
        if (data.profile.articleCount > 0) {
            setSlideNo(1)
        }
        await setCompany(data.profile)
        await setCompanyBranch(data.profile.branch?.split(','))
        await setServices(data.profile.services)
    }
    useEffect(() => {
        getCompanyDetails();
    }, [props.match.params.id])


    const followCompany = async (id) => {
        const { data } = await callAPI(`/company/${id}/follow`, {
            id: id,
        }, 'GET');
        console.log(data);
        getCompanyDetails();
    }
    const expandArticle = async key => {
        console.log(key)
        const articles = company?.articles
        let article = articles[key];
        article.expand = true
        articles[key] = article;
        company.articles = articles
        await setCompany(company)
        await setCompanyBranch(company.branch?.split(','))
    }

    const positions = Positions

    const handleSubmit = async () => {
        console.log(employeePosition)
        if (!employeePosition) {
            await $crud.notify({
                type: 'error',
                message: "Please select position"
            })
        } else {
            const params = {
                name: company.name,
                email: company.email,
                corporateForm: company.corporateForm,
                branch: company.branch,
                street: company.street,
                postCode: company.postCode,
                city: company.city,
                country: company.country,
                lat: company.lat,
                long: company.long,
                position: employeePosition
            }
            const { data } = await callAPI('/company', params, 'POST')
            setPositionDialog(false)
            await $crud.notify({
                type: data.status ? 'success' : 'error',
                message: data.message
            })
        }
    }
    const approveService = async (value) => {
        const { data } = await callAPI(`/service/${value}/join`, {
            id: value
        }, 'GET')
        $crud.notify({
            type: data.status ? 'success' : 'error',
            message: data.message
        })
        getCompanyDetails();
    };
    const CustomRightArrow = () => {
        CarouselEl.current.next()
        if (company.articles.length > slideNo) {
            setSlideNo(slideNo + 1)
        }
    };

    const CustomLeftArrow = () => {
        CarouselEl.current.previous()
        if (slideNo !== 1) {
            setSlideNo(slideNo - 1)
        }
    };
    const handleLike = async (id) => {
        const articleDetails = await this.state.articles.find(article => article.id === id)
        const articlesList = this.state.articles.map(article => {
            if (article.id === articleDetails.id) {
                article.likeStatus = !articleDetails.likeStatus;
                const existUser = article.likes.find(like => like.id === user?.id)
                if (existUser) {
                    article.likes = article.likes.filter(like => like.id !== user?.id);
                    article.likeCount = articleDetails.likeCount - 1
                } else {
                    article.likes = [...article.likes, { id: user?.id, avatar: user.avatar }]
                    article.likeCount = articleDetails.likeCount + 1
                }
            }
            return article;
        })
        this.setState({ articles: articlesList })
    }

    return (
        <React.Fragment>
            <Layout>
                {
                    company &&
                    <div className="company_section w-300 pb-0">
                        <div className="bg-purple">
                            <div className="row">
                                <div className="col-md-5 pr-0">
                                    <div className="user_profile">
                                        <div className="user_img">
                                            <Link>
                                                <img onClick={()=>window.history.go(-1)} className="img-fluid"
                                                    src={process.env.PUBLIC_URL + "/assets/img/arrow_left-selected.svg"}
                                                    alt="Generic placeholder" />
                                            </Link>
                                            <img className="company_img" width={55} height={55}
                                                src={company.logo ? BASE_URL + company.logo : process.env.PUBLIC_URL + "/assets/img/Companies-No-Image.svg"}
                                                alt="Generic placeholder" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 pl-0">
                                    <div className="follow">
                                        <Link to={`/company-followers/${company.id}`}
                                            className="follow_item text-center">
                                            <h3>{company?.followerCount}</h3>
                                            <p>{isGerm ? DE.followers : EN.followers}</p>
                                        </Link>
                                        <div className="follow_item text-center">
                                            <h3>{company?.articleCount}</h3>
                                            <p>{isGerm ? DE.article : EN.article}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 text-right">
                                    <a onClick={(e) => {
                                        followCompany(company.id)
                                    }}
                                        className={company.followStatus ? "svg_icon pointer active-like-follow" : "svg_icon pointer"}>
                                        <svg className="mr-1" width="22px"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 19.81 20.9">
                                            <path
                                                d="M295.14,269.16a1.28,1.28,0,0,0-1.32-1.23h-.91a5.11,5.11,0,0,1-8.76,0h-.91a1.27,1.27,0,0,0-1.31,1.23l-1.31,9a1.28,1.28,0,0,0,1.31,1.24h13.21a1.27,1.27,0,0,0,1.3-1.24Z"
                                                transform="translate(-280.17 -258.91)" />
                                            <path className="cls-1"
                                                d="M288.53,268.2a4.42,4.42,0,1,0-4.41-4.42A4.43,4.43,0,0,0,288.53,268.2Z"
                                                transform="translate(-280.17 -258.91)" />
                                            <line className="cls-2" x1="16.72" y1="0.75" x2="16.72"
                                                y2="3.47" />
                                            <line className="cls-2" x1="16.72" y1="3.47" x2="16.72"
                                                y2="6.18" />
                                            <line className="cls-2" x1="16.72" y1="3.47" x2="14.08"
                                                y2="3.47" />
                                            <line className="cls-2" x1="19.36" y1="3.47" x2="16.72"
                                                y2="3.47" />
                                        </svg>
                                    </a>
                                    <AddSvg onClick={e => {
                                        setPositionDialog(true)
                                        setEmployeePosition('')
                                    }}
                                        className="mt-3 mr-1 pointer custom-svg"
                                        height={21} />
                                </div>
                            </div>
                            <div className="profile-flied">
                                <div className="profile_name">
                                    <h3>{company?.companyName}</h3>
                                    {company.owner ?
                                        <p>{isGerm ? DE.owner_company_admin_new : EN.owner_company_admin_new} :<span className="theme-color"> {company.owner}</span>
                                        </p> : ''}
                                    <p>                          {company.full_address ? company.full_address : `${company.houseNo ? company.houseNo + "," : ""} ${company.street}, ${company.city}, ${company.postCode ? company.postCode + "," : ""} ${company.country ? company.country : ''}`}</p>
                                    <p>{companyBranch?.map((itm, index) => (index ? ", " : "") + convertBranchRole(itm, isGerm))}</p>
                                    {company.position?
                                    <p>My Position: {convertPosition(isGerm, company.position)}</p>:<></>}
                                </div>
                            </div>
                        </div>

                        <div className="my-scroll min_height company_details_scroll">
                            <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}
                                className="bottom-bar">
                                <div className="profile-data">
                                    <div className="article padding-left-right-2">
                                        <div className="profile-flied">
                                            <div className="profile_name">
                                                <h3>{isGerm ? DE.article : EN.article}</h3>
                                            </div>
                                            <div className="icon_right">
                                                {
                                                    (company.role === 'ROLE_COMPANY_SUPER_ADMIN') ?
                                                        <Link to={`/add-company-article/${company.id}`}>
                                                            <img
                                                                src={process.env.PUBLIC_URL + "/assets/img/create-article.svg"} />
                                                        </Link> : ''
                                                }
                                            </div>
                                        </div>
                                        <div className="slider-icon">
                                            <a href="#" onClick={e => CustomLeftArrow()}>
                                                <ArrowLeft className={slideNo > 1 ? 'svg_active' : 'svg_gray'} width={9} height={17} />
                                            </a>
                                            <p>{slideNo} of {company?.articleCount} {isGerm ? DE.article : EN.article}</p>
                                            <a href="#" onClick={e => CustomRightArrow()}>
                                                <ArrowRight className={(company.articleCount > slideNo) ? 'svg_active' : 'svg_gray'} width={9} height={17} />
                                            </a>
                                        </div>
                                    </div>
                                    {
                                        company.articles &&
                                            company.articles.length > 0 ?

                                            <Carousel
                                                ref={CarouselEl}
                                                additionalTransfrom={0}
                                                arrows={false}
                                                centerMode={false}
                                                className="p-0"
                                                // containerClass="container"
                                                dotListClass=""
                                                draggable={company?.articles.length > 1}
                                                focusOnSelect={false}
                                                infinite={false}
                                                itemClass=""
                                                keyBoardControl
                                                minimumTouchDrag={80}
                                                renderButtonGroupOutside={false}
                                                renderDotsOutside={false}
                                                responsive={{
                                                    desktop: {
                                                        breakpoint: {
                                                            max: 3000,
                                                            min: 1024
                                                        },
                                                        items: 1,
                                                    },
                                                    mobile: {
                                                        breakpoint: {
                                                            max: 464,
                                                            min: 0
                                                        },
                                                        items: 1
                                                    },
                                                    tablet: {
                                                        breakpoint: {
                                                            max: 1024,
                                                            min: 464
                                                        },
                                                        items: 1
                                                    }
                                                }}
                                                sliderClass=""
                                                slidesToSlide={1}
                                                swipeable
                                            >
                                                {
                                                    company.articles &&
                                                    company.articles.map((article, key) => (
                                                        <div key={key} className="media-desc" style={{  left:"0", right:"0", top:"0", bottom:"0", zIndex:"1000", height:"100%", width:"100%" }}>   
                                                            {
                                                                <ForienArticle
                                                                    handleLike={handleLike}
                                                                    viewArticleDetail={props.viewArticle} company={company}
                                                                    reloadArticle={() => getCompanyDetails()}
                                                                    index={key}
                                                                    article={article}
                                                                    expandArticle={expandArticle}
                                                                />
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </Carousel> : ''
                                    }
                                    <div className="company_services company_download">
                                        <h5>Services
                                            {
                                                (company.role === 'ROLE_COMPANY_SUPER_ADMIN') ?
                                                    <Link to={`/add-service/${company.id}`}>
                                                        <img className="im`g-fluid"
                                                            src={process.env.PUBLIC_URL + "/assets/img/edit-pen-s.svg"} />
                                                    </Link> : ''
                                            }
                                        </h5>
                                        <small>Invite users to confirm services to the company.</small>
                                        <div className="upload">
                                            <Link to={`/invite-user/${company.id}`}>
                                                <img width={80} className="img-fluid"
                                                    src={process.env.PUBLIC_URL + "/assets/img/Share-Link-EN.svg"} />
                                            </Link>
                                        </div>
                                        <div className="row">
                                            <div className="offset-7 col-5 text-right">
                                                <small className="font-weight-bold">Approve!</small>
                                            </div>
                                        </div>
                                        {
                                            services &&
                                            services.map(service => (
                                                <div key={service.id} className="archi">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-7">

                                                            <div className="services_delete">
                                                                <Link key={service.id}
                                                                    to={`/service-members/${service.id}`}>
                                                                    <span
                                                                        className="small">{service.service} | {service.usersCount}</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <div className="radio_btn">
                                                                <label className="role-label pt-0 mt-3">
                                                                    <input value={service.id}
                                                                        disabled={service.isMember}
                                                                        checked={service.isMember}
                                                                        onChange={(e) => {
                                                                            approveService(e.target.value)
                                                                        }} type="checkbox" />
                                                                    <span className="checkmark-label" />
                                                                </label>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="company_services">
                                        <h5>{isGerm ? DE.contact : EN.contact}</h5>
                                        <div className="company_centact">
                                            <div className="website">
                                                <div className="website_img">
                                                    <img src={process.env.PUBLIC_URL + "/assets/img/Website-grey.svg"}
                                                        alt="Generic placeholder" />
                                                </div>
                                                <div className="details-company">
                                                    <h5>{isGerm ? DE.website : EN.website}</h5>
                                                    <p>{company?.website}</p>
                                                </div>
                                            </div>
                                            <div className="website">
                                                <div className="website_img">
                                                    <img src={process.env.PUBLIC_URL + "/assets/img/e-mail-grey.svg"}
                                                        alt="Generic placeholder" />
                                                </div>
                                                <div className="details-company">
                                                    <div>
                                                        <h5>Email</h5>
                                                        <p>{company?.email}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CustomScrollbars>
                        </div>
                        <ShareMapDialog company={company} show={show} handleClose={handleClose} />
                    </div>
                }
                {
                    !company &&
                    <div className="company_section w-300">
                        <div className="bg-purple">
                            <div className="row">
                                <div className="col-md-5 pr-0">
                                    <div className="user_profile">
                                        <div className="user_img">
                                            <Link to="/profile">
                                                <img className="img-fluid"
                                                    src={process.env.PUBLIC_URL + "/assets/img/arrow_left-selected.svg"}
                                                    alt="Generic placeholder" />
                                            </Link>
                                            <img width={55} height={55}
                                                src={process.env.PUBLIC_URL + "/assets/img/Companies-No-Image.svg"}
                                                alt="Generic placeholder" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 pt-3 text-center">
                            No Record Found!
                        </div>
                    </div>
                }
                <Modal show={showPositionDialog} onHide={hidePositionDialog} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{isGerm ? DE.your_position_within_company : EN.your_position_within_company}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ListGroup variant="flush">
                            {
                                positions.map(position => (
                                    <div onClick={e => setEmployeePosition(position.name_en)} key={position.id}
                                        className="form-check p">
                                        <input className="form-check-input" type="radio" name="exampleRadios"
                                            id={position.id} value={position.name_en} />
                                        <label className="form-check-label pointer" htmlFor={position.id}>
                                            {isGerm ? position.name_de : position.name_en}
                                        </label>
                                    </div>
                                ))
                            }
                        </ListGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={hidePositionDialog}>
                            {isGerm ? DE.close_modal : EN.close_modal}
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Layout>
        </React.Fragment>
    );
}

export default withRouter(ForeignCompanyDetails);
