import React, { useEffect, useState } from "react";
import { Button, Form, Media } from "react-bootstrap";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";
import { callAPI } from "../../helper/apiUtils";
import { $crud } from "../../helper/CrudFactory";
import { BASE_URL } from "../../helper/constants";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import LanguageContext from "../../helper/LanguageContext";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import { convertBranchRole } from "../../helper/convertor";
const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};
const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);
function Followings(props) {
  const { isGerm } = React.useContext(LanguageContext);
  const [followings, setFollowings] = useState([]);
  const [allFollowing, setAllFollowing] = useState([])
  const [search, setSearch] = useState("")
  const getFollowings = async () => {
    const { data } = await callAPI(`/user/following`, {}, "GET");
    const followingsList = _.sortBy(data.followings, [
      (follower) =>
        follower?.lastName?.toLowerCase() || follower?.fullName.toLowerCase(),
    ]);
    setAllFollowing([...followingsList])
    setFollowings(followingsList);
  };
  useEffect(() => {
    getFollowings();
  }, []);

  const removeFollowings = async (following) => {
    await $crud.confirm({
      title: "",
      textContent: isGerm ? `Wollen Sie ${following.fullName} wirklich aus der Liste Abonniert entfernen?` : `Do you really want to remove ${following.fullName}?`,
      options: {
        ok: isGerm ? DE.confirm : EN.confirm,
        cancel: isGerm ? DE.cancel : EN.cancel,
      },
    });
    const { data } = await callAPI(`/user/${following.id}/follow`, {}, "GET");
    $crud.notify({
      type: data.status ? "success" : "error",
      message: data.message,
    });
    await getFollowings();
  };
  const removeCompanyFollowings = async (following) => {
    await $crud.confirm({
      title: "",
      textContent: isGerm ? `Wollen Sie ${following.fullName} wirklich aus der Liste Abonniert entfernen?` : `Do you really want to remove ${following.fullName}?`,
      options: {
        ok: isGerm ? DE.confirm : EN.confirm,
        cancel: isGerm ? DE.cancel : EN.cancel,
      },
    });
    const { data } = await callAPI(
      `/company/${following.id}/follow`,
      {},
      "GET"
    );
    $crud.notify({
      type: data.status ? "success" : "error",
      message: data.message,
    });
    await getFollowings();
  };
  const searchFollowing = async (value) => {
    setSearch(value);
    value = value.trim();
    const searchResult = await allFollowing.filter((itm) => {
      if (!itm.isCompany && (itm.firstName.toLowerCase().startsWith(value.toLowerCase()) || itm.lastName.toLowerCase().startsWith(value.toLowerCase()) || itm.fullName.toLowerCase().startsWith(value.toLowerCase()))) {
        return true
      }
    });
    if (value === "") {
      setFollowings(allFollowing);
    } else {
      setFollowings(searchResult);
    }
  };

  return (
    <React.Fragment>
      <Layout>
        <div className="w-300 following">
          <div className="main_heading">
            <Link to="/profile">
              <img
                width={10}
                src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
              />
            </Link>
            {isGerm ? DE.followings : EN.followings}
          </div>
          <div className="search">
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Control
                autocomplete="off"
                onChange={(e) => searchFollowing(e.target.value)}
                value={search}
                type="search"
                placeholder={isGerm ? DE.search : EN.search}
              />
            </Form.Group>
            <img
              src={process.env.PUBLIC_URL + "assets/img/search.svg"}
              alt="Generic placeholder"
            />
          </div>
          <div className="my-scroll min_height_followers_following contact_height">
            <CustomScrollbars
              autoHide
              autoHideTimeout={500}
              autoHideDuration={200}
              className="bottom-bar"
            >
              {followings &&
                _.sortBy(
                  followings,
                  [(us) => us?.lastName?.toLowerCase()],
                  ["lastName"]
                ).map((following) => (
                  <div key={following.id} className="post_notification">
                    <div className="my_post">
                      <Media>
                        <img
                          width={40}
                          height={40}
                          className="mr-3"
                          src={
                            following.avatar
                              ? BASE_URL + following.avatar
                              :following.isCompany?process.env.PUBLIC_URL +  "/assets/img/Companies-No-Image.svg": process.env.PUBLIC_URL + "/assets/img/head.svg"
                          }
                          alt="Generic placeholder"
                          style={{ objectFit: "cover", borderRadius: following.isCompany ? 5 : "100px", }}
                        />
                        <Media.Body>
                          <div className="authore_name">
                            <Link to={following.isCompany?`/foreign-company-details/${following.id}`: `/user-profile/${following.id}`}>
                              <h5>{following.fullName}</h5>
                            </Link>
                            <p style={{ marginRight: 15 }}>{convertBranchRole(following.buildingIndustryRole, isGerm)}</p>
                          </div>
                        </Media.Body>
                        <Button
                          className="btn-sm theme-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            following.isCompany
                              ? removeCompanyFollowings(following)
                              : removeFollowings(following);
                          }}
                        >
                          {" "}
                          {isGerm ? DE.remove : EN.remove}
                        </Button>
                      </Media>
                    </div>
                  </div>
                ))}
            </CustomScrollbars>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
}

export default Followings;
