import React, {useContext, useEffect, useState} from 'react';
import {Media} from 'react-bootstrap';
import Layout from '../../components/Layout';
import {Link, useHistory, withRouter} from "react-router-dom";
import {callAPI} from "../../helper/apiUtils";
import {BASE_URL} from "../../helper/constants";
import {$user} from "../../helper/UserFactory";
import LanguageContext from '../../helper/LanguageContext';
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';
import { convertBranchRole } from '../../helper/convertor';

function ArticleLike(props) {
    const { isGerm } = useContext(LanguageContext)
    const history = useHistory();
    const [users, setUsers] = useState([])
    const [article, setArticle] = useState({})
    const user = $user.get()

    const getUsers = async () => {
        const id = props.match.params.id
        const {data} = await callAPI(`/article/${id}/listOfLikes`, {}, 'GET')
        setUsers(data.users)
    }
    
    const articleDetails = async () => {
        const id = props.match.params.id
        const {data} = await callAPI(`/article/${id}`, {}, 'GET')
        setArticle(data.article[0])
    }

    useEffect(() => {
        getUsers();
        articleDetails();
    }, [])

    const getUserDetails = (id) => {
        if (id === user?.id) {
            history.push({
                pathname: `/profile`,
            })
        } else {
            history.push({
                pathname: `/user-profile/${id}`,
                state: {articleId: props.match.params.id}
            })
        }

    }

  return (
    <React.Fragment>
      <Layout>
        <div class="w-300 following article_like">
          <div className="main_heading">
            <Link to="/articles">
              <img
                width={10}
                src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
              />
            </Link>
            {article.postedById === user?.id
              ? 
              isGerm
                ? DE.people_who_like_article
                :
                 EN.people_who_like_article
              : 
              isGerm
              ? DE.people_who_like_article
              :
               EN.people_that_like_article}
          </div>
          {users?.map((user) => (
            <div key={user?.id} className="post_notification">
              <div className="my_post">
                <Media>
                  <img
                    width={40}
                    height={40}
                    className="mr-3"
                    src={
                      user.avatar
                        ? BASE_URL + user?.avatar
                        : process.env.PUBLIC_URL + "/assets/img/head.svg"
                    }
                    alt="Generic placeholder"
                    style={{ objectFit: "cover", borderRadius: "100px", }}
                  />
                  <Media.Body>
                    <div className="authore_name">
                      <h5
                        className="pointer"
                        onClick={(e) => getUserDetails(user?.id)}
                      >
                        {" "}
                        {user?.fullName}
                      </h5>
                      <p className='simple-msg'>{convertBranchRole( user?.buildingIndustryRole, isGerm,false, true)}</p>
                    </div>
                  </Media.Body>
                </Media>
              </div>
            </div>
          ))}
        </div>
      </Layout>
    </React.Fragment>
  );
}

export default withRouter(ArticleLike);
