import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Card } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { BsChevronUp, BsChevronDown, BsSearch, BsX } from "react-icons/bs";
import { useState } from "react";
import Layout from "../../components/Layout";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import { callAPI } from "../../helper/apiUtils";
import _ from "lodash";
import { BASE_URL } from "../../helper/constants";
import { $crud } from "../../helper/CrudFactory";
import { convertPosition } from "../../helper/convertor";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  GROUP_CHATS,
  OPEN_CHATS,
  OPEN_PROJECTS,
  RESET_MARKIN_PROJECT,
} from "../../store";

function CompanySetting(props) {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const history = useHistory();
  const { isGerm } = useContext(LanguageContext);
  const [display, setDisplay] = useState("0");
  const [company, setCompany] = useState({});
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [allAdmins, setAllAdmins] = useState([]);
  const [search, setSearch] = useState("");
  const [dashboard, setDashboard] = useState({});
  const [setting, setSetting] = useState({
    language: "de",
  });
  const [totalAdmins, setTotalAdmins] = useState(0);
  const [viewLoader, setViewLoader] = useState(false);
  const [departmentPersons, setdepPersons] = useState([]);
  const [isKjuup, setIsKjuup] = useState([]);
  let departmentID =
    props.match.params.deptId && props.match.params.deptId != "undefined"
      ? props.match.params.deptId
      : null;

  useEffect(() => {
    if (props.location.state) {
      setIsKjuup(props.location.state.isKjuup);
    }
  }, [props]);

  const handleClass = (index) => {
    if (display === index) {
      setDisplay(null);
    } else {
      setDisplay(index);
    }
  };

  const getDashboard = async () => {
    const id = props.match.params.id;
    const { data } = await callAPI(`/company/${id}/dashboard`, {}, "GET");
    await setDashboard(data.dashboard);
  };
  const getSetting = async () => {
    const id = props.match.params.id;
    const { data } = await callAPI(`/company/${id}/setting`, {}, "GET");
    await setSetting(data);
  };

  const getCompanyDetails = async () => {
    const id = props.match.params.id;
    const { data } = await callAPI(`/company/${id}/profile`, {}, "GET");
    if (data.status) {
      await setCompany(data.profile);
      getCompanyAdmins(data.profile);
      getEmployeesDept(data.profile);
    }
  };
  const getCompanyAdmins = async (profile) => {
    const id = props.match.params.id;
    const { data } =
      departmentID && profile?.role !== "ROLE_COMPANY_SUPER_ADMIN"
        ? await callAPI(
          `/company/${id}/admin?deptLocation=${props.match.params.deptId}`,
          {},
          "GET"
        )
        : await callAPI(`/company/${id}/admin`);
    await CreateAdminList(data.coworker, profile);
  };

  useEffect(() => {
    getDashboard();
    getSetting();
    getCompanyDetails();
  }, [props.match.params.id]);

  const getEmployeesDept = async (profile) => {
    const id = props.match.params.id;
    const { data } = await callAPI(
      departmentID
        ? `/company/${id}/request?deptLocation=${props.match.params.deptId}`
        : `/company/${id}/request`,
      {},
      "GET"
    );
    if (data.status && data.members.length > 0) {
      getEmployees(data.members, profile);
    } else {
      getEmployees(null, profile);
    }
  };

  const getEmployees = async (depmem, profile) => {
    const id = props.match.params.id;
    const { data } =
      departmentID && profile?.role !== "ROLE_COMPANY_SUPER_ADMIN"
        ? await callAPI(
          `/company/${id}/coworker?deptLocation=${props.match.params.deptId}`,
          {},
          "GET"
        )
        : await callAPI(`/company/${id}/coworker`, {}, "GET");
    if (profile?.role !== "ROLE_COMPANY_SUPER_ADMIN") {
      let usersToFind = [];
      if (depmem?.length > 0 && data?.coworkers?.length > 0) {
        for (let obj of depmem) {
          for (let objx of data.coworkers) {
            if (obj.id == objx.id) {
              usersToFind.push(objx);
            }
          }
        }
        await CreateList(usersToFind);
      } else {
        setUsers([]);
        setAllUsers([]);
      }
    } else {
      await CreateList(data.coworkers);
    }
  };
  const CreateList = async (users) => {
    const arr = await _.uniqBy(
      users.map((user) => {
        let name = { name: user.lastName.trim()[0].toUpperCase() };
        return name;
      }),
      "name"
    );
    const updatedArr = _.sortBy(arr, ["name"]);
    const usersList = updatedArr.map((a) => {
      let names = users.filter(
        (u) => u.lastName.trim()[0].toUpperCase() === a.name
      );
      names = names.map((e, i) => {
        return { ...e };
      });
      a.names = names;
      return a;
    });
    setUsers([...usersList]);
    setAllUsers([...usersList]);
  };

  const CreateAdminList = async (admins, profile) => {
    const arr = _.uniqBy(
      admins?.map((user) => {
        let name = { name: user.lastName.trim()[0].toUpperCase() };
        return name;
      }),
      "name"
    );
    const updatedArr = _.sortBy(arr, ["name"]);
    let tt = [];
    const usersList = updatedArr.map((a) => {
      let names = admins.filter(
        (u) => u.lastName.trim()[0].toUpperCase() === a.name
      );
      names = names.map((e, i) => {
        return {
          ...e,
          adminStatus:
            e.position === "EMPLOYEE" || e.position === "COMPANY_DEPARTMENT_ADMIN"
              ? false
              : true,
        };
      });
      let tAdmins = names.filter((e) => e.adminStatus);
      tt = [...tt, ...tAdmins];
      a.names = names;
      return a;
    });
    setTotalAdmins(tt.length);
    setAdmins([...usersList]);
    setAllAdmins([...usersList]);
    _makeDep(admins, profile);
  };
  const _makeDep = (admins, profile) => {
    const arr = _.uniqBy(
      admins?.map((user) => {
        let name = { name: user.lastName.trim()[0].toUpperCase() };
        return name;
      }),
      "name"
    );
    const updatedArr = _.sortBy(arr, ["name"]);
    let tt = [];
    const usersList = updatedArr.map((a) => {
      let names = admins.filter(
        (u) => u.lastName.trim()[0].toUpperCase() === a.name
      );
      names = names.map((e, i) => {
        return { ...e, adminStatus: e.position === "EMPLOYEE" ? false : true };
      });
      let tAdmins = names.filter((e) => e.adminStatus);
      tt = [...tt, ...tAdmins];
      a.names = names;
      return a;
    });
    setdepPersons(usersList);
  };
  const filterUsers = async (searchValue) => {
    setSearch(searchValue);
    searchValue = searchValue.trim();
    if (searchValue) {
      let userListFilter = await allUsers.map((item) => {
        let filterName = item.names.filter((itm) => {
          let fullName =
            `${itm.firstName.trim()} ${itm.lastName.trim()}`.toLowerCase();
          if (
            itm.firstName.toLowerCase().startsWith(searchValue.toLowerCase()) ||
            itm.lastName.toLowerCase().startsWith(searchValue.toLowerCase()) ||
            fullName.toLowerCase().startsWith(searchValue.toLowerCase())
          ) {
            return true;
          }
        });
        return { ...item, names: filterName };
      });
      userListFilter = userListFilter.filter(
        (position) => position.names.length > 0
      );
      setUsers(userListFilter);
    } else {
      setUsers(allUsers);
    }
  };
  const filterAdmins = async (searchValue) => {
    setSearch(searchValue);
    searchValue = searchValue.trim();
    if (searchValue) {
      let userListFilter = await allAdmins.map((item) => {
        let filterName = item.names.filter((itm) => {
          let fullName =
            `${itm.firstName.trim()} ${itm.lastName.trim()}`.toLowerCase();
          if (
            itm.firstName.toLowerCase().startsWith(searchValue.toLowerCase()) ||
            itm.lastName.toLowerCase().startsWith(searchValue.toLowerCase()) ||
            fullName.toLowerCase().startsWith(searchValue.toLowerCase())
          ) {
            return true;
          }
        });
        return { ...item, names: filterName };
      });
      userListFilter = userListFilter.filter(
        (position) => position.names.length > 0
      );
      setAdmins(userListFilter);
    } else {
      setAdmins(allAdmins);
    }
  };

  const filterAdminsDeps = async (searchValue) => {
    setSearch(searchValue);
    searchValue = searchValue.trim();
    if (searchValue !== "") {
      let userListFilter = await departmentPersons.map((item) => {
        let filterName = item.names.filter((itm) => {
          let fullName =
            `${itm.firstName.trim()} ${itm.lastName.trim()}`.toLowerCase();
          if (
            itm.firstName.toLowerCase().startsWith(searchValue.toLowerCase()) ||
            itm.lastName.toLowerCase().startsWith(searchValue.toLowerCase()) ||
            fullName.toLowerCase().startsWith(searchValue.toLowerCase())
          ) {
            return true;
          }
        });
        return { ...item, names: filterName };
      });
      userListFilter = userListFilter.filter(
        (position) => position.names.length > 0
      );
      setdepPersons(userListFilter);
    } else {
      _makeDep(allAdmins);
    }
  };

  const removeUser = async (id, name, i, mainIndex) => {
    await $crud.confirm({
      title: isGerm ? DE.remove_member : EN.remove_member,
      textContent: isGerm
        ? DE.do_you_really_want_to_remove.replace("username", name)
        : EN.do_you_really_want_to_remove.replace("username", name),
      options: {
        ok: isGerm ? DE.confirm : EN.confirm,
        cancel: isGerm ? DE.cancel : EN.cancel,
      },
    });
    setViewLoader(true);
    const companyId = props.match.params.id;
    const { data } = await callAPI(
      `/company/${id}/remove/${companyId}`,
      {
        id: id,
        companyId: companyId,
      },
      "GET"
    );
    if (data.status) {
      $crud.notify({
        type: "success",
        message: data.message,
      });
      let tmp = [...users];
      tmp[mainIndex].names.splice(i, 1);
      setUsers([...tmp]);
      // setAllUsers([...tmp])
      // await getCompanyAdmins();
      // await getEmployeesDept(company);
      // getCompanyDetails()
    }
    setViewLoader(false);
  };

  const assignAdminRights = async (id, index, adminStatus, indexMain) => {
    setViewLoader(true);
    const companyId = props.match.params.id;
    const { data } = await callAPI(
      `/company/${companyId}/admin/${id}?position=${!adminStatus ? "companyAdmin" : "employee"
      }`,
      {
        user: id,
        companyId: companyId,
      },
      "GET"
    );
    if (data.status) {
      let tmp = [...allAdmins]
      tmp[indexMain] = { ...tmp[indexMain], names: tmp[indexMain].names.map((e, i) => { if (i === index) { return { ...e, adminStatus: !e.adminStatus } } else { return e } }) }
      setAllAdmins([...tmp])
      setAdmins([...tmp])
      // $crud.notify({
      //     type: 'success',
      //     message: data.message
      // })
      // await getCompanyAdmins();
    } else {
      $crud.notify({
        type: "error",
        message: data.message,
      });
    }
    setViewLoader(false);
  };

  const assignAdminRightsDepartment = async (id, index, adminStatus) => {
    setViewLoader(true);
    const companyId = props.match.params.id;
    if (!adminStatus) {
      const { data } = await callAPI(
        `/company/${companyId}/deptAdmin/${id}?${!adminStatus ? `position=departmentadmin&` : ""
        }deptLocation=${props.match.params.deptId}`,
        null,
        "GET"
      );
      if (data.status) {
        // $crud.notify({
        //     type: 'success',
        //     message: data.message
        // })
        await getCompanyAdmins();
      } else {
        $crud.notify({
          type: "error",
          message: data.message,
        });
      }
      setViewLoader(false);
    } else {
      const { data } = await callAPI(
        `/company/${companyId}/deptAdmin/${id}?deptLocation=${props.match.params.deptId}`,
        null,
        "DELETE"
      );
      if (data.status) {
        // $crud.notify({
        //     type: 'success',
        //     message: data.message
        // })
        await getCompanyAdmins();
      } else {
        $crud.notify({
          type: "error",
          message: data.message,
        });
      }
      setViewLoader(false);
    }
  };

  const toggleTranslate = async (translation) => {
    console.log(company);
    const companyId = props.match.params.id;
    let msg = "";
    if (translation) {
      msg = !isGerm
        ? `Do you really want to block automatic translation in rooms for all members of the company ${company.companyName}?`
        : `Wollen Sie die automatische Übersetzungsfunktion in Räumen für Mitglieder des Unternehmens ${company.companyName} blockieren?`;
    } else {
      msg = !isGerm
        ? `Do you really want to unblock automatic translation in rooms for all members of the company ${company.companyName}?`
        : `Wollen Sie die automatische Übersetzungsfunktion in Räumen für Mitglieder des Unternehmens ${company.companyName} erlauben?`;
    }
    await $crud.confirm({
      title: "",
      textContent: msg,
      options: {
        ok: "Confirm",
        cancel: "Cancel",
      },
    });
    const translate = translation ? "none" : "on";
    const { data } = await callAPI(
      `/company/${companyId}/switchTranslation`,
      {
        translation: translate,
      },
      "PUT"
    );
    if (data.status) {
      $crud.notify({
        type: "success",
        message: data.message,
      });
      await getSetting();
    }
    dispatch({ type: RESET_MARKIN_PROJECT, status: true });
    dispatch({ type: GROUP_CHATS, groupChats: [] });
    dispatch({ type: OPEN_CHATS, openChats: [] });
    dispatch({ type: OPEN_PROJECTS, openProjects: [] });
  };

  const _removeMyself = async () => {
    setViewLoader(true);
    const companyId = props.match.params.id;
    const { data } = await callAPI(
      `/company/${companyId}/admin/`,
      {
        id: companyId,
      },
      "delete"
    );
    if (data.status) {
      $crud.notify({
        type: "success",
        message: data.message,
      });
      await getSetting();
      setViewLoader(false);
      history.push(`/company-details/${companyId}`);
    } else {
      $crud.notify({
        type: "success",
        message: data.message,
      });
      setViewLoader(false);
      history.push(`/company-details/${companyId}`);
    }
  };

  const _removeMyselfDepartment = async () => {
    setViewLoader(true);
    const companyId = props.match.params.id;
    const { data } = await callAPI(
      `/company/${companyId}/deptAdmin/${user.id}?deptLocation=${props.match.params.deptId}`,
      null,
      "DELETE"
    );
    if (data.status) {
      $crud.notify({
        type: "success",
        message: data.message,
      });
      await getSetting();
      setViewLoader(false);
      history.push(`/company-details/${companyId}`);
    } else {
      $crud.notify({
        type: "success",
        message: data.message,
      });
      setViewLoader(false);
      history.push(`/company-details/${companyId}`);
    }
  };

  const [errorImages, setErrorImages] = useState([]);

  const handleImageError = (index) => {
    setErrorImages((prevErrors) => [...prevErrors, index]);
  };

  const handleChangeLanguage = async (e) => {
    const id = props.match.params.id;
    setSetting({ ...setting, language: e.target.value });
    const { data } = await callAPI(`/company/${id}/language`, {}, "PUT", {
      language: e.target.value,
    });
    if (data.status) {
      $crud.notify({
        type: "success",
        message: data.message,
      });
    }
  };

  return (
    <Layout>
      <div class="company_section w-300 company_list setting_profile company_profile">
        {viewLoader && (
          <div className="website_loader">
            <div className="loader" />
          </div>
        )}
        <div className="company_header">
          <div
            className={
              !isKjuup ? "main_heading" : "main_heading main_heading-internal"
            }
          >
            <Link to={`/company-details/${props.match.params.id}`}>
              <img
                width={10}
                src={
                  process.env.PUBLIC_URL +
                  (isKjuup ? "/assets/img/backx.svg" : "/assets/img/back.svg")
                }
                alt=""
              />
            </Link>
            {isGerm ? DE.settings : EN.settings} {company.name}
          </div>
        </div>
        {company.role === "ROLE_COMPANY_SUPER_ADMIN" ||
          company.position === "EMPLOYEE" ? (
          <div
            className="setting_accrd"
            style={{ overflow: "scroll", height: "82vh" }}
          >
            <Accordion defaultActiveKey="0">
              {/* <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="0"
                  onClick={(e) => handleClass("0")}
                >
                  {isGerm ? DE.dashboard : EN.dashboard}
                  {display === "0" ? (
                    <BsChevronUp className="acr_up" />
                  ) : (
                    <BsChevronDown className="acr_down" />
                  )}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div
                      className={
                        isKjuup
                          ? "dashboard_short_info-internal"
                          : "dashboard_short_info"
                      }
                    >
                      <div>
                        {dashboard.profileViews}{" "}
                        <span>
                          {isGerm ? DE.profile : EN.profile}-
                          <br /> {isGerm ? DE.views : EN.views}
                        </span>
                      </div>
                      <div>
                        {dashboard.articleViews}{" "}
                        <span>
                          {isGerm ? DE.article : EN.article}-
                          <br /> {isGerm ? DE.views : EN.views}
                        </span>
                      </div>
                      <div>
                        {dashboard.searchSeen}
                        <span>
                          {isGerm ? DE.times_seen : EN.times_seen}
                          <br /> {isGerm ? DE.in_search : EN.in_search}
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card> */}
              <Card>
                {company.role === "ROLE_COMPANY_SUPER_ADMIN" && (
                  <Accordion.Toggle
                    as={Card.Header}
                    variant="link"
                    eventKey="1"
                    onClick={(e) => handleClass("1")}
                  >
                    {isGerm ? DE.add_remove_admin : EN.add_remove_admin}
                    {display === "1" ? (
                      <BsChevronUp className="acr_up" />
                    ) : (
                      <BsChevronDown className="acr_down" />
                    )}
                  </Accordion.Toggle>
                )}
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className="admin_right">
                      <div className="search_div">
                        <div className="search">
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Control
                              autocomplete="off"
                              value={search}
                              type="search"
                              placeholder={isGerm ? DE.search : EN.search}
                              onChange={(e) => filterAdmins(e.target.value)}
                            />
                          </Form.Group>
                          <BsSearch />
                        </div>
                      </div>
                      {admins &&
                        _.sortBy(
                          admins,
                          [(us) => us?.lastName?.toLowerCase()],
                          ["lastName"]
                        ).map((admin, index) => (
                          <div className="admin-content">
                            <h5 style={{ background: "#f7f7f7" }}>
                              {admin.name}
                            </h5>
                            {admin?.names?.map((u, i) => (
                              <div
                                className="admin-contact"
                                onClick={async () => {
                                  await $crud.confirm({
                                    title: "",
                                    textContent: u.adminStatus
                                      ? isGerm
                                        ? DE.makeEmp
                                        : EN.makeEmp
                                      : isGerm
                                        ? DE.makeAdmin
                                        : EN.makeAdmin,
                                    options: {
                                      ok: isGerm ? DE.confirm : EN.confirm,
                                      cancel: isGerm ? DE.cancel : EN.cancel,
                                    },
                                  });
                                  assignAdminRights(u.id, i, u.adminStatus, index);
                                }}
                              >
                                <div className="row m-0 ">
                                  <div className="col-md-10 p-0">
                                    <div className="admin-control">
                                      {errorImages.includes(index) ? (
                                        <img
                                          width={40}
                                          height={40}
                                          style={{
                                            borderRadius: 100,
                                            overflow: "hidden",
                                          }}
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/img/head.svg"
                                          }
                                          alt="Default Image"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            u.avatar
                                              ? BASE_URL + u.avatar
                                              : process.env.PUBLIC_URL +
                                              "/assets/img/head.svg"
                                          }
                                          onError={() =>
                                            handleImageError(index)
                                          }
                                          alt={`Image ${index}`}
                                          width={40}
                                          height={40}
                                          style={{
                                            borderRadius: 100,
                                            overflow: "hidden",
                                          }}
                                        />
                                      )}
                                      {/* <img width={40} height={40} style={{ borderRadius: 5, overflow: 'hidden' }}
                                                                                        src={u.avatar ? BASE_URL + u.avatar : process.env.PUBLIC_URL + "/assets/img/head.svg"}
                                                                                    /> */}
                                      <h4>
                                        {u.fullName}
                                        <span>
                                          {convertPosition(isGerm, u.position)}
                                        </span>
                                        {u.department_name &&
                                          u.location_name &&
                                          u.location_name != "null" ? (
                                          <span>
                                            {u.department_name +
                                              "/" +
                                              u.location_name}
                                          </span>
                                        ) : (
                                          <span>{u.department_name}</span>
                                        )}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="col-md-2 pr-0">
                                    <div className="custom_checkbox">
                                      <label
                                        className={
                                          company?.isKjuup
                                            ? "role-label-internal"
                                            : "role-label"
                                        }
                                      >
                                        <input
                                          // checked={u.addStatus}
                                          checked={u.adminStatus}
                                          type="checkbox"
                                          value={u.id}
                                          onClick={(e) => {
                                            // addUser(u);
                                          }}
                                        />
                                        <span
                                          className={
                                            company?.isKjuup
                                              ? "checkmark-label-internal"
                                              : "checkmark-label"
                                          }
                                        />
                                      </label>
                                    </div>
                                    {/* <div className="custom_checkbox">
                                                                                    <label
                                                                                        className="container_checkbox cust-checkbox">
                                                                                        <input type="checkbox"
                                                                                            checked={u.adminStatus} />
                                                                                        <span
                                                                                            className="checkmark" style={{ height: '24px', width: '24px' }}></span>
                                                                                    </label>
                                                                                </div> */}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {props?.match?.params?.deptId !== "undefined" && (
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    variant="link"
                    eventKey="5"
                    onClick={(e) => handleClass("5")}
                  >
                    {isGerm ? DE.add_remove_dep_admin : EN.add_remove_dep_admin}
                    {display === "5" ? (
                      <BsChevronUp className="acr_up" />
                    ) : (
                      <BsChevronDown className="acr_down" />
                    )}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>
                      <div className="admin_right">
                        <div className="search_div">
                          <div className="search">
                            <Form.Group controlId="exampleForm.ControlInput1">
                              <Form.Control
                                autocomplete="off"
                                value={search}
                                type="search"
                                placeholder={isGerm ? DE.search : EN.search}
                                onChange={(e) =>
                                  filterAdminsDeps(e.target.value)
                                }
                              />
                            </Form.Group>
                            <BsSearch />
                          </div>
                        </div>
                        {departmentPersons &&
                          _.sortBy(
                            departmentPersons,
                            [(us) => us?.lastName?.toLowerCase()],
                            ["lastName"]
                          ).map((admin, index) => (
                            <div className="admin-content">
                              <h5 style={{ background: "#f7f7f7" }}>
                                {admin.name}
                              </h5>
                              {admin?.names.map((u, i) =>
                                u.position !== "COMPANY ADMIN" ? (
                                  <div
                                    className="admin-contact"
                                    onClick={async () => {
                                      await $crud.confirm({
                                        title: "",
                                        textContent: u.adminStatus
                                          ? isGerm
                                            ? DE.dep_remove
                                            : EN.dep_remove
                                          : isGerm
                                            ? DE.add_dep
                                            : EN.add_dep,
                                        options: {
                                          ok: isGerm ? DE.confirm : EN.confirm,
                                          cancel: isGerm
                                            ? DE.cancel
                                            : EN.cancel,
                                        },
                                      });
                                      assignAdminRightsDepartment(
                                        u.id,
                                        i,
                                        u.adminStatus
                                      );
                                    }}
                                  >
                                    <div className="row m-0 ">
                                      <div className="col-md-10 p-0">
                                        <div className="admin-control">
                                          {errorImages.includes(index) ? (
                                            <img
                                              width={40}
                                              height={40}
                                              style={{
                                                borderRadius: 100,
                                                overflow: "hidden",
                                              }}
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/img/head.svg"
                                              }
                                              alt="Default Image"
                                            />
                                          ) : (
                                            <img
                                              src={
                                                u.avatar
                                                  ? BASE_URL + u.avatar
                                                  : process.env.PUBLIC_URL +
                                                  "/assets/img/head.svg"
                                              }
                                              onError={() =>
                                                handleImageError(index)
                                              }
                                              alt={`Image ${index}`}
                                              width={40}
                                              height={40}
                                              style={{
                                                borderRadius: 100,
                                                overflow: "hidden",
                                              }}
                                            />
                                          )}
                                          <h4>
                                            {u.fullName}
                                            <span>
                                              {convertPosition(
                                                isGerm,
                                                u.position
                                              )}
                                            </span>
                                            {u.department_name &&
                                              u.location_name &&
                                              u.location_name != "null" ? (
                                              <span>
                                                {u.department_name +
                                                  "/" +
                                                  u.location_name}
                                              </span>
                                            ) : (
                                              <span>{u.department_name}</span>
                                            )}
                                          </h4>
                                        </div>
                                      </div>
                                      <div className="col-md-2 pr-0">
                                        <div className="custom_checkbox">
                                          <label
                                            className={
                                              company?.isKjuup
                                                ? "role-label-internal"
                                                : "role-label"
                                            }
                                          >
                                            <input
                                              // checked={u.addStatus}
                                              checked={u.adminStatus}
                                              type="checkbox"
                                              value={u.id}
                                              onClick={(e) => {
                                                // addUser(u);
                                              }}
                                            />
                                            <span
                                              className={
                                                company?.isKjuup
                                                  ? "checkmark-label-internal"
                                                  : "checkmark-label"
                                              }
                                            />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )
                              )}
                            </div>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )}
              {company.position === "COMPANY ADMIN" && totalAdmins > 0 && (
                <Card>
                  <div
                    style={{
                      height: "40px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                    onClick={async (e) => {
                      await $crud.confirm({
                        title: "",
                        textContent: isGerm
                          ? DE.remove_my_self
                          : EN.remove_my_self,
                        options: {
                          ok: isGerm ? DE.confirm : EN.confirm,
                          cancel: isGerm ? DE.cancel : EN.cancel,
                        },
                      });
                      _removeMyself();
                    }}
                    as={Card.Header}
                  >
                    <div style={{ flex: 1, fontWeight: "550" }}>
                      {isGerm
                        ? DE.remove_my_admin_right
                        : EN.remove_my_admin_right}
                    </div>
                    <BsX size={"20px"} />
                  </div>
                </Card>
              )}
              {company.role === "ROLE_COMPANY_SUPER_ADMIN" && (
                <>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      variant="link"
                      eventKey="3"
                      onClick={(e) => handleClass("3")}
                    >
                      {" "}
                      {isGerm ? DE.remove_employees : EN.remove_employees}
                      {display === "3" ? (
                        <BsChevronUp className="acr_up" />
                      ) : (
                        <BsChevronDown className="acr_down" />
                      )}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <div className="admin_right">
                          <div className="search_div">
                            <div className="search">
                              <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Control
                                  autocomplete="off"
                                  value={search}
                                  type="search"
                                  placeholder={isGerm ? DE.search : EN.search}
                                  onChange={(e) => filterUsers(e.target.value)}
                                />
                              </Form.Group>
                              <BsSearch />
                            </div>
                          </div>
                          {users?.map((user, index) => (
                            <div className="admin-content">
                              <h5 style={{ background: "#f7f7f7" }}>
                                {user.name}
                              </h5>
                              {user.names &&
                                _.sortBy(
                                  user.names,
                                  [(us) => us?.lastName?.toLowerCase()],
                                  ["lastName"]
                                ).map((u, i) => (
                                  <div className="admin-contact">
                                    <div
                                      className=""
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div className=" p-0" style={{ flex: 1 }}>
                                        <div className="admin-control">
                                          {errorImages.includes(index) ? (
                                            <img
                                              width={40}
                                              height={40}
                                              style={{
                                                borderRadius: 100,
                                                overflow: "hidden",
                                              }}
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/img/head.svg"
                                              }
                                              alt="Default Image"
                                            />
                                          ) : (
                                            <img
                                              src={
                                                u.avatar
                                                  ? BASE_URL + u.avatar
                                                  : process.env.PUBLIC_URL +
                                                  "/assets/img/head.svg"
                                              }
                                              onError={() =>
                                                handleImageError(index)
                                              }
                                              alt={`Image ${index}`}
                                              width={40}
                                              height={40}
                                              style={{
                                                borderRadius: 100,
                                                overflow: "hidden",
                                              }}
                                            />
                                          )}
                                          <h4>
                                            {u.fullName}
                                            <span>
                                              {convertPosition(
                                                isGerm,
                                                u.position
                                              )}
                                            </span>
                                            {u.department_name &&
                                              u.location_name &&
                                              u.location_name != "null" ? (
                                              <span>
                                                {u.department_name +
                                                  "/" +
                                                  u.location_name}
                                              </span>
                                            ) : (
                                              <span>{u.department_name}</span>
                                            )}
                                          </h4>
                                        </div>
                                      </div>
                                      <div className=" pr-0">
                                        <div className="custom_checkbox mt-2">
                                          <img
                                            width={18}
                                            className="pointer"
                                            onClick={(e) =>
                                              removeUser(
                                                u.id,
                                                u.fullName,
                                                i,
                                                index
                                              )
                                            }
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/img/trashcan.svg"
                                            }
                                            alt="Generic placeholder"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          ))}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      {isGerm
                        ? DE.translation_on_building_projects
                        : EN.translation_on_building_projects}
                      <div
                        className={
                          isKjuup
                            ? "project-checkbox-internal"
                            : "project-checkbox"
                        }
                      >
                        <Form>
                          <Form.Check
                            type="switch"
                            id="custom-switch-1"
                            onClick={(e) =>
                              toggleTranslate(setting.translation)
                            }
                            checked={setting?.translation}
                          />
                        </Form>
                      </div>
                    </Card.Header>
                  </Card>
                </>
              )}
              {company?.position === "COMPANY ADMIN" && (
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    variant="link"
                    eventKey="4"
                    onClick={(e) => handleClass("4")}
                  >
                    {isGerm ? DE.company_language : EN.company_language}

                    {display === "4" ? (
                      <BsChevronUp className="acr_up" />
                    ) : (
                      <BsChevronDown className="acr_down" />
                    )}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <Form.Group>
                        <Form.Control
                          value={setting?.language}
                          onChange={(e) => {
                            handleChangeLanguage(e);
                          }}
                          as="select"
                        >
                          <option value="en">
                            {isGerm ? DE.english : EN.english}
                          </option>
                          <option value="de">
                            {isGerm ? DE.german : EN.german}
                          </option>
                        </Form.Control>
                      </Form.Group>
                      {isGerm
                        ? DE.company_language_info
                        : EN.company_language_info}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )}
            </Accordion>
          </div>
        ) : (
          <div
            className="setting_accrd"
            style={{ overflow: "scroll", height: "82vh" }}
          >
            <Accordion defaultActiveKey="0">
              {/* <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="0"
                  onClick={(e) => handleClass("0")}
                >
                  {isGerm ? DE.dashboard : EN.dashboard}
                  {display === "0" ? (
                    <BsChevronUp className="acr_up" />
                  ) : (
                    <BsChevronDown className="acr_down" />
                  )}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className="dashboard_short_info">
                      <div>
                        {dashboard.profileViews}{" "}
                        <span>
                          {isGerm ? DE.profile : EN.profile}-
                          <br /> {isGerm ? DE.views : EN.views}
                        </span>
                      </div>
                      <div>
                        {dashboard.articleViews}{" "}
                        <span>
                          {isGerm ? DE.article : EN.article}-
                          <br /> {isGerm ? DE.views : EN.views}
                        </span>
                      </div>
                      <div>
                        {dashboard.searchSeen}
                        <span>
                          {isGerm ? DE.times_seen : EN.times_seen}
                          <br /> {isGerm ? DE.in_search : EN.in_search}
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card> */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="1"
                  onClick={(e) => handleClass("1")}
                >
                  {isGerm ? DE.add_remove_dep_admin : EN.add_remove_dep_admin}
                  {display === "1" ? (
                    <BsChevronUp className="acr_up" />
                  ) : (
                    <BsChevronDown className="acr_down" />
                  )}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className="admin_right">
                      <div className="search_div">
                        <div className="search">
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Control
                              autocomplete="off"
                              value={search}
                              type="search"
                              placeholder={isGerm ? DE.search : EN.search}
                              onChange={(e) => filterAdminsDeps(e.target.value)}
                            />
                          </Form.Group>
                          <BsSearch />
                        </div>
                      </div>
                      {departmentPersons &&
                        _.sortBy(
                          departmentPersons,
                          [(us) => us?.lastName?.toLowerCase()],
                          ["lastName"]
                        ).map((admin, index) => (
                          <div className="admin-content">
                            <h5 style={{ background: "#f7f7f7" }}>
                              {admin.name}
                            </h5>
                            {admin?.names?.map((u, i) => (
                              <div
                                className="admin-contact"
                                onClick={async () => {
                                  await $crud.confirm({
                                    title: "",
                                    textContent: u.adminStatus
                                      ? isGerm
                                        ? DE.dep_remove
                                        : EN.dep_remove
                                      : isGerm
                                        ? DE.add_dep
                                        : EN.add_dep,
                                    options: {
                                      ok: isGerm ? DE.confirm : EN.confirm,
                                      cancel: isGerm ? DE.cancel : EN.cancel,
                                    },
                                  });
                                  assignAdminRightsDepartment(
                                    u.id,
                                    i,
                                    u.adminStatus
                                  );
                                }}
                              >
                                <div className="row m-0 ">
                                  <div className="col-md-10 p-0">
                                    <div className="admin-control">
                                      {errorImages.includes(index) ? (
                                        <img
                                          width={40}
                                          height={40}
                                          style={{
                                            borderRadius: 100,
                                            overflow: "hidden",
                                          }}
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/img/head.svg"
                                          }
                                          alt="Default Image"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            u.avatar
                                              ? BASE_URL + u.avatar
                                              : process.env.PUBLIC_URL +
                                              "/assets/img/head.svg"
                                          }
                                          onError={() =>
                                            handleImageError(index)
                                          }
                                          alt={`Image ${index}`}
                                          width={40}
                                          height={40}
                                          style={{
                                            borderRadius: 100,
                                            overflow: "hidden",
                                          }}
                                        />
                                      )}
                                      <h4>
                                        {u.fullName}
                                        <span>
                                          {convertPosition(isGerm, u.position)}
                                        </span>
                                        {u.department_name &&
                                          u.location_name &&
                                          u.location_name != "null" ? (
                                          <span>
                                            {u.department_name +
                                              "/" +
                                              u.location_name}
                                          </span>
                                        ) : (
                                          <span>{u.department_name}</span>
                                        )}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="col-md-2 pr-0">
                                    <div className="custom_checkbox">
                                      <label
                                        className={
                                          company?.isKjuup
                                            ? "role-label-internal"
                                            : "role-label"
                                        }
                                      >
                                        <input
                                          // checked={u.addStatus}
                                          checked={u.adminStatus}
                                          type="checkbox"
                                          value={u.id}
                                          onClick={(e) => {
                                            // addUser(u);
                                          }}
                                        />
                                        <span
                                          className={
                                            company?.isKjuup
                                              ? "checkmark-label-internal"
                                              : "checkmark-label"
                                          }
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <div
                  style={{
                    height: "40px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                  onClick={async (e) => {
                    await $crud.confirm({
                      title: "",
                      textContent: isGerm
                        ? DE.my_self_dep_remove
                        : EN.my_self_dep_remove,
                      options: {
                        ok: isGerm ? DE.confirm : EN.confirm,
                        cancel: isGerm ? DE.cancel : EN.cancel,
                      },
                    });
                    _removeMyselfDepartment();
                  }}
                  as={Card.Header}
                >
                  <div style={{ flex: 1, fontWeight: "550" }}>
                    {isGerm
                      ? DE.remove_my_admin_right
                      : EN.remove_my_admin_right}
                  </div>
                  <BsX size={"20px"} />
                </div>
              </Card>
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="3"
                  onClick={(e) => handleClass("3")}
                >
                  {" "}
                  {isGerm ? DE.remove_employees_dept : EN.remove_employees_dept}
                  {` ${company.department}/${company.location}`}
                  {display === "3" ? (
                    <BsChevronUp className="acr_up" />
                  ) : (
                    <BsChevronDown className="acr_down" />
                  )}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <div className="admin_right">
                      <div className="search_div">
                        <div className="search">
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Control
                              autocomplete="off"
                              value={search}
                              type="search"
                              placeholder={isGerm ? DE.search : EN.search}
                              onChange={(e) => filterUsers(e.target.value)}
                            />
                          </Form.Group>
                          <BsSearch />
                        </div>
                      </div>
                      {users?.map((user, index) => (
                        <div className="admin-content">
                          <h5 style={{ background: "#f7f7f7" }}>{user.name}</h5>
                          {user.names &&
                            _.sortBy(
                              user.names,
                              [(us) => us?.lastName?.toLowerCase()],
                              ["lastName"]
                            ).map((u, i) => (
                              <div className="admin-contact">
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div className=" p-0" style={{ flex: 1 }}>
                                    <div className="admin-control">
                                      {errorImages.includes(index) ? (
                                        <img
                                          width={40}
                                          height={40}
                                          style={{
                                            borderRadius: 100,
                                            overflow: "hidden",
                                          }}
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/img/head.svg"
                                          }
                                          alt="Default Image"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            u.avatar
                                              ? BASE_URL + u.avatar
                                              : process.env.PUBLIC_URL +
                                              "/assets/img/head.svg"
                                          }
                                          onError={() =>
                                            handleImageError(index)
                                          }
                                          alt={`Image ${index}`}
                                          width={40}
                                          height={40}
                                          style={{
                                            borderRadius: 100,
                                            overflow: "hidden",
                                          }}
                                        />
                                      )}
                                      <h4>
                                        {u.fullName}
                                        <span>
                                          {convertPosition(isGerm, u.position)}
                                        </span>
                                        {u.department_name &&
                                          u.location_name &&
                                          u.location_name != "null" ? (
                                          <span>
                                            {u.department_name +
                                              "/" +
                                              u.location_name}
                                          </span>
                                        ) : (
                                          <span>{u.department_name}</span>
                                        )}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className=" pr-0">
                                    <div className="custom_checkbox mt-2">
                                      <img
                                        width={18}
                                        className="pointer"
                                        onClick={(e) =>
                                          removeUser(u.id, u.fullName, i, index)
                                        }
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/img/trashcan.svg"
                                        }
                                        alt="Generic placeholder"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      ))}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default CompanySetting;
