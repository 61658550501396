import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import LanguageContext from "../../helper/LanguageContext";
import { callAPI } from "../../helper/apiUtils";
import { Scrollbars } from "react-custom-scrollbars";
import { BASE_URL, goBack } from "../../helper/constants";
import { Animated } from "react-animated-css";
import { convertBranchRole, convertPosition } from "../../helper/convertor";

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

function MyCompany(props) {
  const { isGerm } = React.useContext(LanguageContext);
  const [followStatus, setFollowStatus] = useState(null);
  const [visible, setVisible] = useState(true);
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const getCompanies = async () => {
    const { data } = await callAPI(`/company/my`, {}, "GET");
    if (data.status) {
      setCompanies(data.companies);
    }
  };
  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanyDetails = (id) => {
    history.push({
      pathname: "/company-details/" + id,
      state: { id: id },
    });
  };
  const followCompany = async (id) => {
    setFollowStatus(id);
    const { data } = await callAPI(
      `/company/${id}/follow`,
      {
        id: id,
      },
      "GET"
    );
    console.log(data);
    setFollowStatus(null);
    getCompanies();
  };

  const HandleHide = () => {
    setVisible(false);
    setTimeout(() => {
      goBack();
    }, 1000);
  };

  return (
    <React.Fragment>
      <Layout>
        <Animated
          animationIn="slideInLeft"
          animationOutDuration={800}
          isVisible={true}
        >
          <div className="company_section w-300 company_list">
            <div className="company_header company_border">
              <div className="heading_icon m-0">
                <a onClick={HandleHide} className="main_heading pointer">
                  <img
                    width={10}
                    src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                  />
                  {isGerm ? DE.my_companies : EN.my_companies}
                </a>
                <div className="icons_heading">
                  <Link to="/add-company">
                    <img
                      width={18}
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/Add-Company-PP.svg"
                      }
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="company_list_ver">
              <div className="my-scroll min_height m-0">
                <CustomScrollbars
                  autoHide
                  autoHideTimeout={500}
                  autoHideDuration={200}
                  className="bottom-bar"
                >
                  {companies &&
                    companies.map((company) => {
                      let add = `${company.houseNo ? company.houseNo + "," : ""} ${company.street}, ${company.city}, ${company.postCode ? company.postCode + "," : ""} ${company.country ? company.country : ''}`
                      return (
                        <div className="bg-purple" key={company.id}>
                          <div className="row">
                            <div className="col-md-5 pr-0">
                              <div className="user_profile">
                                <div className="user_img b-4">
                                  <a href="#">
                                    <img
                                      width={55}
                                      height={55}
                                      src={
                                        company.logo
                                          ? BASE_URL + company.logo
                                          : process.env.PUBLIC_URL +
                                          "assets/img/Companies-No-Image.svg"
                                      }
                                      alt="Generic placeholder"
                                      style={{ objectFit: "cover", borderRadius: "5px", }}
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5 pl-0">
                              <div className="follow">
                                <div className="follow_item text-center">
                                  <h3>{company.followerCount}</h3>
                                  <p>{isGerm ? DE.followers : EN.followers}</p>
                                </div>
                                <div className="follow_item text-center">
                                  <h3>{company.articleCount}</h3>
                                  <p>{isGerm ? DE.article : EN.article}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 text-right">
                              <a
                                onClick={(e) => {
                                  followCompany(company.id);
                                }}
                                className={`svg_icon pointer ${company.followStatus ? "social-icon-active" : ""
                                  } ${followStatus === company.id
                                    ? "bouncing-animation"
                                    : ""
                                  }`}
                              >
                                <svg
                                  className="mr-1"
                                  width="22px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 19.81 20.9"
                                >
                                  <path
                                    d="M295.14,269.16a1.28,1.28,0,0,0-1.32-1.23h-.91a5.11,5.11,0,0,1-8.76,0h-.91a1.27,1.27,0,0,0-1.31,1.23l-1.31,9a1.28,1.28,0,0,0,1.31,1.24h13.21a1.27,1.27,0,0,0,1.3-1.24Z"
                                    transform="translate(-280.17 -258.91)"
                                  />
                                  <path
                                    className="cls-1"
                                    d="M288.53,268.2a4.42,4.42,0,1,0-4.41-4.42A4.43,4.43,0,0,0,288.53,268.2Z"
                                    transform="translate(-280.17 -258.91)"
                                  />
                                  <line
                                    className="cls-2"
                                    x1="16.72"
                                    y1="0.75"
                                    x2="16.72"
                                    y2="3.47"
                                  />
                                  <line
                                    className="cls-2"
                                    x1="16.72"
                                    y1="3.47"
                                    x2="16.72"
                                    y2="6.18"
                                  />
                                  <line
                                    className="cls-2"
                                    x1="16.72"
                                    y1="3.47"
                                    x2="14.08"
                                    y2="3.47"
                                  />
                                  <line
                                    className="cls-2"
                                    x1="19.36"
                                    y1="3.47"
                                    x2="16.72"
                                    y2="3.47"
                                  />
                                </svg>
                              </a>
                            </div>
                          </div>
                          <div className="profile-flied">
                            <div className="profile_name">
                              <h3
                                className="pointer"
                                onClick={(e) => getCompanyDetails(company.id)}
                              >
                                {company.name}
                              </h3>
                              <p>
                                {company.full_address ? company.full_address : add}
                                {/* {company.street}, {company.postCode},{" "}
                              {company.city} */}
                              </p>
                              {/* {company.owner ? (
                              <p>
                                {isGerm
                                  ? DE.owner_company_admin_new
                                  : EN.owner_company_admin_new}
                                <span className="theme-color">
                                  {" "}
                                  {company.owner}
                                </span>
                              </p>
                            ) : (
                              ""
                            )} */}
                              <p>{convertBranchRole(company.branch, isGerm)}</p>
                              <p>
                                {isGerm ? DE.my_position : EN.my_position}{" "}
                                {convertPosition(isGerm, company.position)}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  {!companies && (
                    <div className="bg-purple">
                      <p className="text-center">Record not found!</p>
                    </div>
                  )}
                </CustomScrollbars>
              </div>
            </div>
          </div>
        </Animated>
      </Layout>
    </React.Fragment>
  );
}

export default MyCompany;
