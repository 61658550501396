import { $user } from "./UserFactory";
import { CrudRequest, RequestOptions } from "@crud/core";
import { chooseFile } from "@crud/web";


export class CrudFactory extends CrudRequest {
  baseUrl = process.env.REACT_APP_API_URL;
  // baseUrl = "https://immosnapp.teamjft.com";
  // baseUrl = "http://fb3d69fe9cef.ngrok.io";

  getUrl = (...segments) =>
    segments.reduce((url, segment) => url + segment, this.baseUrl);

  constructor() {
    super();
    this.config(chooseFile);
  }

  async get<Request = any, Response = any>(
    url: string,
    data: any = {},
    requestOptions: RequestInit = {}
  ): Promise<ResponseType<Response>> {
    return this.send({
      method: "GET",
      url,
      data,
      ...requestOptions,
    });
  }

  async post<Request = any, Response = any>(
    url: string,
    data: any = {},
    requestOptions: RequestOptions = {}
  ): Promise<ResponseType<Response>> {
    return this.send({
      method: "POST",
      url,
      data,
      ...requestOptions,
    });
  }

  async put<Request = any, Response = any>(
    url: string,
    data: any = {},
    requestOptions: RequestOptions = {}
  ): Promise<ResponseType<Response>> {
    return this.send({
      method: "PUT",
      url,
      data,
      ...requestOptions,
    });
  }

  async delete<Request = any, Response = any>(
    url: string,
    data: any = {},
    requestOptions: RequestOptions = {}
  ): Promise<ResponseType<Response>> {
    return this.send({
      method: "DELETE",
      url,
      data,
      ...requestOptions,
    });
  }

  async send(requestOptions: RequestOptions = {}): Promise<ResponseType<any>> {
    const { url, data, method, notify = true } = requestOptions;

    const options: RequestInit = {
      ...requestOptions.ajaxOptions,
      method,
    };

    let fullUrl;

    options.headers = {
      ...options.headers,
      Accept: "application/json",
      Authorization: `jwt ${$user.getToken()}`,
    };
    fullUrl = this.getUrl(url);

    if (options.method === "GET") {
      let queryString = new URLSearchParams(data);
      fullUrl += `?${queryString}`;
    } else if (!(data instanceof FormData)) {
      options.body = JSON.stringify(data);
      options.headers["Content-Type"] = "application/json";
    } else {
      options.body = data;
    }

    let res = {
      // data: [],
      message: "",
      status: null,
    };

    try {
      const response = await fetch(fullUrl, options);
      if (response.status === 200) {
        res = await response.json();
        const { status, message } = res;
        if (options.method !== "GET" && notify) {
          this.notify({
            message,
            type: status ? "success" : "error",
        action:null

          });
        }
      } else {
        if (response.status === 400 || response.status === 401) {
          res = await response.json();
          const { status, message } = res;
          return { status, message }
        }
        else {
           throw new Error(`${response.status} : ${response.statusText}`);
        }
      }
    } catch (e) {
      this.notify({
        message: e.message,
        type: "error",
        action:null
      });
      throw e;
    }

    const { status } = res;
    if (!status) throw res;

    return res;
  }
}

export const $crud = new CrudFactory();
