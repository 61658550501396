import React, { useEffect, useState, Fragment } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { BsArrowBarDown, BsChevronDown, BsChevronUp } from "react-icons/bs";
import Layout from "../../components/Layout";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import { Link, useHistory } from "react-router-dom";
import LocationSearchInput from "../../components/LocationSearchInput";
import { callAPI } from "../../helper/apiUtils";
import { $crud } from "../../helper/CrudFactory";
import BuildingTypesDialog from "./Dialogs/BuildingTypesDialog";
import CompanyListDialog from "./Dialogs/CompanyListDialog";
import ProjectMapComponent from "./ProjectMap/ProjectMapComponent";
import _ from "lodash";
import AutoComplete from "./Partial/AutoComplete";
import { Animated } from "react-animated-css";
import { store, UPDATE_PROJECT_ACTION_FIRE } from "../../store";
import Geocode from "react-geocode";
import { withRouter } from "react-router-dom";
import { KJUUP_COLORS, TOGGLE_DRAWER } from "../../helper/constants";
import { connect, useDispatch, useSelector } from "react-redux";
import { BiArrowToBottom } from "react-icons/bi";
export const GOOGLE_API_KEY = 'AIzaSyCc6D4Md2hNlwB3seL_fKKr8ugbZbOzem8';

Geocode.setApiKey("AIzaSyBY4CL97jLZQR77C7mvJCWM5Wq2HkYk69Q");
function AddProject(props) {
  const isInternal = props.history.location.state?.from?true:false
  let companyId = props.location.state?.companyId
  let companyName = props.location.state?.companyId
  const [params, setParams] = useState({
    companyId: "",
    name: "",
    projectNo: "",
    buildingType: [],
    areaOfActivity: "",
    street: "",
    postCode: "",
    city: "",
    country: "",
    lat: "",
    long: "",
    companyName: "",
    houseNo: "",
    isHidden: true,
    full_address: ""
  });
  const [selectedAddress, setSelectedAddress] = useState(false)
  const [loading, setLoading] = useState(false);
  const [displayTypes, setDisplayTypes] = useState('');
  const [errors, setErrors] = useState({
    companyId: "",
    name: "",
    projectNo: "",
    buildingType: "",
    areaOfActivity: "",
    street: "",
    // postCode: "",
    city: "",
    country: "",
    lat: "",
    long: "",
    houseNo: "",
  });
  const [cameraModal, setCameraModal] = useState(false);
  const [street, setStreet] = useState('');
  const [house, setHouse] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [pNo, setPNo] = useState('');
  const [email, setEmail] = useState('');
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [full_address, setFullAddress] = useState('');
  const [countryName, setCountryName] = useState('');
  const [pid, setPid] = useState('');
  const [showDropdpownCity, setShowDropdpownCity] = useState(false)
  const [citySelected, setCitySelected] = useState([]);
  const [citySelectedAPIParam, setCitySelectedAPIParam] = useState([]);
  const [searchCity, setSearchCity] = useState('');
  const [showOptionalInfo, setShowOptionalInfo] = useState(true)
  const [paramsLoaded, setParamsLoaded] = useState({
    name: false,
    buildingType: false,
    areaOfActivity: false,
    projectNo: false,
  });
  const { isGerm } = React.useContext(LanguageContext);
  const [suggestionLoaded, setSuggestionLoaded] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [show, setShow] = useState(false);
  const [showCompanyDialog, setCompanyDialog] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showOptionalInformation, setShowOptionalnformation] = useState(false)
  const [disableAll, setDisableAll] = useState(false)
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const dispatch = useDispatch()
  const getList = async () => {
    try {
      const { data } = await callAPI(`/building/all?projectType=${isInternal ? "1" : "0"}`, "", "GET");
      console.log(data);
      if (data.status) {
        // setSuggestions(!isInternal? data.buildings:props.location.state?.projects);
        setSuggestions(!isInternal ? data.buildings : []);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const setParam = (name, value) => {
    console.log(name, value)
    setParams((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setErrors((prev) => {
      return {
        ...prev,
        [name]: "",
      };
    });
  };
  const setName = (name, value) => {
    if (value) {
      setParams((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      setErrors((prev) => {
        return {
          ...prev,
          [name]: "",
        };
      });
    }
  };
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    //Role
    if (companies.length > 1) {
      if (!params.companyId) {
        formIsValid = false;
        errors["companyId"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
      }
    }
    //Firstname
    if (!params.name) {
      formIsValid = false;
      errors["name"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
    }

    // if (!params.areaOfActivity) {
    //   formIsValid = false;
    //   errors["areaOfActivity"] = isGerm? DE.invalid_fiels : EN.invalid_fiels;
    // }

    setErrors(errors);
    return formIsValid;
  };
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const getCompanies = async () => {
    const { data } = await callAPI(`/company/my`, {}, "GET");
    if (data.status) {
      if (data.companies.length === 1) {
        setParam("companyId", data.companies[0].id);
      } else {
        setCompanies(data.companies);
      }
    }
  };
  useEffect(() => {
    setParam('buildingType', '')
    setDisplayTypes('')
    getCompanies();
    getBuildings();
    getList();
  }, [props.location]);
  const submitProject = async () => {
    setLoading(true);
    if (isInternal) {
      let errors = {}
      if (!params.name) {
        errors["name"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
        setErrors(errors)
      }
      else {
        setErrors(errors)
        try {
          let params1= props.history.location.state
          let projectData = {
            "name": params.name,
            "projectNo": params.projectNo,
            "companyName": params1.companyName
          };
          if (params1.companyId) {
            projectData['companyId'] = params1.companyId
          }
          if (pid) {
            projectData['id'] = pid
          }
          console.log(projectData)
          const { data } = await callAPI("/building/createInternalBuilding", "", "POST", projectData);
          if (!data.status) {
            await $crud.notify({
              type: "error",
              message: data.message,
            });
          } else {
            store.dispatch({
              type: UPDATE_PROJECT_ACTION_FIRE,
              updateProjectActionFire: true,
            });
            dispatch({ type: TOGGLE_DRAWER, open: false });
          }
        } catch (e) {
          console.log(e);
        }
        setLoading(false);
      }
    }
    else if (handleValidation()) {
      if (pid) {
        params['id'] = pid
      }
      if (params.companyId) {
        params.companyId = params.companyId
      }
      else
        delete params.companyId
      try {
        const { data } = await callAPI(`/building`, "", "POST", params);
        if (!data.status) {
          await $crud.notify({
            type: "error",
            message: data.message,
          });
        } else {
          store.dispatch({
            type: UPDATE_PROJECT_ACTION_FIRE,
            updateProjectActionFire: true,
          });
          dispatch({ type: TOGGLE_DRAWER, open: false });
        }
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    } else {
      console.log("errors", errors);
    }
  };

  useEffect(() => {
    if (!selectedAddress) {
      let fullAddress = `${params.street} ${params.city ? `, ${params.city}` : ""}`
      console.log("fullAddress", fullAddress)
      Geocode.fromAddress(fullAddress).then(
        (results) => {
          const { lat, lng } = results.results[0].geometry.location;
          let country;
          for (var i = 0; i < results.results[0].address_components.length; i++) {
            for (
              var j = 0;
              j < results.results[0].address_components[i].types.length;
              j++
            ) {
              if (
                results.results[0].address_components[i].types[j] == "country"
              ) {
                country = results.results[0].address_components[i]?.long_name;
              }
            }
          }
          console.log("country", country)
          setParam('lat', lat)
          setParam('long', lng)
          setParam('country', country)
        },
        (error) => {
          console.error(error);
        }
      );
    }
    console.log("params", params.city, "street", params.street, "selectedAddress", selectedAddress)
  }, [params.city, params.street])

  const getSuggestion = async (suggestion) => {
    setDisableAll(true)
    let add = `${suggestion.houseNo ? suggestion.houseNo + ", " : ""}${suggestion.street ? suggestion.street + ", " : ''}${suggestion.city ? suggestion.city + ", " : ""}${suggestion.postCode ? suggestion.postCode + ", " : ""}${suggestion.country ? suggestion.country : ''}`
    console.log('---ad', add)
    setSearchCity(suggestion.full_address ? suggestion.full_address : add)
    setFullAddress(suggestion.full_address ? suggestion.full_address : add)
    setPid(suggestion.id)
    await setParams((prev) => {
      return {
        ...prev,
        name: suggestion.name ? suggestion.name : "",
        buildingType: true,
        areaOfActivity: suggestion.buildingType
          ? _.isArray(suggestion.buildingType)
            ? suggestion.buildingType.join(", ")
            : suggestion.buildingType
          : "",
        street: suggestion.street,
        postCode: suggestion.postCode,
        city: suggestion.city,
        country: suggestion.country ? suggestion.country : "germany",
        lat: suggestion.lat,
        long: suggestion.long,
        houseNo: suggestion.houseNo,
        isHidden: false
      };
    });
    let tp = suggestion.buildingType
      ? (_.isArray(suggestion.buildingType) && suggestion.buildingType.length > 0)
        ? suggestion.buildingType.join(", ")
        : (_.isArray(suggestion.buildingType) && suggestion.buildingType.length == 0) ? (isGerm ? DE.building_types : EN.building_types) : suggestion.buildingType
      : (isGerm ? DE.building_types : EN.building_types)
    setDisplayTypes(tp)
    setParamsLoaded((prev) => {
      return {
        ...prev,
        name: !!suggestion.name,
        buildingType: true,
      };
    });
    setSuggestionLoaded(true);
  };

  const getBuildings = async () => {
    try {
      const { data } = await callAPI("/building/all", "", "GET");
      if (data.status) {
        setBuildings(data.buildings);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleHideProject = async (e) => {
    if (!disableAll) {
      if (!params?.isHidden) {
        let langText = isGerm ? DE.hide_project_popup_text : EN.hide_project_popup_text;
        await $crud.confirm({
          title: "",
          textContent: langText,
          options: {
            ok: "Confirm",
            cancel: "Cancel"
          }
        });
      }
      else {
        let langText = isGerm ? DE.unhidden_message : EN.unhidden_message;
        await $crud.confirm({
          title: "",
          textContent: langText,
          options: {
            ok: "Confirm",
            cancel: "Cancel"
          }
        });
      }
      setParams({ ...params, isHidden: !params.isHidden })
    }
  }

  return (
    <React.Fragment>
      <Layout>
        <Animated
          animationIn="slideInLeft"
          isVisible={true}
        >
          <div className="w-300 add_company" style={{ height: "93vh" }}>
            <div className="heading bg_white">
              <h2>
                {isGerm ? DE.add_or_join_project : EN.add_or_join_project}
              </h2>
              <a onClick={() => {
                // console.log(props)
                props.handleDrawer(false)
              }}>
                {!isInternal ?
                  <img
                    width={10}
                    src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                  />
                  :
                  <img
                    width={10}
                    className="custom-svg"
                    src={process.env.PUBLIC_URL + "/assets/img/backx.svg"}
                  />
                }
              </a>
            </div>
            <div className="form-bottom">
              <Form
                className="add_company_from"
                onSubmit={(e) => {
                  e.preventDefault();
                  submitProject();
                }}
              >
                <Form.Group controlId="formBasicEmail">
                  <AutoComplete
                    suggestions={suggestions}
                    setParam={setName}
                    value={params.name}
                    setSuggestion={getSuggestion}
                    locked={paramsLoaded.name}
                  />
                  {errors.name && (
                    <small className="pl-2 text-danger">{errors.name}</small>
                  )}
                </Form.Group>
                {isInternal ?
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      value={params.projectNo}
                      onChange={(e) => setParam("projectNo", e.target.value)}
                      type="text"
                      placeholder={isGerm ? DE.project_No : EN.project_No}
                    />
                    {errors.projectNo && (
                      <small className="pl-2 text-danger">
                        {errors.projectNo}
                      </small>
                    )}
                  </Form.Group>
                  :
                  <></>
                }
                {!isInternal &&
                  <>
                    {companies.length > 1 && (
                      <Form.Group className="select_coustom border-0">
                        {!suggestionLoaded && !params.companyId && (
                          <a
                            className="register_role pointer text-truncate position-relative"
                            onClick={(e) => {
                              e.preventDefault();
                              setCompanyDialog(true);
                            }}
                          >
                            {!params.companyId && (
                              <span>{isGerm ? DE.company : EN.company}* </span>
                            )}
                            {params.companyId && <span>{params.companyName}</span>}
                            <BsChevronDown />
                          </a>
                        )}
                        {(suggestionLoaded || params.companyId) && (
                          <a
                            className="register_role pointer text-truncate position-relative"
                            onClick={(e) => {
                              e.preventDefault();
                              setCompanyDialog(true);
                            }}
                          >
                            {!params.companyId && (
                              <span>{isGerm ? DE.company : EN.company}* </span>
                            )}
                            {params.companyId && <span>{params.companyName}</span>}
                            <BsChevronDown />
                          </a>
                        )}
                        {errors.companyId && (
                          <small className="pl-2 text-danger">
                            {errors.companyId}
                          </small>
                        )}
                      </Form.Group>
                    )}
                    <Card.Header style={{ padding: "0.40rem 0.80rem", borderBottom: "none" }}>
                      <div style={{ display: "flex", justifyContent: "space-between" }} className="project-checkbox">
                        <span style={{ color: "#737373", fontSize: "14px", fontStyle: "italic" }}>
                          {isGerm ? DE.hide_project : EN.hide_project}
                        </span>
                        <Form>
                          <Form.Check
                            checked={params?.isHidden}
                            onChange={handleHideProject}
                            type="switch"
                            id="custom-switchx"
                          />
                        </Form>
                      </div>
                    </Card.Header>
                  </>
                }
                {
                  !isInternal ?
                    <div onClick={() => {
                      setShowOptionalnformation(!showOptionalInformation)
                    }} style={{ marginTop: 20, marginRight: 9, marginLeft: 9, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      <div style={{ flex: 1 }}>
                        <h6>
                          {isGerm ? DE.optional_information : EN.optional_information}
                        </h6>
                      </div>
                      <div>
                        {!showOptionalInformation ?
                          <BsChevronDown
                            size={15}
                          />
                          :
                          <BsChevronUp
                            size={15}
                          />
                        }
                      </div>
                    </div>
                    :
                    <></>
                }

                {
                  !isInternal && showOptionalInformation ?
                    <>
                      <Form.Group controlId="formBasicPassword11" style={{ marginTop: 10 }}>
                        <LocationSearchInput
                          custom
                          defaultValue={full_address}
                          placeHolder={isGerm ? DE.Address : EN.Address}
                          setVal={setSearchCity}
                          val={searchCity}
                          setFull={(val) => {
                            setParam('full_address', val)
                            setFullAddress(val)
                          }}
                          disbled={disableAll}
                          isGerm={isGerm}
                        />
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Control
                          value={params.projectNo}
                          onChange={(e) => setParam("projectNo", e.target.value)}
                          type="text"
                          placeholder={isGerm ? DE.project_No : EN.project_No}
                        />
                        {errors.projectNo && (
                          <small className="pl-2 text-danger">
                            {errors.projectNo}
                          </small>
                        )}
                      </Form.Group>
                      <Form.Group className="select_coustom border-0">
                        {!paramsLoaded.buildingType && (
                          <a
                            className="register_role pointer text-truncate position-relative"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowDialog(true);
                            }}
                          >
                            {!params.buildingType && (
                              <span>
                                {isGerm ? DE.building_type.replace('*', '') : EN.building_type.replace('*', '')}{" "}
                              </span>
                            )}
                            {params.buildingType && (
                              <span>{displayTypes}</span>
                            )}
                            <BsChevronDown />
                          </a>
                        )}
                        {paramsLoaded.buildingType && params.buildingType && (
                          <a
                            className="register_role pointer text-truncate position-relative"
                            style={{ background: "#e9ecef" }}
                          >
                            {!params.buildingType && (
                              <span>
                                {isGerm ? DE.building_type.replace('*', '') : EN.building_type.replace('*', '')}{" "}
                              </span>
                            )}
                            {params.buildingType && (
                              <span>{displayTypes}</span>
                            )}
                            <BsChevronDown />
                          </a>
                        )}
                        {errors.buildingType && (
                          <small className="pl-2 text-danger">
                            {errors.buildingType}
                          </small>
                        )}
                      </Form.Group>
                    </>
                    :
                    <></>
                }

                <Button
                  className={isInternal ? "btn request-btnx" : "btn request-btn"}
                  type="submit"
                  style={{
                    width:"auto"
                  }}

                >
                  {isGerm ? DE.add_join : EN.add_join}
                </Button>
              </Form>
              <p className="mandatory">
                {isGerm ? DE.mandatory_fiels : EN.mandatory_fiels}*
              </p>
            </div>
          </div>
          {showDialog && (
            <BuildingTypesDialog
              ctom
              handleClose={(e) => setShowDialog(false)}
              setParam={setParam}
              setTypesToDisplay={setDisplayTypes}
            />
          )}

          {showCompanyDialog && (
            <CompanyListDialog
              handleClose={(e) => setCompanyDialog(false)}
              setParam={setParam}
              handleReJoinCompany={() => { }}
              companies={companies}
            />
          )}
        </Animated>
      </Layout>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const {
    projects,
    openProjects,
    updateProjectActionFire,
    openBottomProjects,
    projectRequestCount,
    isOpenByOwner,
  } = state;
  return {
    projects: projects,
    updateProjectActionFire: updateProjectActionFire,
    openProjects: openProjects,
    openBottomProjects: openBottomProjects,
    projectRequestCount: projectRequestCount,
    isOpenByOwner: isOpenByOwner,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleDrawer: (open) => dispatch({ type: TOGGLE_DRAWER, open })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddProject));
