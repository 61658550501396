import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Layout from "../../components/Layout";
import LanguageContext from "../../helper/LanguageContext";
import { useSelector } from "react-redux";
import { callAPI } from "../../helper/apiUtils";
import { BASE_URL, KJUUP_COLORS } from "../../helper/constants";
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';
import { $crud } from "../../helper/CrudFactory";
import { BranchPosition } from "../../Languages/vars/BranchPosition";
import { LoadingDots } from "../../LoadingDots/LoadingDots";
import { Link, useHistory } from 'react-router-dom';
import RegisterRole from "../RegisterRole";
import { BsChevronDown } from "react-icons/bs";
import { $user } from "../../helper/UserFactory";
import ImageCropper from "./ImageCropper";
import { Discuss, Rings, Oval } from 'react-loader-spinner'
import { convertBranchRole } from '../../helper/convertor';

function EditProfile() {

    const [profile, setProfile] = useState({
        fullName: "",
        firstName: '',
        lastName: '',
        avatar: '',
        title: '',
        buildingIndustryRole: ''
    })
    const [errors, setErrors] = useState({
        fullName: "",
        firstName: '',
        lastName: '',
        avatar: '',
        title: '',
        buildingIndustryRole: ''
    });
    const { isGerm } = React.useContext(LanguageContext);
    const user = useSelector(state => state.user)
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [showRoles, SetShowRoles] = useState(false)
    const [isUploadingFile, setIsUploadFile] = useState(false)
    const getProfile = async () => {
        const { data: { profile } } = await callAPI(`/user/${user?.id}/profile`, {}, 'GET');
        setProfile(prev => {
            return {
                ...prev,
                fullName: profile?.fullName,
                title: profile?.title,
                firstName: profile?.firstName,
                lastName: profile?.lastName,
                avatar: profile?.avatar,
                buildingIndustryRole: profile?.buildingIndustryRole
            }
        })
        setIsUploadFile(false)
    }

    const setParam = (name, value) => {
        setProfile(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
        setErrors(prev => {
            return {
                ...prev,
                [name]: ''
            }
        });
    }
    const changeImage = async (file) => {
        console.log(file)
        if (file) {
            setIsUploadFile(true)
            let formData = new FormData()
            formData.append('file', file);
            $crud.put('/user/avatar', formData).then(res => {
                let userDetails = user
                userDetails.avatar = res.avatar
                $user.updateUserData(userDetails)
                getProfile();
            }).catch(() => {
                setIsUploadFile(false)
            })
            setShow(false);
        } else {
            $crud.notify({
                type: 'error',
                message: "Please select a file to upload"
            })
        }

    }

    useEffect(() => {
        getProfile();
    }, [user])
    const position = BranchPosition;
    const updateProfile = async () => {
        setLoading(true)
        const { data } = await callAPI('/user/profile', {}, 'PUT', profile);
        if (!data.status) {
            await $crud.notify({
                type: 'error',
                message: data.message
            })
        }
        setLoading(false)
        history.push('/profile')
    }
    const changeRole = roles => {
        const selectedRoles = roles.join(',')
        setParam('buildingIndustryRole', selectedRoles)
    }
    const [show, setShow] = useState(false);
    const [filterPositions, setFilterPosition] = useState()
    useEffect(() => {
        let tempFilter = position?.filter(o1 => o1.Roles.some(o2 => o2.name_en === profile?.buildingIndustryRole || o2.name_de === profile?.buildingIndustryRole))
        let filterVal = tempFilter?.[0]?.Roles?.filter(it => it.name_en === profile?.buildingIndustryRole || it.name_de === profile?.buildingIndustryRole)
        let checkVal = isGerm ? filterVal?.[0]?.name_de : filterVal?.[0]?.name_en
        setFilterPosition(convertBranchRole(profile?.buildingIndustryRole, isGerm))
    }, [profile.buildingIndustryRole])

    return (
        <React.Fragment>
            <Layout>
                <div style={{ position: 'relative' }}>
                    <div className="w-300 add_company">
                        <div className="heading">
                            <Link to="/profile"><img width={10}
                                src={process.env.PUBLIC_URL + "/assets/img/back.svg"} /></Link>
                            <a onClick={e => {
                                setShow(true)
                            }} className="h_edit pointer">
                                <img
                                    src={process.env.PUBLIC_URL + "/assets/img/edit-pen-s.svg"} />
                            </a>
                        </div>
                        <div className="user_image text-center">
                            {!isUploadingFile ?
                                <img onClick={e => {
                                    setShow(true)
                                }}
                                style={{borderRadius:100}}
                                    src={profile.avatar ? BASE_URL + profile.avatar : process.env.PUBLIC_URL + "assets/img/head.svg"} />
                                :
                                <div style={{ display: "flex", textAlign: "center", flexDirection: "row" }}>
                                    <div style={{ flex: 1 }} />
                                    <div style={{
                                        height: '100px',
                                        // width: "100px",
                                        display: "flex",
                                        flexDirection: "column",
                                        textAlign: "center",
                                        border: "1px solid #DFDFDF",
                                        borderRadius: 10,
                                        justifyContent: "center",
                                        padding: 10,
                                    }}>
                                        <Oval
                                            height={40}
                                            width={40}
                                            color={KJUUP_COLORS.base_color}
                                            wrapperStyle={{ alignSelf: "center" }}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel='oval-loading'
                                            secondaryColor={"#fff"}
                                            strokeWidth={5}
                                            strokeWidthSecondary={5}
                                        />
                                        {/* <p>Please wait...</p> */}
                                    </div>
                                    <div style={{ flex: 1 }} />
                                </div>
                            }
                        </div>
                        <div className="form-bottom">
                            <Form className="add_company_from" onSubmit={(e) => {
                                e.preventDefault();
                                updateProfile();
                            }}>
                                <Form.Group className={showRoles ? "select_coustom position-relative edit_form theme-border" : "select_coustom position-relative edit_form"}>
                                    <a style={{ minHeight: '38px' }} className="register_role_XX" onClick={(e) => {
                                        e.preventDefault();
                                        SetShowRoles(true);
                                    }}>
                                        {!profile.buildingIndustryRole && <span>Role in the building industry* </span>}
                                        {profile.buildingIndustryRole &&
                                            <span style={{ marginRight: 20 }}>{filterPositions}</span>}
                                    </a>
                                    <BsChevronDown />
                                    <div className="profile-edit-roles">
                                        {
                                            showRoles &&
                                            <RegisterRole hideRoles={(e) => {
                                                SetShowRoles(false)
                                            }} selectPosition={changeRole} isGerms={isGerm} position={position} />
                                        }
                                    </div>
                                </Form.Group>
                                <div className="row">
                                    <div className="col-md-6 pr-1">
                                        <Form.Group className="custom_select">
                                            <Form.Control value={profile.title}
                                                onChange={e => setParam('title', e.target.value)} as="select">
                                                <option value="">
                                                    {isGerm ? DE.title : EN.title}
                                                </option>
                                                <option value="Dipl.-Ing.">
                                                    {isGerm ? DE.dep_ing : EN.dep_ing}
                                                </option>
                                                <option value="Dr.">{isGerm ? DE.dr : EN.dr}</option>
                                                <option value="Dr.-Ing.">
                                                    {isGerm ? DE.dr_ing : EN.dr_ing}
                                                </option>
                                                <option value="Dipl.-Ing. Architect">
                                                    {isGerm ? DE.dep_ing_arc : EN.dep_ing_arc}
                                                </option>
                                                <option value="Prof.">
                                                    {isGerm ? DE.prof : EN.prof}
                                                </option>
                                                <option value="Dipl.-Kfm.">
                                                    {"Dipl.-Kfm."}
                                                </option>
                                                <option value="Dipl.-Kff.">
                                                    {"Dipl.-Kff."}
                                                </option>
                                            </Form.Control>
                                            <img src={process.env.PUBLIC_URL + "/assets/img/arrow-down.svg"} />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 pr-1">
                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Control type="text"
                                                onChange={e => setParam('firstName', e.target.value)}
                                                placeholder={isGerm ? "Vorname" : "First Name"} value={profile.firstName} />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 pl-1">
                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Control onChange={e => setParam('lastName', e.target.value)}
                                                type="text"
                                                placeholder={isGerm ? "Nachname" : "Last Name"} value={profile.lastName} />
                                        </Form.Group>
                                    </div>
                                </div>
                                <Button disabled={loading} className="btn request-btn" type="submit">
                                    {loading ? <LoadingDots /> : isGerm ? DE.submit : EN.submit}
                                </Button>
                            </Form>
                        </div>
                    </div>

                </div>
            </Layout>
            <>
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {isGerm ? DE.select_profile_image : EN.select_profile_image}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ImageCropper changeImage={changeImage} />
                    </Modal.Body>
                </Modal>
            </>
        </React.Fragment>
    );
}

export default EditProfile;
