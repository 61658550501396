import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {DE} from '../../Languages/DE';
import {EN} from '../../Languages/EN';
import LanguageContext from '../../helper/LanguageContext';
import {callAPI} from "../../helper/apiUtils";
import {Scrollbars} from 'react-custom-scrollbars';
import _ from "lodash";
import {BASE_URL} from "../../helper/constants";
import {$crud} from "../../helper/CrudFactory";
import {BsFillXCircleFill} from "react-icons/all";
import {ReactComponent as ContactIcon} from "../../Icons/ContactIcon.svg"
import {ReactComponent as Projects} from "../../Icons/Projects.svg"
import "./ShareContact.css";
import {$user} from "../../helper/UserFactory";

const renderThumb = ({style, ...props}) => {
    const thumbStyle = {
        borderRadius: 6,
        backgroundColor: 'rgba(240, 132, 56, 0.8)'
    };
    return <div className="thumb-style" style={{...style, ...thumbStyle}} {...props} />;
};

const CustomScrollbars = props => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
    />
);

function ShareContact(props) {
    const [viewLoader,setLoader]=useState(false)
    const {isGerm} = React.useContext(LanguageContext)
    const [inviteLink, setInviteLink] = useState(false);
    const [search, setSearch] = useState('');
    const [users, setUsers] = useState([]);
    const [buildingUsers, setBuildingUsers] = useState([]);
    const [userList, setUsersList] = useState([]);
    const [postUser, setPostUser] = useState([]);
    const [viewProjectContact, setViewProjectContact] = useState(false);
    const [viewContact, setViewContact] = useState(true);
    const getCompanies = async () => {
        const {data} = await callAPI(`/building/getAllBuildingContacts`, {}, 'GET');
        if (data.status) {
            await CreateList(data.users, 'building');
        }
    }
    const getConnection = async () => {
        setLoader(true)
        const {data} = await callAPI(`/user/connections`, {}, 'GET');
        setLoader(false)
        if (data.status) {
            for (let obj of data.users) {
                let names = obj.fullName.split('')
                obj.lastName = names[1]
                obj.firstName = names[0]
            }
            await CreateList(data.users, 'user');
        }
    }

    const CreateList = async (users, type) => {
        if (type === 'building') {
            const arr = await _.uniqBy(users.map((user) => {
                let name = {name: user.lastName.trim()[0]?.toUpperCase()};
                return name;
            }), 'name');
            const updatedArr = _.sortBy(arr, ["name"]);
            const usersList = updatedArr.map((a) => {
                let names = users.filter(u => u.lastName.trim()[0]?.toUpperCase() === a.name);
                a.names = names;
                return a;
            });
            setBuildingUsers(usersList);
            setUsersList(usersList)
        } else {
            const arr = await _.uniqBy(users.map((user) => {
                let name = {name: user.lastName.trim()[0]?.toUpperCase()};
                return name;
            }), 'name');
            const updatedArr = _.sortBy(arr, ["name"]);
            const newUsersList = updatedArr.map((a) => {
                let names = users.filter(u => u.lastName.trim()[0]?.toUpperCase() === a.name);
                a.names = names;
                return a;
            });
            setUsers(newUsersList);
            setUsersList([...userList, ...newUsersList])
        }
    }
    useEffect(() => {
        getCompanies();
        getConnection();
    }, []);


    const filterUsers = async (text) => {
        setSearch(text)
        console.log(typeof text)

        if (text !== '') {
            let userListFilter = userList;
            let positionIndex = await users.findIndex(item => item.name.toLowerCase() == text.toLowerCase());

            if (positionIndex !== -1) {
                console.log(positionIndex);
                userListFilter = [userListFilter[positionIndex]]
                console.log(userListFilter)
            } else {
                let userListFilter = await userList.map(item => {
                    console.log(positionIndex);
                    let filterName = item.names.filter(i => i.fullName.toLowerCase().includes(text.toLowerCase()));
                    return {...item, names: filterName};
                });
                userListFilter = userListFilter.filter(position => position.names.length > 0)
                console.log(userListFilter)
                setUsers(userListFilter)
            }
        } else {
            setUsers(userList)
        }
    }

    const addUser = async (user) => {
        const chkExist = postUser.find(usr => usr.id === user?.id);
        if (chkExist) {
            const updatedUsers = postUser.filter(u => u.id !== user?.id);
            setPostUser(updatedUsers)
            const updateUser = await users.map(usr => {
                usr.names = usr.names.map(name => {
                    if (user?.id === name.id) {
                        return {...name, addStatus: false}
                    } else {
                        return name;
                    }
                });
                return usr;
            });
            const updateBuildingUsers = await buildingUsers.map(usr => {
                usr.names = usr.names.map(name => {
                    if (user?.id === name.id) {
                        return {...name, addStatus: false}
                    } else {
                        return name;
                    }
                });
                return usr;
            });
            setUsers(updateUser);
            setBuildingUsers(updateBuildingUsers);
        } else {
            const uniqUsers = _.uniqBy([...postUser, user], 'id');
            await setPostUser(uniqUsers);
            const updateUser = await users.map(u => {
                u.names = u.names.map(name => {
                    if (name.id === user?.id) {
                        return {...name, addStatus: true}
                    } else {
                        return name;
                    }
                });
                return u;
            });
            const updateBuildingUsers = await buildingUsers.map(u => {
                u.names = u.names.map(name => {
                    if (name.id === user?.id) {
                        return {...name, addStatus: true}
                    } else {
                        return name;
                    }
                });
                return u;
            });
            console.log(updateUser);
            setUsers(updateUser);
            setBuildingUsers(updateBuildingUsers)
        }
    }


    const addProjectUser = async () => {
        const sender = $user.get();
        const contacts = postUser.map(user => user?.id);
        console.log(contacts);
        if (postUser.length > 0) {
            props.sendCont({
                contacts: contacts,
                group: props.chat.id,
                isGroup: true,
                message: "",
                messageType: 'contact',
                sender: sender.id,
                senderId: sender.id,
                inReplyTo:{},
                conts:postUser
            })
            props.handleClose()
        } else {
            $crud.notify({
                type: 'error',
                message: "Please select users to continue"
            })
        }
    }

    const removeUser = async (u) => {
        const updatedUsers = postUser.filter(user => user?.id !== u.id);
        setPostUser(updatedUsers);
        const updateUser = await users.map(usr => {
            usr.names = usr.names.map(name => {
                if (u.id === name.id) {
                    return {...name, addStatus: false}
                } else {
                    return name;
                }
            });
            return usr;
        });
        const updateBuildingUsers = await buildingUsers.map(usr => {
            usr.names = usr.names.map(name => {
                if (u.id === name.id) {
                    return {...name, addStatus: false}
                } else {
                    return name;
                }
            });
            return usr;
        });
        console.log('users', updateUser);
        console.log('building Contact', updateBuildingUsers);
        setUsers(updateUser);
        setBuildingUsers(updateBuildingUsers);
    }

    function isExist(id) {
        const user = postUser.find(u => u.i === id);
        console.log(user);
        return !!user;
    }

    return (
        <React.Fragment>
            
            {
                !inviteLink &&
                <div class="company_section company_list right_company w-300 add_user_chat_right" style={{height:"93vh", display:"flex", flexDirection:"column"}}>
                     {viewLoader && (
                <div className="website_loader">
                  <div className="loader" />
                </div>
              )}
                    <div className="company_header">
                        <div class="heading_icon m-0">

                            <a onClick={(e) => props.handleClose()} className="main_heading pointer" style={{
                                color: "#f08438",
                            }}>
                                <svg className="back-color" width="10px" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.36 17.31">
                                    <polyline class="cls-1" points="9.01 16.96 0.71 8.66 9.01 0.35"/>
                                </svg>
                                {isGerm ? DE.cancel : EN.cancel}
                            </a>
                            <div style={{
                                marginLeft: "10px",
                                fontSize: "18px",
                                color: "#000",
                                fontWeight: 500
                            }}>{isGerm ? DE.share_user : EN.share_user}
                            </div>
                            <a style={{
                                color: postUser.length > 0 ? "#f08438" : "#737373",
                            }} onClick={addProjectUser} className="main_heading pointer">{isGerm ? DE.continue : EN.continue}
                            <img style={{color: "#f08438"}} width={10}
                                src={process.env.PUBLIC_URL + "/assets/img/arrow-rightdeselected.svg"}/>

                            </a>

                        </div>
                    </div>
                            <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200} className="bottom-bar">
                                <div className="row m-0">
                                    {
                                        postUser &&
                                        postUser.map((user, index) => (
                                            <div className="col-4 p-2 text-center">
                                                <img width={40} height={40}
                                                     className="news_profile"
                                                     style={{borderRadius: 6}}
                                                     src={user.avatar ? BASE_URL + user.avatar : process.env.PUBLIC_URL + "/assets/img/head.svg"}
                                                     alt="Generic placeholder"/>
                                                <div className="small font-weight-bold">{user.fullName}</div>
                                                <BsFillXCircleFill onClick={(e) => removeUser(user)} class="pointer" color="red"/>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="invite">
                                    {/* <div onClick={() => setViewProjectContact(!viewProjectContact)} className={`contact-header-section mb-2 ${viewProjectContact ? 'theme-color' : ''}`}>
                                        <Projects className={viewProjectContact ? 'active-svg' : 'disable-svg'} width="32px"/> Bulding projects contacts
                                    </div> */}
                                    {
                                        viewProjectContact &&
                                        <div className="admin_right add_user_chat">
                                            <div className="search_div">
                                                <div className="search">
                                                    <Form.Group controlId="exampleForm.ControlInput1">
                                                        <Form.Control autocomplete="off" value={search} type="text"
                                                                      placeholder={isGerm ? DE.search : EN.search}
                                                                      onChange={e => filterUsers(e.target.value)}/>
                                                    </Form.Group>

                                                </div>
                                            </div>
                                            <div className="admin-content">
                                                {
                                                    buildingUsers &&
                                                    buildingUsers.map((user, index) => (
                                                        <div key={index}>
                                                            <h5 className="mt-2">{user.name}</h5>
                                                            {
                                                                user.names &&
                                                                user.names.map((u, i) => (
                                                                    <div key={i} className="admin-contact">
                                                                        <div className="row m-0">
                                                                            <div className="col-md-10 p-0">
                                                                                <div className="admin-control">
                                                                                    <img width={40} height={40}
                                                                                         className="news_profile"
                                                                                         style={{borderRadius: 6}}
                                                                                         src={u.avatar ? BASE_URL + u.avatar : process.env.PUBLIC_URL + "/assets/img/head.svg"}
                                                                                         alt="Generic placeholder"/>
                                                                                    <h4>{u.fullName}
                                                                                        <span>{u.details}</span>
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2" pr-0>
                                                                                <div className="custom_checkbox">
                                                                                    <label className="container_checkbox">
                                                                                        {u.addStatus}
                                                                                        <input checked={u.addStatus} type="checkbox" value={u.id}
                                                                                               onClick={e => addUser(u)}/>
                                                                                        <span className="checkmark"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))

                                                            }

                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        </div>
                                    }


                                    {/* <div onClick={() => setViewContact(!viewContact)} className={`contact-header-section ${viewContact ? 'theme-color' : ''}`}>
                                        <ContactIcon className={viewContact ? 'active-svg' : 'disable-svg'} width="32px"/> Contacts
                                    </div> */}
                                    {
                                        viewContact &&
                                        <div className="admin_right add_user_chat">
                                            <div className="search_div">
                                                <div className="search">
                                                    <Form.Group controlId="exampleForm.ControlInput1">
                                                        <Form.Control autocomplete="off" value={search} type="text"
                                                                      placeholder={isGerm ? DE.search : EN.search}
                                                                      onChange={e => filterUsers(e.target.value)}/>
                                                    </Form.Group>

                                                </div>
                                            </div>
                                            <div className="admin-content">
                                                {
                                                    users &&
                                                    users.map((user, index) => (
                                                        <div key={index}>
                                                            <h5 className="mt-2">{user.name}</h5>
                                                            {
                                                                user.names &&
                                                                user.names.map((u, i) => (
                                                                    <div key={i} className="admin-contact">
                                                                        <div className="row m-0">
                                                                            <div className="col-md-10 p-0">
                                                                                <div className="admin-control">
                                                                                    <img width={40} height={40}
                                                                                         className="news_profile"
                                                                                         style={{borderRadius: 6}}
                                                                                         src={u.avatar ? BASE_URL + u.avatar : process.env.PUBLIC_URL + "/assets/img/head.svg"}
                                                                                         alt="Generic placeholder"/>
                                                                                    <h4>{u.fullName}
                                                                                        <span>{u.details}</span>
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2" pr-0>
                                                                                <div className="custom_checkbox">
                                                                                    <label className="container_checkbox">
                                                                                        <input checked={u.addStatus} type="checkbox" value={u.id}
                                                                                               onClick={e => addUser(u)}/>
                                                                                        <span className="checkmark"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))

                                                            }

                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        </div>
                                    }

                                </div>
                            </CustomScrollbars>
                </div>
            }
        </React.Fragment>
    );
}

export default ShareContact;
