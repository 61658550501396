import React, { memo, useEffect, useState } from "react";
import { DE } from '../../../Languages/DE';
import { EN } from '../../../Languages/EN';
import LanguageContext from "../../../helper/LanguageContext";
import { BASE_URL } from "../../../helper/constants";
import IconButton from "@material-ui/core/IconButton";
import { BsCheck, BsXCircle } from "react-icons/all";
import { callAPI } from "../../../helper/apiUtils";
import { convertPosition } from "../../../helper/convertor";
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { Positions } from "../../../Languages/vars/Positions";
import { $crud } from "../../../helper/CrudFactory";
import { $user } from "../../../helper/UserFactory";
import { connect } from "react-redux";
import { store, UPDATE_PROJECT_ACTION_FIRE } from "../../../store";
import { withRouter } from "react-router-dom";

function RejoinUsersDialoge({ handleClose, companyRequests }) {
    const { isGerm } = React.useContext(LanguageContext)
    const [users, setUsers] = useState([])
    const [Myusers, setMyUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [isShowCompany, setIsShowCompany] = useState(null)
    const [isShowME, setIsShowME] = useState(null)
    const [employeePosition, setEmployeePosition] = useState();
    const user = $user.get();
    useEffect(() => {
        let forME = companyRequests.filter(e => {
            return (e.buildingUser.findIndex(j => (j == user.id)) || e.buildingUser.findIndex(j => (j == user.id)) == 0)
        })
        setMyUsers(forME)
        if (forME.length > 0) {
            setIsShowCompany(false)
            setIsShowME(true)
        }
    }, [companyRequests])

    const confirmUser = async (user) => {
        hideConfirmDia()
        const { data } = await callAPI(
            `/building/${user.requestId}/ApproveProjectJoinRequest`,
            {},
            "POST",
            {
                id: user.id,
                companyId: user.company_id,
            }
        );
        $crud.notify({
            type: data.status ? "success" : "error",
            message: data.message,
        });
        if (isShowME) {
            const updatedUsers = Myusers.filter(u => u.id !== user?.id);
            setMyUsers(updatedUsers);
        }
        setSelectedUser(null)
    }
    const removeUser = async (user) => {
        hideConfirmDia()
        const { data } = await callAPI(
            `/building/${user.requestId}/RejectProjectJoinRequest`,
            {},
            "POST",
            {
                id: user.id,
                companyId: user.company_id,
            }
        );
        $crud.notify({
            type: data.status ? "success" : "error",
            message: data.message,
        });
        if (isShowME) {
            const updatedUsers = Myusers.filter(u => u.id !== user?.id);
            setMyUsers(updatedUsers);
        }
        setSelectedUser(null)
    }
    const [showPositionDialog, setPositionDialog] = useState(false);
    const hidePositionDialog = () => setPositionDialog(false);
    const [showConfirmDia, setConfirmDia] = useState(false);
    const hideConfirmDia = () => setConfirmDia(false);

    const handleSubmit = async (type) => {
        if (type == 'reject') {
            removeUser(selectedUser)
        }
        else {
            confirmUser(selectedUser)
        }
    }

    const onConfirmClick = (user) => {
        // setEmployeePosition(null)
        // setSelectedUser(user)
        confirmUser(user)
        // setPositionDialog(true)
    }

    const onRejectClick = (user) => {
        // setEmployeePosition(null)
        // setSelectedUser(user)
        // setConfirmDia(true)
        removeUser(user)
    }

    const handleCloseX = () => {
        setIsShowCompany(false)
        if (Myusers.length > 0) {
            setIsShowME(true)
        }
    }

    const handleCloseXX = () => {
        setIsShowME(false)
        if (users.length > 0) {
            setIsShowCompany(true)
        }
    }


    return (
        <>
            {Myusers.length > 0 &&
                <div className="blur_bg">
                    <div className="role_popup shadow-sm">
                        <h4>{isGerm ? DE.request_to_join_a_room : EN.request_to_join_a_room}</h4>
                        <div className="role_data" style={{ minHeight: 100, overflow: 'hidden' }}>
                            {
                                Myusers.map((user, index) => (
                                    <div style={{ display: "flex", flexDirection: "row" }} className="m-0" key={index}>
                                        <div className="col-2 p-2">
                                            <img
                                                width={40}
                                                height={40}
                                                className="mr-3 news_profile"
                                                src={user.avatar ? BASE_URL + user.avatar : process.env.PUBLIC_URL + "assets/img/profile.png"}
                                                alt="Generic placeholder"
                                            />
                                        </div>
                                        <div style={{}}>
                                            <>
                                            <strong>{user.user}</strong>
                                                {!isGerm
                                                    ? ' wants to rejoin the room '
                                                    : ' möchte dem Raum '}
                                                 <strong>{user.buildingName}</strong>{isGerm ? " wieder beitreten." : "."}
                                            </>

                                            <div style={{}}>
                                                <IconButton onClick={e => onConfirmClick(user)} className="p-2 m-2" color="primary" aria-label="Accept Request" component="span">
                                                    <BsCheck color="green" size={20} />
                                                </IconButton>
                                                <IconButton onClick={e => onRejectClick(user)} className="p-2 m-2" color="primary" aria-label="Delete Request" component="span">
                                                    <BsXCircle color="red" size={16} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="bottom-button">
                            <a className="pointer" onClick={handleCloseXX}>{isGerm ? DE.close : EN.close}</a>
                        </div>
                    </div>
                    <Modal
                        show={showPositionDialog}
                        onHide={hidePositionDialog}
                        animation={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{isGerm ? DE.your_position_within_company : EN.your_position_within_company}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ListGroup variant="flush">
                                {Positions.map((position) => (
                                    <div
                                        onClick={(e) => setEmployeePosition(position.name_en)}
                                        key={position.id}
                                        className="form-check p"
                                    >
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="exampleRadios"
                                            id={position.id}
                                            value={position.name_en}
                                        />
                                        <label
                                            className="form-check-label pointer"
                                            htmlFor={position.id}
                                        >
                                            {isGerm ? position.name_de : position.name_en}
                                        </label>
                                    </div>
                                ))}
                            </ListGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {
                                setConfirmDia(true)
                            }}>
                                {isGerm ? DE.close_modal : EN.close_modal}
                            </Button>
                            <Button variant="primary"
                                onClick={handleSubmit}
                            >
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={showConfirmDia}
                        onHide={hideConfirmDia}
                        animation={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{isGerm ? DE.confirm : EN.confirm}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{isGerm ? DE.refuse_company : EN.refuse_company}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={hideConfirmDia}>
                                {isGerm ? DE.cancel : EN.cancel}
                            </Button>
                            <Button variant="primary"
                                onClick={() => handleSubmit('reject')}
                            >
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </>

    )
}

const mapStateToProps = (state) => {
    const {
        openProjects,
        updateProjectActionFire,
        projects,
        groupChats,
        forwardGroupMessages,
        draftMessages,
        updateGroupMessage,
        isOpenByOwner,
    } = state;
    return {
        projects: projects,
        openProjects: openProjects,
        updateProjectActionFire: updateProjectActionFire,
        groupChats: groupChats,
        forwardGroupMessages: forwardGroupMessages,
        draftMessages: draftMessages,
        updateGroupMessage: updateGroupMessage,
        isOpenByOwner,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateProjectAction: (value) =>
            dispatch({
                type: UPDATE_PROJECT_ACTION_FIRE,
                updateProjectActionFire: value,
            }),

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(RejoinUsersDialoge)));