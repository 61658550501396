import React from 'react';
import { Modal } from "react-bootstrap";
import { BsXCircleFill } from 'react-icons/bs';
import Carousel from "react-multi-carousel";
import { BASE_URL } from "../../../helper/constants";
import { ARTICLE, store } from "../../../store";



export default function FeedViewDialog({ viewFeed, article }) {
    const handleClose = () => {
        store.dispatch({
            type: ARTICLE,
            article: null
        })
    }

    return (
        <React.Fragment>
            <Modal size="lg" show={!!article} onHide={handleClose} centered className="feed-dialog">

                <Modal.Body className="position-relative p-0">
                    <div className="article-wrapper" >
                        {
                            article.uploadUrl &&
                            <div className="article-img" style={{ overflow: 'hidden', width: '100%' }}>
                                <Carousel
                                    additionalTransfrom={0}
                                    arrows={article.uploadUrl.length > 1 ? true : false}
                                    centerMode={false}
                                    className="p-0"
                                    // containerClass="container"
                                    dotListClass=""
                                    draggable={article.uploadUrl.length > 1 ? true : false}
                                    focusOnSelect={false}
                                    infinite={false}
                                    itemClass=""
                                    keyBoardControl
                                    minimumTouchDrag={80}
                                    renderButtonGroupOutside={false}
                                    renderDotsOutside={false}
                                    responsive={{
                                        desktop: {
                                            breakpoint: {
                                                max: 3000,
                                                min: 1024
                                            },
                                            items: 1,
                                        },
                                        mobile: {
                                            breakpoint: {
                                                max: 464,
                                                min: 0
                                            },
                                            items: 1
                                        },
                                        tablet: {
                                            breakpoint: {
                                                max: 1024,
                                                min: 464
                                            },
                                            items: 1
                                        }
                                    }}
                                    showDots={article.uploadUrl.length > 1 ? true : false}
                                    sliderClass=""
                                    slidesToSlide={1}
                                    swipeable
                                >
                                    {
                                        article.uploadUrl.map((image, index) => (
                                            <div key={index} className="feed-slide">
                                                <img
                                                    src={BASE_URL + image.fileUrl}
                                                    style={{
                                                        display: 'block',
                                                        height: 'auto',
                                                        maxHeight: '83vh',
                                                        margin: 'auto',
                                                        width: 'auto'
                                                    }}
                                                />
                                                <BsXCircleFill onClick={handleClose} size={25} className="close-preview-dialog" />
                                            </div>
                                        ))
                                    }
                                </Carousel>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}
