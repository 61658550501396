import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import LanguageContext from "../../helper/LanguageContext";
import { callAPI } from "../../helper/apiUtils";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import { BASE_URL, KJUUP_COLORS } from "../../helper/constants";
import { $crud } from "../../helper/CrudFactory";
import { BsFillXCircleFill } from "react-icons/all";
import { $user } from "../../helper/UserFactory";
import { convertCapitalLeter, convertPosition } from "../../helper/convertor";
import { async } from "@firebase/util";
import { store, UPDATE_PROJECT_ACTION_FIRE } from "../../store";
import Switch from "react-switch";
import { useSelector } from "react-redux";

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

function AddProjectChatUser(props) {
  const { isGerm } = React.useContext(LanguageContext);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [usersAll, setUsersAll] = useState([]);
  const [userList, setUsersList] = useState([]);
  const [postUser, setPostUser] = useState(props.allUsers);
  const [existingUsers, setExistingUsers] = useState(props.users);
  const [existingUsersAll, setExistingUsersAll] = useState(props.users);
  const [continueAction, setContinueAction] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [checkToggle, setCheckToggle] = useState(false)
  const [isAllMember, setIsAllMember] = useState(false)
  const [loader, setLoader] = useState(false)
  const user = useSelector((state) => state.user);


  const getCompanies = async () => {
    const id = props.project_id;
    const { data } = await callAPI(
      !props.isInternal ? `/building/${id}/getMyBuildingContacts` : `/building/${id}/getMyBuildingContacts?company_id=${props.cID}`,
      {},
      "GET"
    );
    const arrList = await data.users;
    await CreateList(arrList);
  };


  const CreateList = async (users) => {
    const arr = await _.uniqBy(
      users?.map((user) => {
        return { name: user.lastName.trim()[0].toUpperCase() };
      }),
      "name"
    );
    const updatedArr = _.sortBy(arr, ["name"]);
    const usersList = updatedArr.map((a) => {
      a.names = users.filter((u) => u.lastName.trim()[0].toUpperCase() === a.name);
      return a;
    });
    setUsers(usersList);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    _prepaareArr()
  }, [props])

  const _prepaareArr = async () => {
    if (props.myMems && props.myMems.length > 0 || props.Mems) {
      let tmp = [...existingUsers]
      let users = (props.myMems && Array.isArray(props.myMems)) ? [...props.myMems, ...props.Mems] : [...props.Mems]
      let already = (props.gpUser && Array.isArray(props.gpUser)) ? [...props.gpUser] : []
      if (props.addAsMember) {
        for (let i = 0; i < already.length; i++) {
          let inx = users.findIndex(e => e.id == already[i]['id'])
          if (inx !== -1) {
            if ((already[i]['requestTime'] && already[i]['isLeave']) || !already[i]['isLeave'])
              users.splice(inx, 1)
          }
        }
      }
      console.log('---------xx', props, already)
      const arr = await _.uniqBy(
        users.map((user) => {
          return { name: user.lastName.trim()[0].toUpperCase() };
        }),
        "name"
      );
      const updatedArr = _.sortBy(arr, ["name"]);
      const usersList = updatedArr.map((a) => {
        a.names = users.filter((u) => u.lastName.trim()[0].toUpperCase() === a.name);
        return a;
      });
      tmp = [...tmp, ...usersList]
      var output = [];
      tmp.forEach(function (item) {
        var existing = output.filter(function (v, i) {
          return v.name == item.name;
        });
        if (existing.length) {
          var existingIndex = output.indexOf(existing[0]);
          output[existingIndex].names = output[existingIndex].names.concat(item.names);
        } else {
          if (typeof item.names == 'string')
            item.names = [item.names];
          output.push(item);
        }
      });
      output = output.map((e) => {
        e.names = e.names.filter((value, index, self) => {
          return index === self.findIndex((t) => (
            t.id === value.id
          ))
        })
        return { ...e }
      })
      setExistingUsers([...output])
      setExistingUsersAll([...output])
    }
  }



  const addProjectUser = async () => {
    if (!groupName) {
      $crud.notify({
        type: "error",
        message: "Please enter group name",
      });
      return false;
    }
    let selectedMembers = []
    existingUsers.map(e => {
      e.names.map(j => {
        if (j.addStatus) {
          selectedMembers.push(j)
        }
      })
    })
    if (selectedMembers.length > 0) {
      let postUser = [...selectedMembers]
      const id = props.project_id;
      let userIds = postUser.map((u) => {
        return u.id;
      });
      let memberToAdd = []
      postUser.map((u) => {
        if (u.nonmember)
          memberToAdd.push({ user: u.id })
      })
      setLoader(true)
      let nonmemberId = postUser?.filter((itm) => itm.nonmember === true);
      if (nonmemberId?.length > 0) {
        // const { data } = await callAPI(props.isInternal ? `/building/${id}/add?companyId=${props.cID}` : props.cID ? `/building/${id}/add?companyId=${props.cID}` : `/building/${id}/add`, {}, "PUT", {
        //   id: id,
        //   users: memberToAdd,
        // });
        const { data } = await callAPI(props.isInternal ? `/building/${id}/add?companyId=${props.cID}` : `/building/${id}/add`, {}, "PUT", {
          id: id,
          users: memberToAdd,
        });
        if (data.status) {
          const { data } = await callAPI(`/group`, {}, "POST", {
            building: id,
            groupType: "building",
            isOneToOne: false,
            name: groupName,
            users: userIds,
          });
          console.log({
            building: id,
            groupType: "building",
            isOneToOne: false,
            name: groupName,
            users: userIds,
          })

          $crud.notify({
            type: data.status ? "success" : "error",
            message: data.message,
          });
          props.setAllUsers([]);
          props.handleEditClose();
        }
        else {
          $crud.notify({
            type: data.status ? "success" : "error",
            message: data.message,
          });
        }
      } else {
        const { data } = await callAPI(`/group`, {}, "POST", {
          building: id,
          groupType: "building",
          isOneToOne: false,
          name: groupName,
          users: userIds,
        });
        $crud.notify({
          type: data.status ? "success" : "error",
          message: data.message,
        });
        props.setAllUsers([]);
        props.handleEditClose();
      }
      setLoader(false)
    }
  };

  const removeUser = async (u) => {
    const updatedUsers = postUser.filter((user) => user?.id !== u.id);
    const updatedAllUsers = props.allUsers.filter((user) => user?.id !== u.id);
    setPostUser(updatedUsers);
    props.setAllUsers(updatedAllUsers);
    const updateUser = await users.map((usr) => {
      usr.names = usr.names.map((name) => {
        if (name.id === u.id) {
          return { ...name, addStatus: false };
        } else {
          return name;
        }
      });
      return usr;
    });
    setUsers(updateUser);
    const updateExistUser = await existingUsers.map((usr) => {
      usr.names = usr.names.map((name) => {
        if (name.id === u.id) {
          return { ...name, addStatus: false };
        } else {
          return name;
        }
      });
      return usr;
    });
    setUsers(updateExistUser);
  };

  const continueHandler = async () => {
    let selectedMembers = []
    existingUsers.map(e => {
      e.names.map(j => {
        if (j.addStatus) {
          selectedMembers.push(j)
        }
      })
    })
    if (selectedMembers.length > 0) {
      let postUser = [...selectedMembers]
      if (props.addAsMember) {
        await $crud.confirm({
          title: isGerm ? DE.confirm : EN.confirm,
          textContent: isGerm ? DE.check_selected_settings : EN.check_selected_settings,
          options: {
            ok: isGerm ? DE.confirm : EN.confirm,
            cancel: isGerm ? DE.cancel : EN.cancel
          }
        });
        setLoader(true)
        const id = props.project.id;
        let memberToAdd = []
        postUser.map((u) => {
          if (u.nonmember)
            memberToAdd.push({ user: u.id })
        })
        let ids = []
        for (let obj of postUser) {
          ids.push({ chat_from_start: obj.isChecked ? true : false, user: obj.id })
        }
        ids = ids.filter(e => e.user != user.id)
        if (memberToAdd.length > 0) {
          // let { data } = await callAPI(props.isInternal ? `/building/${id}/add?companyId=${props.cID}` : props.cID ? `/building/${id}/add?companyId=${props.cID}` : `/building/${id}/add`, {}, "PUT", {
          //   users: memberToAdd,
          // });
          let { data } = await callAPI(props.isInternal ? `/building/${id}/add?companyId=${props.cID}` : `/building/${id}/add`, {}, "PUT", {
            users: memberToAdd,
          });
          if (data.status) {
            let { data } = await callAPI(`/group/${props.groupId}/add`, {}, "PUT", {
              users: ids,
              building: id
            })
            $crud.notify({
              type: data.status ? "success" : "error",
              message: data.message,
            });
            setPostUser([])
            store.dispatch({
              type: UPDATE_PROJECT_ACTION_FIRE,
              updateProjectActionFire: true,
            });
            props.handleEditClose()
          }
          else {
            $crud.notify({
              type: data.status ? "success" : "error",
              message: data.message,
            });
          }
        }
        else {
          let { data } = await callAPI(`/group/${props.groupId}/add`, {}, "PUT", {
            users: ids,
            building: id
          })
          $crud.notify({
            type: data.status ? "success" : "error",
            message: data.message,
          });
          setPostUser([])
          store.dispatch({
            type: UPDATE_PROJECT_ACTION_FIRE,
            updateProjectActionFire: true,
          });
          props.handleEditClose()
        }
        setLoader(false)
      }
      else
        setContinueAction(true);
    }
    else {
      $crud.notify({
        type: "error",
        message: "Please select users to continue",
      });
    }
  };

  useEffect(() => {
    if (checkToggle) {
      let { index, id } = checkToggle
      let tmp = [...existingUsers]
      tmp = tmp.map((e, i) => {
        e.names = e.names.map((j, k) => {
          if (id == j.id) {
            return { ...j, isChecked: !j.isChecked }
          }
          else {
            return { ...j }
          }
        })
        return { ...e }
      })
      setExistingUsers(tmp)
      setExistingUsersAll([...tmp])
      setCheckToggle(false)
    }
  }, [checkToggle])

  const _getSelectedCount = () => {
    let cnt = []
    existingUsers.map(user => {
      let sl = user.names.filter(e => e.addStatus)
      cnt = [...cnt, ...sl]
    })
    return cnt.length
  }

  return (
    <React.Fragment>
      <div class="company_section company_list right_company" style={{ height: "93vh", display: "flex", flexDirection: "column" }}>
        {/* <div className="company_header" >
          <div class="heading_icon m-0">
            <a
              onClick={(e) => props.handleEditClose()}
              className="main_heading pointer"
              style={{
                color: props.isInternal ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color,
              }}
            >
              {!props.isInternal ?
                <img
                  width={10}
                  src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                />
                :
                <img
                  width={10}
                  className="custom-svg"
                  src={process.env.PUBLIC_URL + "/assets/img/backx.svg"}
                />
              }
              {isGerm ? DE.cancel : EN.cancel}
            </a>
            <div
              style={{
                marginLeft: "10px",
                fontSize: "18px",
                color: "#000",
                fontWeight: 500,
              }}
            >
              {isGerm ? DE.add_user : EN.add_user}
            </div>
            {!continueAction && (
              <a
                style={{
                  whiteSpace: "nowrap",
                  color: postUser?.length > 0 ? props.isInternal ? "#288f6c" : "#f08438" : "#737373",
                }}
                onClick={continueHandler}
                className="main_heading pointer"
              >
                {isGerm ? DE.continue : EN.continue}
                <img
                  style={{ color: props.isInternal ? "#288f6c" : "#f08438" }}
                  width={10}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/img/arrow-rightdeselected.svg"
                  }
                />
              </a>
            )}
            {continueAction && (
              <a
                style={{
                  whiteSpace: "nowrap",
                  color: postUser?.length > 0 ? props.isInternal ? "#288f6c" : "#f08438" : "#737373",
                }}
                onClick={addProjectUser}
                className="main_heading pointer"
              >
                {isGerm ? DE.create_group : EN.create_group}
                <img
                  style={{ color: props.isInternal ? "#288f6c" : "#f08438" }}
                  width={10}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/img/arrow-rightdeselected.svg"
                  }
                />
              </a>
            )}
          </div>
        </div> */}
        <div className="company_header">
          <div class="heading_icon m-0">
            <a
              onClick={(e) => {
                if (!continueAction)
                  props.handleEditClose()
                else
                  setContinueAction(false)
              }}
              className="main_heading pointer"
              style={{
                color: props.isInternal ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color,
                fontSize: "17px"
              }}
            >
              {!props.isInternal ?
                <img
                  width={10}
                  src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                />
                :
                <img
                  width={10}
                  className="custom-svg"
                  src={process.env.PUBLIC_URL + "/assets/img/backx.svg"}
                />
              }
              {isGerm ? DE.cancel : EN.cancel}
            </a>
            <a
              // className="main_heading pointer"
              style={{ fontSize: "17px", fontWeight: "600" }}
            >
              {isGerm ? DE.add_user : EN.add_user}
            </a>
            <a
              style={{
                color: (_getSelectedCount() > 0 && !loader) ? props.isInternal ? KJUUP_COLORS.internal : "#f08438" : "#737373",
                fontSize: "17px"

              }}
              onClick={() => {
                if (!loader) {
                  if (continueAction)
                    addProjectUser()
                  else
                    continueHandler()
                }
              }}
              className="main_heading pointer"
            >
              {isGerm ? !continueAction ? DE.continue : DE.create_group : !continueAction ? EN.continue : EN.create_group}
              <img
                style={{ color: "#f08438" }}
                width={10}
                src={
                  process.env.PUBLIC_URL + (_getSelectedCount() > 0 && !loader) ? props.isInternal ? "/assets/img/arrowRightGreen.svg" : "/assets/img/arrowRight.svg" : "/assets/img/arrow-rightdeselected.svg"
                }
              />
            </a>
          </div>
        </div>
        <div className="company_list_ver" style={{ flex: 1 }}>
          <CustomScrollbars
            autoHide
            autoHideTimeout={500}
            autoHideDuration={200}
            className="bottom-bar"
          >
            {!continueAction && (
              <>
                <div
                  className="pt-2"
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    color: "#737373",
                  }}
                >
                  {isGerm ? DE.choose : EN.choose}{" "}
                  <strong>
                    {isGerm ? DE.your_add_member : EN.your_add_member}
                  </strong>{" "}
                  {isGerm ? "," : ""} {isGerm ? DE.if_you : EN.if_you}
                  <p className="p-0">
                    {isGerm ? DE.can_not_find : EN.can_not_find}
                  </p>
                </div>

                <div className="bg-purple bg-white mt-10">
                  <a
                    onClick={props.addMember}
                    className={props.isInternal ? "middle-title pointer ax" : "middle-title pointer"}
                  >
                    <span>
                      <img
                        width={28}
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/add-to-project.svg"
                        }
                        alt="Generic placeholder"
                      />
                    </span>
                    {isGerm ? DE.add_member : EN.add_member}
                  </a>
                </div>
              </>
            )}
            {/* {!continueAction && (
              <div className="row m-0">
                {props.allUsers &&
                  props.allUsers.map((user, index) => (
                    <div className="col-4 p-2 text-center">
                      <img
                        width={40}
                        height={40}
                        className="news_profile"
                        style={{ borderRadius: 100 }}
                        src={
                          user.avatar
                            ? BASE_URL + user.avatar
                            : process.env.PUBLIC_URL + "/assets/img/head.svg"
                        }
                        alt="Generic placeholder"
                      />
                      <div className="small font-weight-bold">
                        {user.fullName}
                      </div>
                      <BsFillXCircleFill
                        onClick={(e) => removeUser(user)}
                        class="pointer"
                        color="red"
                      />
                    </div>
                  ))}
              </div>
            )} */}
            <div className="invite p-2">
              <div className="admin_right">
                {/* {!continueAction && (
                  <div className="search_div">
                    <div className="search">
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control
                          autocomplete="off"
                          value={search}
                          type="text"
                          placeholder={isGerm ? DE.search : EN.search}
                          onChange={(e) => filterUsers(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                )} */}
                {continueAction && (
                  <div className="search_div">
                    <div className="search">
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control
                          autocomplete="off"
                          value={groupName}
                          type="text"
                          onChange={(e) => setGroupName(e.target.value)}
                          placeholder={isGerm ? DE.group_name : EN.group_name}
                        />
                      </Form.Group>
                    </div>
                  </div>
                )}
                {search && (
                  <div className="admin-content">
                    {existingUsers &&
                      existingUsers.map((user, index) => (
                        <div key={index}>
                          <h5 className="mt-2 mx-1">{user.name}</h5>
                          {user.names &&
                            _.sortBy(
                              user.names,
                              [(us) => us?.lastName?.toLowerCase()],
                              ["lastName"]
                            ).map((u, i) => (
                              <div key={i} className="admin-contact" onClick={() => {
                                // addUser(u);
                                let tmp = [...existingUsers]
                                let ids = []
                                tmp = tmp.map(e => {
                                  e.names = e.names.map(j => {
                                    return { ...j, addStatus: (u.id == j.id) ? !j.addStatus : j.addStatus }
                                  })
                                  let x = e.names.filter(xs => xs.addStatus)
                                  if (x?.length == e.names.length)
                                    ids = [...ids, { ...e }]
                                  return { ...e }
                                })
                                if (ids.length == tmp.length) {
                                  setIsAllMember(true)
                                }
                                else {
                                  setIsAllMember(false)
                                }
                                setExistingUsers([...tmp])
                                // setExistingUsersAll([...tmp])
                              }}>
                                <div className="row m-0 mx-1">
                                  <div className="col-md-10 p-0">
                                    <div
                                      className="admin-control"
                                      style={
                                        (u.requestNotYetAccepted || u.nonmember) ? { opacity: "0.5" } : {}
                                      }
                                    >
                                      <img
                                        width={40}
                                        height={40}
                                        className="news_profile"
                                        style={{ borderRadius: 6, objectFit:"cover" }}
                                        src={
                                          u.avatar
                                            ? BASE_URL + u.avatar
                                            : process.env.PUBLIC_URL +
                                            "/assets/img/head.svg"
                                        }
                                        alt="Generic placeholder"
                                      />
                                      <h4>
                                        {u.fullName
                                          ? convertCapitalLeter(u.fullName)
                                          : ""}
                                        {(u.requestNotYetAccepted || u.nonmember) ? (
                                          <span>
                                            {isGerm ? DE.PENDING : EN.PENDING}{" "}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        <span>{u.details}</span>
                                        <span>{u.companyName}</span>
                                        {u.position ? (
                                          <span>
                                            Position:{" "}
                                            {convertPosition(
                                              isGerm,
                                              u.position
                                            )}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="custom_checkbox">
                                      <label className={props.isInternal ? "role-label-internal" : "role-label"}>
                                        <input
                                          // checked={u.addStatus}
                                          disabled
                                          checked={
                                            u.addStatus
                                          }
                                          type="checkbox"
                                          value={u.id}
                                          onClick={(e) => {
                                            // addUser(u);
                                          }}
                                        />
                                        <span style={{ right: -10 }} className={props.isInternal ? "checkmark-label-internal" : "checkmark-label"} />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {props.addAsMember ?
                                  <div>
                                    <div style={{ marginTop: 10, display: "flex", alignItems: "center" }} className={props.isInternal ? "project-checkbox-internal" : "project-checkbox"}>
                                      <Form>
                                        <Form.Check
                                          checked={u.isChecked ? u.isChecked : false}
                                          onChange={async () => {
                                            let tmp = [...existingUsers]
                                            await $crud.confirm({
                                              title: isGerm ? DE.confirm : EN.confirm,
                                              textContent: isGerm ? u.isChecked ? DE.toggle_chat_history_off : DE.toggle_chat_history_on : u.isChecked ? EN.toggle_chat_history_off : EN.toggle_chat_history_on,
                                              options: {
                                                ok: isGerm?DE.confirm: EN.confirm,
                                                cancel: isGerm?DE.cancel: EN.cancel
                                              }
                                            });
                                            setCheckToggle({ index, id: u.id })
                                          }}
                                          type="switch"
                                          // id={u.id}
                                          // id={'dd'}
                                          id={`custom-switch-${u.id}`}
                                        // value={u.isChecked ? u.isChecked : false}
                                        />
                                      </Form>
                                      <span style={{ color: u.isChecked ? props.isInternal ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color : "#737373", fontSize: "14px", fontStyle: "italic" }}>
                                        {isGerm ? DE.allow_access_chat : EN.allow_access_chat}
                                      </span>
                                    </div>
                                  </div>
                                  :
                                  <></>
                                }
                              </div>
                            ))}
                        </div>
                      ))}
                  </div>
                )}
                {
                  (existingUsers?.length > 0 && !continueAction && !search) ?
                    <div
                      style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                      onClick={() => {
                        let inn = !isAllMember
                        setIsAllMember(inn)
                        if (inn) {
                          let tmp = [...existingUsers]
                          tmp = tmp.map(e => {
                            e.names = e.names.map(j => {
                              return { ...j, addStatus: true }
                            })
                            return { ...e }
                          })
                          setExistingUsers([...tmp])
                          setExistingUsersAll([...tmp])
                        }
                        else {
                          let tmp = [...existingUsers]
                          tmp = tmp.map(e => {
                            e.names = e.names.map(j => {
                              return { ...j, addStatus: false }
                            })
                            return { ...e }
                          })
                          setExistingUsers([...tmp])
                          setExistingUsersAll([...tmp])

                        }
                      }}
                      className="mx-1 my-3"
                    >
                      <div
                        style={{}}
                      >
                        <span style={{ fontWeight: "600" }}>
                          {isGerm ? DE.invite_all_members : EN.invite_all_members}
                        </span>
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        <label className={props.isInternal ? "role-label-internal" : "role-label"}>
                          <input
                            disabled
                            checked={isAllMember}
                            type="checkbox"
                            style={{ backgroundColor: "red" }}
                          />
                          <span style={{ top: 0, right: 5 }} className={props.isInternal ? "checkmark-label-internal" : "checkmark-label"} />
                        </label>
                      </div>
                    </div>
                    :
                    <></>
                }
                {!search && !continueAction && (
                  <div className="admin-content">
                    {existingUsers &&
                      existingUsers.map((user, index) => (
                        <div key={index}>
                          <h5 className="mt-2 mx-1">{user.name}</h5>
                          {user.names &&
                            _.sortBy(
                              user.names,
                              [(us) => us?.lastName?.toLowerCase()],
                              ["lastName"]
                            ).map((u, i) => (
                              <div key={i} className="admin-contact" onClick={() => {
                                // addUser(u);
                                let tmp = [...existingUsers]
                                let ids = []
                                tmp = tmp.map(e => {
                                  e.names = e.names.map(j => {
                                    return { ...j, addStatus: (u.id == j.id) ? !j.addStatus : j.addStatus }
                                  })
                                  let x = e.names.filter(xs => xs.addStatus)
                                  if (x?.length == e.names.length)
                                    ids = [...ids, { ...e }]
                                  return { ...e }
                                })
                                if (ids.length == tmp.length) {
                                  setIsAllMember(true)
                                }
                                else {
                                  setIsAllMember(false)
                                }
                                setExistingUsers([...tmp])
                                setExistingUsersAll([...tmp])
                              }}>
                                <div className="row m-0 mx-1">
                                  <div className="col-md-10 p-0">
                                    <div
                                      className="admin-control"
                                      style={
                                        (u.requestNotYetAccepted || u.nonmember) ? { opacity: "0.5" } : {}
                                      }
                                    >
                                      <img
                                        width={40}
                                        height={40}
                                        className="news_profile"
                                        style={{ borderRadius: 100 }}
                                        src={
                                          u.avatar
                                            ? BASE_URL + u.avatar
                                            : process.env.PUBLIC_URL +
                                            "/assets/img/head.svg"
                                        }
                                        alt="Generic placeholder"
                                      />
                                      <h4>
                                        {u.fullName
                                          ? convertCapitalLeter(u.fullName)
                                          : ""}
                                        {(u.requestNotYetAccepted || u.nonmember) ? (
                                          <span>
                                            {isGerm ? DE.PENDING : EN.PENDING}{" "}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        <span>{u.details}</span>
                                        <span>{u.companyName}</span>
                                        {u.position ? (
                                          <span>
                                            Position:{" "}
                                            {convertPosition(
                                              isGerm,
                                              u.position
                                            )}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="custom_checkbox">
                                      <label className={props.isInternal ? "role-label-internal" : "role-label"}>
                                        <input
                                          // checked={u.addStatus}
                                          disabled
                                          checked={
                                            u.addStatus
                                          }
                                          type="checkbox"
                                          value={u.id}
                                          onClick={(e) => {
                                            // addUser(u);
                                          }}
                                        />
                                        <span style={{ right: -10 }} className={props.isInternal ? "checkmark-label-internal" : "checkmark-label"} />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {props.addAsMember ?
                                  <div>
                                    <div style={{ marginTop: 10, display: "flex", alignItems: "center" }} className={props.isInternal ? "project-checkbox-internal" : "project-checkbox"}>
                                      <Form>
                                        <Form.Check
                                          checked={u.isChecked ? u.isChecked : false}
                                          onChange={async () => {
                                            let tmp = [...existingUsers]
                                            await $crud.confirm({
                                              title: isGerm ? DE.confirm : EN.confirm,
                                              textContent: isGerm ? u.isChecked ? DE.toggle_chat_history_off : DE.toggle_chat_history_on : u.isChecked ? EN.toggle_chat_history_off : EN.toggle_chat_history_on,
                                              options: {
                                                ok: isGerm ? DE.confirm : EN.confirm,
                                                cancel: isGerm ? DE.cancel : EN.cancel
                                              }
                                            });
                                            setCheckToggle({ index, id: u.id })
                                          }}
                                          type="switch"
                                          // id={u.id}
                                          // id={'dd'}
                                          id={`custom-switch-${u.id}`}
                                        // value={u.isChecked ? u.isChecked : false}
                                        />
                                      </Form>
                                      <span style={{ color: u.isChecked ? props.isInternal ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color : "#737373", fontSize: "14px", fontStyle: "italic" }}>
                                        {isGerm ? DE.allow_access_chat : EN.allow_access_chat}
                                      </span>
                                    </div>
                                  </div>
                                  :
                                  <></>
                                }
                              </div>
                            ))}
                        </div>
                      ))}
                  </div>
                )}
                {continueAction && existingUsers && (
                  <div className="admin-content">
                    {existingUsers &&
                      existingUsers.map((user, index) => (
                        <div key={index}>
                          {user.names.filter(e => e.addStatus)?.length > 0 ?
                            <h5 className="mt-2">{user.name}</h5>
                            :
                            <></>
                          }
                          {user.names &&
                            _.sortBy(
                              user.names,
                              [(us) => us?.lastName?.toLowerCase()],
                              ["lastName"]
                            ).map((u, i) => (
                              u.addStatus ?
                                <div key={i} className="admin-contact" onClick={() => {

                                }}>
                                  <div className="row m-0 mx-1">
                                    <div className="col-md-10 p-0">
                                      <div
                                        className="admin-control"
                                        style={
                                          (u.requestNotYetAccepted || u.nonmember) ? { opacity: "0.5" } : {}
                                        }
                                      >
                                        <img
                                          width={40}
                                          height={40}
                                          className="news_profile"
                                          style={{ borderRadius: 100 }}
                                          src={
                                            u.avatar
                                              ? BASE_URL + u.avatar
                                              : process.env.PUBLIC_URL +
                                              "/assets/img/head.svg"
                                          }
                                          alt="Generic placeholder"
                                        />
                                        <h4>
                                          {u.fullName
                                            ? convertCapitalLeter(u.fullName)
                                            : ""}
                                          {(u.requestNotYetAccepted || u.nonmember) ? (
                                            <span>
                                              {isGerm ? DE.PENDING : EN.PENDING}{" "}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          <span>{u.details}</span>
                                          <span>{u.companyName}</span>
                                          {u.position ? (
                                            <span>
                                              Position:{" "}
                                              {convertPosition(
                                                isGerm,
                                                u.position
                                              )}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </h4>
                                      </div>
                                    </div>

                                  </div>
                                  {props.addAsMember ?
                                    <div>
                                      <div style={{ marginTop: 10, display: "flex", alignItems: "center" }} className={props.isInternal ? "project-checkbox-internal" : "project-checkbox"}>
                                        <Form>
                                          <Form.Check
                                            checked={u.isChecked ? u.isChecked : false}
                                            onChange={async () => {

                                            }}
                                            type="switch"
                                            // id={u.id}
                                            // id={'dd'}
                                            id={`custom-switch-${u.id}`}
                                          // value={u.isChecked ? u.isChecked : false}
                                          />
                                        </Form>
                                        <span style={{ color: u.isChecked ? props.isInternal ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color : "#737373", fontSize: "14px", fontStyle: "italic" }}>
                                          {isGerm ? DE.allow_access_chat : EN.allow_access_chat}
                                        </span>
                                      </div>
                                    </div>
                                    :
                                    <></>
                                  }
                                </div>
                                :
                                <></>
                            ))}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </CustomScrollbars>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddProjectChatUser;
