import React from 'react';
import Layout from '../components/Layout';
import LanguageContext from '../helper/LanguageContext';
import { DE } from '../Languages/DE';
import { EN } from '../Languages/EN';
import {Scrollbars} from 'react-custom-scrollbars';

const renderThumb = ({style, ...props}) => {
    const thumbStyle = {
        borderRadius: 6,
        backgroundColor: 'rgba(240, 132, 56, 0.8)'
    };
    return <div className="thumb-style" style={{...style, ...thumbStyle}} {...props} />;
};

const CustomScrollbars = props => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
    />
);


function Capacities() {
    const {isGerm} = React.useContext(LanguageContext)
    return(
        <React.Fragment>
            <Layout>
                <div className="w-300 capacities_content-page pb-0">
                    <h5><img width={10} src={process.env.PUBLIC_URL+"assets/img/back.svg"} alt="Generic placeholder"/> {isGerm?DE.search_results:EN.search_results}</h5>
                    <div className="my-scroll min_height capacities_height">
                        <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}  className="bottom-bar">
                        <div className="content-capi">
                            <div className="capacities_content">
                                <img width={40} src={process.env.PUBLIC_URL+"assets/img/user.jpg"} alt="Generic placeholder"/>
                                <div className="right_capacities">
                                    <h4>Lorenz Hz</h4>
                                    <p>Service: Heizen</p>
                                    <p>Branch: Heizen construction</p>
                                    <p>Location: Bonn, Germany</p>
                                    <p>Radius: 50Km</p>
                                </div>
                            </div>
                            <div className="timeframe">
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="timeframe-content">
                                            <p>Timeframe:</p>
                                            <p>19.01.2021 - 19.02.2021</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pl-0">
                                        <div className="frame-icon">
                                            <a href="#">
                                                <img width={20} src={process.env.PUBLIC_URL+"assets/img/Follow-gray-dark-new.svg"} alt="Generic placeholder"/>
                                            </a>
                                            <a href="#">
                                                <img width={27} src={process.env.PUBLIC_URL+"assets/img/e-mail-grey.svg"} alt="Generic placeholder"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-capi">
                            <div className="capacities_content">
                                <img width={40} src={process.env.PUBLIC_URL+"assets/img/user.jpg"} alt="Generic placeholder"/>
                                <div className="right_capacities">
                                    <h4>Lorenz Hz</h4>
                                    <p>Service: Heizen</p>
                                    <p>Branch: Heizen construction</p>
                                    <p>Location: Bonn, Germany</p>
                                    <p>Radius: 50Km</p>
                                </div>
                            </div>
                            <div className="timeframe">
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="timeframe-content">
                                            <p>Timeframe:</p>
                                            <p>19.01.2021 - 19.02.2021</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pl-0">
                                        <div className="frame-icon">
                                            <a href="#">
                                                <img width={20} src={process.env.PUBLIC_URL+"assets/img/Follow-gray-dark-new.svg"} alt="Generic placeholder"/>
                                            </a>
                                            <a href="#">
                                                <img width={27} src={process.env.PUBLIC_URL+"assets/img/e-mail-grey.svg"} alt="Generic placeholder"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </CustomScrollbars>
                    </div>
                </div>
            </Layout>
        </React.Fragment>
    );
}

export default Capacities;