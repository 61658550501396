import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useHistory, withRouter } from "react-router-dom";
import Layout from "../../components/Layout";
import { callAPI } from "../../helper/apiUtils";
import { BASE_URL, KJUUP_COLORS } from "../../helper/constants";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import { Scrollbars } from "react-custom-scrollbars";
import { UserFeed } from "../Feeds/UserFeed";
import { CompanyFeed } from "../Feeds/CompanyFeed";
import Carousel from "react-multi-carousel";
import { ARTICLE, GROUPS, GROUPS_BOTTOM, OPEN_BOTTOM_CHATS, OPEN_CHATS, RIGHT_TOP } from "../../store";
import { connect } from "react-redux";
import FeedViewDialog from "../Feeds/Dialogs/FeedViewDialog";
import { ReactComponent as ArrowLeft } from "../../Icons/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../Icons/ArrowRight.svg";
import { $user } from "../../helper/UserFactory";
import { convertBranchRole, convertPosition } from "../../helper/convertor";
import { BsFillPlusCircleFill } from "react-icons/bs";
import _ from "lodash";
import { $crud } from "../../helper/CrudFactory";


const user = $user.get();
const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

function Profile(props) {
  const [show, setShow] = useState(false);
  const [viewLoader, setViewLoader] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const CarouselEl = useRef();
  const [slideNo, setSlideNo] = useState(0);

  const [profile, setProfile] = useState({});
  const [articles, setArticles] = useState([]);
  const { isGerm } = React.useContext(LanguageContext);
  const history = useHistory();

  const getProfile = async () => {
    const id = props.match.params.id;
    const { data } = await callAPI(`/user/${id}/profile`, {}, "GET");
    let profile = data?.profile;
    if (profile.articleCount > 0) {
      const updateArticles = await profile.articles.map((article) => {
        article.expand = false;
        let tempArticle = { ...article, buildingIndustryRole: profile.buildingIndustryRole }
        return tempArticle;
      });
      setArticles(updateArticles);
      setSlideNo(1);
    }
    console.log(profile);
    await setProfile(profile);
  };
  useEffect(() => {
    getProfile();
  }, [props.match.params.id]);

  const getCompanyDetails = (id) => {
    history.push({
      pathname: "/foreign-company-details/" + id,
    });
  };

  const backToLikesList = () => {
    if (props.location.state) {
      history.push({
        pathname: `/articles`,
        state: props.location.state
      });
    } else {
      history.goBack()
    }
  };

  const followUser = async (id) => {
    const { data } = await callAPI(
      `/user/${id}/follow`,
      {
        id: id,
      },
      "GET"
    );

    console.log(data);
    getProfile();
  };


  const get = async (dataN) => {
    const { data } = await callAPI("/group", {}, "GET");
    if (data.status) {
      const updateData = data.groups.map((building, i) => {
        return {
          ...building,
          key: i + 1,
          isOpened: !![...props.openChats, ...props.openBottomChats].find(
            (g) => g.id === building.id
          ),
          originalMessage: building.lastMessage,
          messageLng: "en",
          isBlock: building.name.trim()
            ? !!building.users.find((u) => u.id !== user?.id)?.isBlock
            : false,
        };
      });
      props.updateGroups(updateData);
    }
    setViewLoader(false)
    props.updateRightTop("chat1");
    const group = await props.groups.find((cht) => cht.id === dataN.chat.id);
    if (group) {
      const chk = await props.openChats.find((g) => g.id === dataN.chat.id);
      if (chk) {
        await props.updateOpenChats(
          _.uniqBy([...props.openChats, chk], "id")
        );
      } else {
        await props.updateOpenChats([...props.openChats, group]);
        const updateGroup = await props.groups.map((g) => {
          if (g.id === group.id) {
            return { ...g, isOpenedTop: true, isOpened:true };
          } else {
            return { ...g }
          }
        });
        props.updateGroups(updateGroup);
      }
    }
  };

  const addProjectUser = async (u) => {
    await $crud.confirm({
      title: '',
      textContent: isGerm ? DE.start_chat : EN.start_chat,
      options: {
        ok: isGerm ? DE.ok : EN.ok,
        cancel: isGerm ? DE.cancel : EN.cancel
      },
    });
    setViewLoader(true)
    const user = $user.get();
    const { data } = await callAPI(`/group`, {}, "POST", {
      groupType: "user",
      isOneToOne: true,
      users: [user?.id, u.id],
    });
    get(data)
  };

  return (
    <React.Fragment>
      <Layout>
        {profile && (
          <>
            <div className="profile_section w-300">
              {viewLoader && (
                <div className="website_loader">
                  <div className="loader" />
                </div>
              )}
              <div className="row">
                <div className="col-md-5 pr-0">
                  <div className="user_profile">
                    <div className="user_img" style={{ cursor: "pointer" }}>
                      <a onClick={backToLikesList}>
                        <img
                          className="img-fluid"
                          src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                          alt="Generic placeholder"
                        />
                      </a>
                      <img
                        width={55}
                        height={55}
                        variant="primary"
                        onClick={handleShow}
                        className="profile_user"
                        src={
                          profile?.avatar
                            ? BASE_URL + profile.avatar
                            : process.env.PUBLIC_URL + "/assets/img/head.svg"
                        }
                        alt="Generic placeholder"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-7 pl-0">
                  <div className="follow">
                    <Link
                      to={`/user-followers/${props.match.params.id}`}
                      className="follow_item text-center"
                    >
                      <h3>{profile?.followerCount}</h3>
                      <p>{isGerm ? DE.followers : EN.followers}</p>
                    </Link>
                    <Link
                      to={`/user-followings/${props.match.params.id}`}
                      className="follow_item text-center"
                    >
                      <h3>{profile?.followingCount}</h3>
                      <p>{isGerm ? DE.followings : EN.followings}</p>
                    </Link>
                    <a href="#" className="follow_item text-center">
                      <h3>{profile?.articleCount}</h3>
                      <p>{isGerm ? DE.article : EN.article}</p>
                    </a>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="profile_name" style={{ flex: 1 }}>
                  <h3>{profile?.fullName}</h3>
                  <p>{convertBranchRole(profile?.buildingIndustryRole, isGerm)}</p>
                </div>
                <div style={{ marginRight: 20 }}>
                  <BsFillPlusCircleFill size={25} color={KJUUP_COLORS.base_color} onClick={() => { addProjectUser(profile) }} />
                </div>

                <div className="icon_right">
                  <a
                    onClick={(e) => {
                      followUser(profile.id);
                    }}
                    className={
                      profile.followStatus
                        ? "svg_icon pointer active-like-follow"
                        : "svg_icon pointer"
                    }
                  >
                    <svg
                      className="mr-1"
                      width="22px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 19.81 20.9"
                    >
                      <path
                        d="M295.14,269.16a1.28,1.28,0,0,0-1.32-1.23h-.91a5.11,5.11,0,0,1-8.76,0h-.91a1.27,1.27,0,0,0-1.31,1.23l-1.31,9a1.28,1.28,0,0,0,1.31,1.24h13.21a1.27,1.27,0,0,0,1.3-1.24Z"
                        transform="translate(-280.17 -258.91)"
                      />
                      <path
                        className="cls-1"
                        d="M288.53,268.2a4.42,4.42,0,1,0-4.41-4.42A4.43,4.43,0,0,0,288.53,268.2Z"
                        transform="translate(-280.17 -258.91)"
                      />
                      <line
                        className="cls-2"
                        x1="16.72"
                        y1="0.75"
                        x2="16.72"
                        y2="3.47"
                      />
                      <line
                        className="cls-2"
                        x1="16.72"
                        y1="3.47"
                        x2="16.72"
                        y2="6.18"
                      />
                      <line
                        className="cls-2"
                        x1="16.72"
                        y1="3.47"
                        x2="14.08"
                        y2="3.47"
                      />
                      <line
                        className="cls-2"
                        x1="19.36"
                        y1="3.47"
                        x2="16.72"
                        y2="3.47"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="profile-data">
                <div className="my-scroll profile_height">
                  <CustomScrollbars
                    autoHide
                    autoHideTimeout={500}
                    autoHideDuration={200}
                    className="bottom-bar"
                  >
                    {profile.companies &&
                      profile.companies.map((company, index) => (
                        <div className="profile-flied profile-gray" key={index}>
                          <div
                            onClick={(e) => getCompanyDetails(company.id)}
                            className="profile_name font-orange pointer"
                          >
                            <h3>{company.name}</h3>
                            <p>Position: {convertPosition(isGerm, company.position)}</p>
                          </div>
                        </div>
                      ))}

                    {/* <div className="article">
                      <div className="profile-flied">
                        <div className="profile_name">
                          <h3>
                            <p>{isGerm ? DE.article : EN.article}</p>
                          </h3>
                        </div>
                      </div>
                      <div className="slider-icon">
                        <a href="#" onClick={(e) => CustomLeftArrow()}>
                          <ArrowLeft
                            className={slideNo > 1 ? "svg_active" : "svg_gray"}
                            width={9}
                            height={17}
                          />
                        </a>
                        <p>
                          {slideNo} of {profile.articleCount}{" "}
                          {isGerm ? DE.article : EN.article}
                        </p>
                        <a href="#" onClick={(e) => CustomRightArrow()}>
                          <ArrowRight
                            className={
                              profile.articleCount > slideNo
                                ? "svg_active"
                                : "svg_gray"
                            }
                            width={9}
                            height={17}
                          />
                        </a>
                      </div>
                    </div>
                    {profile.articles && profile.articles.length > 0 ? (
                      <Carousel
                        ref={CarouselEl}
                        additionalTransfrom={0}
                        arrows={false}
                        centerMode={false}
                        className="p-0"
                        containerClass="container"
                        dotListClass=""
                        draggable={profile?.articles.length > 1}
                        focusOnSelect={false}
                        infinite={false}
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                          desktop: {
                            breakpoint: {
                              max: 3000,
                              min: 1024,
                            },
                            items: 1,
                          },
                          mobile: {
                            breakpoint: {
                              max: 464,
                              min: 0,
                            },
                            items: 1,
                          },
                          tablet: {
                            breakpoint: {
                              max: 1024,
                              min: 464,
                            },
                            items: 1,
                          },
                        }}
                        showDots={profile.articles.length > 1}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                      >
                        {articles &&
                          articles.map((article, key) => (
                            <div key={key} className="media-desc">
                              {article.createdBy === "user" ? (
                                <UserFeed
                                  handleFollow={handleFollow}
                                  handleLike={handleLike}
                                  viewArticleDetail={props.viewArticle}
                                  reloadArticle={() => getProfile()}
                                  index={key}
                                  article={article}
                                  expandArticle={expandArticle}
                                />
                              ) : (
                                <CompanyFeed
                                  handleFollow={handleFollow}
                                  handleLike={handleLike}
                                  viewArticleDetail={props.viewArticle}
                                  reloadArticle={() => getProfile()}
                                  index={key}
                                  article={article}
                                  expandArticle={expandArticle}
                                />
                              )}
                            </div>
                          ))}
                      </Carousel>
                    ) : (
                      ""
                    )} */}

                    <div className="company_services">
                      <h5>
                        {isGerm ? DE.company_services : EN.company_services}
                      </h5>
                      {profile.services &&
                        profile.services.map((service) => (
                          <p key={service.id}>
                            {service.service}{" "}
                            {service.usersCount > 0 ? (
                              <span> | {service.usersCount}</span>
                            ) : (
                              ""
                            )}
                          </p>
                        ))}
                    </div>
                    <div className="company_services">
                      <h5>
                        {isGerm ? DE.company_contacts : EN.company_contacts}
                      </h5>
                      <div className="company_centact">
                        <div className="website">
                          <div className="website_img">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/Website-grey.svg"
                              }
                              alt="Generic placeholder"
                            />
                          </div>
                          <div className="details-company">
                            <h5>{isGerm ? DE.website : EN.website}</h5>
                            <p>{profile?.website}</p>
                          </div>
                        </div>
                        <div className="website">
                          <div className="website_img">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/e-mail-grey.svg"
                              }
                              alt="Generic placeholder"
                            />
                          </div>
                          <div className="details-company">
                            <h5>Email</h5>
                            {profile.companies &&
                              profile.companies.map((company) => (
                                <div key={company.id}>
                                  <h6>{company.name}</h6>
                                  <p>{company.email}</p>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </CustomScrollbars>
                </div>
              </div>
            </div>
            <Modal
              className="profile_image"
              show={show}
              onHide={handleClose}
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="profile_pic_profile">
                  <img
                    variant="primary"
                    onClick={handleShow}
                    className="profile_user"
                    src={
                      profile.avatar
                        ? BASE_URL + profile.avatar
                        : process.env.PUBLIC_URL + "/assets/img/head.svg"
                    }
                    alt="Generic placeholder"
                  />
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
        {!profile && <div className="profile_section w-300"></div>}
        {props.article && <FeedViewDialog article={props.article} />}
      </Layout>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { article, groups, groupsBottom, openChats, openBottomChats } = state;
  console.log(article);
  return {
    article: article,
    groups: groups,
    groupsBottom: groupsBottom,
    openChats: openChats,
    openBottomChats: openBottomChats,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    viewArticle: (article) => dispatch({ type: ARTICLE, article: article }),
    updateGroups: (groups) => dispatch({ type: GROUPS, groups: groups }),
    updateOpenChats: (chats) =>
      dispatch({ type: OPEN_CHATS, openChats: chats }),
    updateGroupsBottom: (groups) =>
      dispatch({ type: GROUPS_BOTTOM, groupsBottom: groups }),
    updateOpenBottomChats: (chats) =>
      dispatch({ type: OPEN_BOTTOM_CHATS, openBottomChats: chats }),
    updateRightTop: (value) => dispatch({ type: RIGHT_TOP, rightTop: value }),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Profile));
