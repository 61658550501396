import React, { useEffect, useState } from "react";
import LanguageContext from "../../helper/LanguageContext";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import {
  OPEN_BOTTOM_PROJECTS,
  OPEN_PROJECTS,
  PROJECT_REQUEST_COUNT,
  PROJECTS,
  UPDATE_PROJECT_ACTION_FIRE,
  ALL_BADGE_COUNT,
} from "../../store";
import { withRouter } from "react-router-dom";
import { MdClose } from "react-icons/all";
import Button from "@material-ui/core/Button";
import { callAPI } from "../../helper/apiUtils";
import CompanyListDialog from "./Dialogs/CompanyListDialog";
import { $crud } from "../../helper/CrudFactory";
import { EN } from "../../Languages/EN";
import { DE } from "../../Languages/DE";
import { KJUUP_COLORS } from "../../helper/constants";

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

function ProjectNotification(props) {
  let type = props.type
  const { isGerm } = React.useContext(LanguageContext);
  const [companies, setCompanies] = useState([]);
  const [showCompanyDialog, setCompanyDialog] = useState(false);
  const [requestID, setRequestID] = useState("");

  const getCompanies = async () => {
    const { data } = await callAPI(`/company/my`, {}, "GET");
    if (data.status) {
      setCompanies(data.companies);
    }
  };
  const getProjectName = (name) => {
    if (name) {
      const nameArray = name?.split(" ");
      if (nameArray && nameArray.length) {
        const length = nameArray.length;
        let newName = [];
        newName.push(nameArray[0][0]);
        if (nameArray.length > 1) {
          newName.push(nameArray[length - 1][0]);
        }
        return newName.join("");
      }
    }
  };
  console.log(companies)
  useEffect(() => {
    getCompanies();
  }, [props.requests]);
  const confirm = async (user, index) => {
    if (type) {
      const { data } = await callAPI(
        `/building/${user.requestId}/ApproveProjectJoinRequest`,
        {},
        "POST",
        {
          id: user.requestId,
          companyId: user.company_id,
        }
      );
      console.log(data)
      props.handleClose()
      if (data.status) {
        $crud.notify({
          type: data.status ? "success" : "error",
          message: data.message,
        });
        props.updateRequests(user.requestId, user.company_id, index);
        // props.handleClose();
      }
    }
    else {
      setRequestID(user.requestId);
      if (companies.length > 1) {
        setCompanyDialog(true);
      } else if (companies.length === 1) {
        await submitReJoinCompany(companies[0], user.requestId);
      } else {
        await submitReJoinCompany(null, user.requestId);
      }
    }
  };
  const reject = async (user, index) => {
    console.log(user)
    const { data } = await callAPI(
      `/building/${user.requestId}/RejectProjectJoinRequest`,
      {},
      "POST",
      {
        id: user.requestId,
      }
    );
    props.handleClose()
    if (!type)
      await getBadgesCount()
    if (data.status) {
      $crud.notify({
        type: data.status ? "success" : "error",
        message: data.message,
      });
      props.updateRequests(user.requestId, user.company_id, index);
      // props.handleClose();
    }
  };
  const getBadgesCount = async () => {
    const { data } = await callAPI(`/badge/count`, {}, "GET");
    if (data?.status) {
      await props.updateAllBadgeCount(data);
    }
  }
  const submitReJoinCompany = async (company = {}, requestId = null) => {
    const { data } = await callAPI(
      `/building/${requestId}/ApproveProjectJoinRequest`,
      {},
      "POST",
      {
        id: requestId,
        companyId: company?.id,
      }
    );
    props.handleClose()
    await getBadgesCount()
    if (data.status) {
      $crud.notify({
        type: data.status ? "success" : "error",
        message: data.message,
      });
      props.updateRequests(requestId);
      // props.handleClose();
    }
  };

  return (
    <React.Fragment>
      <div className="w-300 my_project pt-0 h45vh immoanimation-enter-done" style={{ height: "93vh", display: "flex", flexDirection: "column", borderWidth: 0 }}>
        <div className="main_heading">
          {isGerm ? DE.pending_request : EN.pending_request}
          <div>
            <MdClose
              className="pointer"
              onClick={props.handleClose}
              size={25}
            />
          </div>
        </div>

        <div className="wscroll project_notification" style={{ flex: 1 }}>
          <CustomScrollbars
            autoHide
            autoHideTimeout={200}
            autoHideDuration={200}
            className="bottom-bar"
          >
            <div className="project_listing">
              {props.requests &&
                props.requests.map((user, index) => (
                  <div
                    className="row m-0 justify-content-center align-items-center request_popup"
                    key={index}
                  >
                    <div className="col-2 p-2">
                      <div
                        className="project_icon"
                        style={{ backgroundColor: user.themeColor }}
                      >
                        {getProjectName(user.projectName)}
                      </div>
                    </div>
                    <div className="col-10  p-2">
                      {isGerm ? (
                        <div>
                          <strong>{user.addedBy}</strong> möchte Sie zum Raum{" "}
                          <strong> {user.projectName}</strong> hinzufügen.
                        </div>
                      ) : (
                        <div>
                          <strong>{user.addedBy}</strong> invited you to join the
                          room <strong> {user.projectName}</strong>
                        </div>
                      )}
                    </div>
                    <div className="col-12 p-2 row justify-content-center">
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#f7f7f7", color: type ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color }}
                        // className="theme-color"
                        size="small"
                        onClick={(e) => confirm(user, index)}
                      >
                        {isGerm ? "Bestätigen" : "Confirm"}
                      </Button>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#f7f7f7", color: type ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color }}
                        className="ml-3"
                        size="small"
                        onClick={(e) => reject(user, index)}
                      >
                        {isGerm ? "Ablehnen" : "Reject"}
                      </Button>
                    </div>
                  </div>
                ))}
              {props.requests.length <= 0 && (
                <p className="text-center pt-5">{isGerm ? DE.no_pending_request : EN.no_pending_request}</p>
              )}
            </div>
          </CustomScrollbars>
        </div>
        {showCompanyDialog && (
          <CompanyListDialog
            requestId={requestID}
            handleClose={(e) => setCompanyDialog(false)}
            setParam={() => { }}
            companies={companies}
            handleReJoinCompany={submitReJoinCompany}
          />
        )}
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const {
    projects,
    openProjects,
    updateProjectActionFire,
    openBottomProjects,
  } = state;
  return {
    projects: projects,
    updateProjectActionFire: updateProjectActionFire,
    openProjects: openProjects,
    openBottomProjects: openBottomProjects,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateOpenProjects: (projects) =>
      dispatch({ type: OPEN_PROJECTS, openProjects: projects }),
    updateProjectAction: (value) =>
      dispatch({
        type: UPDATE_PROJECT_ACTION_FIRE,
        updateProjectActionFire: value,
      }),
    updateBottomOpenProjects: (projects) =>
      dispatch({ type: OPEN_BOTTOM_PROJECTS, openBottomProjects: projects }),
    updateProjectRequestCount: (count) =>
      dispatch({ type: PROJECT_REQUEST_COUNT, projectRequestCount: count }),
    updateAllBadgeCount: (count) =>
      dispatch({ type: ALL_BADGE_COUNT, unSeenAllCount: count }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectNotification));
